import ReactHtmlParser from "react-html-parser"
export const dynamicHTML = (content) => {
  if (content) {
    const newTextPortions = content.split('\n');
    let newTextStr = ''
    newTextPortions.forEach(function (portion) {
      newTextStr += portion + "<br>"
    })
    return ReactHtmlParser(newTextStr)
  }
}