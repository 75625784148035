import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import FeedPost from "../../components/social/post/admin_post"
import { url } from "../../helpers/url"
import { useParams } from "react-router-dom"
import { Context } from "../../helpers/context"
const Mypost = (props) => {
  const { id } = useParams()
  const [post, setPost] = useState([])
  const [user, setUser] = useState()
  const [status, setStatus] = useState({})
  const [conUser, setConUser] = useContext(Context)
  const [load, setLoad] = useState(false)

  useEffect(() => {
    axios.get(`${url}/influencer/feed/api?type=single&page=0&userId=${id}`).then(({ data }) => {
      if (data.success) {
        setPost(data.rows)
        setUser(data.user)
        setStatus(data.friend)
        setLoad(true)
      }
    })
  }, [id])

  return (
    <>
      <FeedPost route={"influencer"} type="profile" data={post} isUser={user} isLoad={load} isStatus={status} />
    </>
  )
}
export default Mypost
