import { Link } from "react-router-dom"
import { url } from "../../helpers/url"
import "./alert.css"
const Success = ({ title, type, sub, description, link = '/merchandise', a }) => {

	return (
		<>
			<div className="container">
				<div className="custom_alert">
					<p className={"title font18 " + type}>{title}</p>
					<img className="image" src={url + "/uploads/default/" + type + ".png"} alt="" />
					<p className="half_black subtitle font16">{sub}</p>
					<p className="grey description font14">{description}</p>
					{!a && <Link to={type === "success" ? link : "/contact"} className="alert_btn font14">
						Ok
					</Link>}
					{a && <a href={link} className="alert_btn font14">
						Ok
					</a>}

				</div>
			</div>
		</>
	)
}
export default Success
