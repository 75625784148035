import React, { useState, useEffect, } from "react"
import axios from "axios"
import { Link, NavLink } from "react-router-dom"
import "./friend.css"
import { url, imgPath } from "../../../helpers/url"
import { time } from "../../../helpers/time"
import { username } from "../../../helpers/functions"
import InfiniteScroll from 'react-infinite-scroller';
const Friend = ({ more, type, data, count, load, title, link, button, sub, message }) => {
	const [friend, setFriend] = useState([])
	const [request, setRequest] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (load) {
			setFriend(data)
			setLoading(load)
		}
	}, [load])


	useEffect(() => {
		const notificationClick = (e) => {
			if (e.target.className !== 'fa fa-list') {
				setTool('')
			}
		};
		window.addEventListener("click", notificationClick);
		return () => window.removeEventListener("click", notificationClick);
	}, [])

	const addFriend = (receiverId) => {
		axios.post(`${url}/social/request`, { receiverId: receiverId }).then((item) => {
			if (item.data.success) {
				setRequest({ ...request, [receiverId]: receiverId })
			}
		})
	}
	const requestReview = (receiverId, type) => {
		axios.post(`${url}/social/requestreview`, { receiverId: receiverId, type: type }).then((item) => {
			if (item.data.success) {
				if (type === "delete") {
					const requestPerson = { ...request, [receiverId]: receiverId, ["delete" + receiverId]: receiverId }
					setRequest(requestPerson)
				}
			}
			if (type === "accept") {
				const requestPerson = { ...request, [receiverId]: receiverId, ["accept" + receiverId]: receiverId }
				setRequest(requestPerson)
			}
		})
	}
	const [reportBox, setReportBox] = useState('')
	const handleTools = async (receiverId, i, type) => {
		if (type === 'delete') {
			const { data } = await axios.post(`${url}/social/requestreview`, { receiverId: receiverId, type: type })
			if (data.success) {
				const fr = [...friend]
				fr.splice(i, 1)
				setFriend(fr)
			}
		}
		if (type === 'report') {
			setReportBox(receiverId)
		}
		if (type === 'block') {
			const { data } = await axios.post(`${url}/social/requestreview`, { receiverId: receiverId, type: type })
			if (data.success) {
				const fr = [...friend]
				fr.splice(i, 1)
				setFriend(fr)
			}
		}
	}

	const [tool, setTool] = useState('')

	const toolsUi = (id) => {
		if (tool && tool === id) {
			setTool('')
		} else {
			setTool(id)
		}
	}


	const [text, setText] = useState()
	const [reported, setReported] = useState(false)
	const changeHandler = (e) => {
		setText(e.target.value)
	}
	const reportUi = async (type) => {
		if (type === 'submit' && reportBox) {
			const { data } = await axios.post(url + '/social/report/api', { comment: text, userId: reportBox })
			if (data.success) {
				setReported(true)
			}
		}
		if (type === 'cancel') {
			setReportBox('')
			setReported(false)
		}

	}
	const [search, setSearch] = useState('')

	const searchHandler = (e) => {
		setSearch(e.target.value)
	}
	const friendSearchHandler = () => {
		axios.get(`${url}/social/users?search=${search}`)
			.then(({ data }) => {
				if (data.success) {
					setFriend(data.rows)
				}
			})
	}


	const unblockHandler = (i, receiverId) => {
		axios.post(`${url}/social/block`, { receiverId: receiverId })
			.then(({ data }) => {
				if (data.success) {
					if (data.success) {
						const fr = [...friend]
						fr.splice(i, 1)
						setFriend(fr)
					}
				}
			})
	}

	const [hasMore, setHasMore] = useState(true)
	const findItem = () => {
		if (hasMore && friend.length > 20) {
			setHasMore(false)
			axios.get(`${url}/${more}&page=${friend.length}`).then(({ data }) => {
				if (data.success && data.rows.length > 0) {
					setFriend([...friend, ...data.rows])
					setHasMore(true)
				}
				if (data.rows.length === 0 && data.success) {
					setHasMore(false)
				}
			})
		}
	}


	return (
		<>

			<div className="row" style={{ display: 'flex' }}>
				<div className="titel col friend_title">
					<div className="left">
						<h2 className="half_black font24">{title}</h2>
					</div>
					{type === 'find' &&
						< div className="right">
							<input type="text" onChange={searchHandler} />
							<span onClick={friendSearchHandler}><i className="fa fa-search"></i></span>
						</div>
					}


					{sub && friend.length !== 0 && <div className="right"><p className="socil_sub">{sub}</p></div>}
				</div>
			</div>

			{
				loading && friend.length === 0 && (
					<div className="row">
						<div className="titel col">
							<div className="empty_cart_wrapper">
								<h2>{message}</h2>
								<div className="empty_cart">
									<img src={url + "/uploads/default/message.png"} alt="" />
								</div>
								{type !== "find" && (
									<div className="buy">
										<NavLink to={link} className="shopping_btn">
											{button}
										</NavLink>
									</div>
								)}
							</div>
						</div>
					</div>
				)
			}


			<InfiniteScroll pageStart={0}
				loadMore={findItem}
				hasMore={hasMore}
				initialLoad={false}>
				<div className="row friend" style={{ display: 'flex' }}>
					{friend.map((item, i) => {
						return (

							<div className=" col-lg-4 col-md-6 col-sm-6 m_bottom my-3 friend_col" key={i} id={"friend-" + item.id}>
								<div className="friend_img">

									{
										(type !== 'find' && type !== 'block') &&
										<div className="report">
											<i className="fa fa-list" onClick={() => toolsUi(item.id)}></i>

											{(reportBox === item.id) &&
												<div className="report_row"  >
													<div className="report_box" >
														{reported ? <><div className="alert alert-success">
															Thanks for your feedback we will review it soon.
														</div>
															<button className="button" onClick={() => reportUi('cancel')}>Close</button></> : <>
															<label htmlFor="">Let us know your feedback.</label>
															<textarea onChange={changeHandler} id="" cols="30" rows="5" ></textarea>
															<button className="button" onClick={() => reportUi('submit')}>Submit</button>
															<button className="button" onClick={() => reportUi('cancel')}>Cancel</button>
														</>}
													</div>
												</div>
											}

											{tool === item.id && <div className="tools_all"> <div className="report_items">
												{type === 'friend' && <>
													<Link to={"/social/messages/" + item.id}>Message</Link>
													<a onClick={() => handleTools(item.id, i, 'delete')}>Unfriend</a>
												</>
												}
												<a onClick={() => handleTools(item.id, i, 'block')}>Block</a>
												<a onClick={() => handleTools(item.id, i, 'report')}>Report</a>
											</div>

											</div>}
										</div>
									}
									<div className="img">
										<img src={item.image ? imgPath + item.image + '-' + 200 + '.webp' : process.env.REACT_APP_USER_IMG_200} alt="" srcSet="" />
									</div>
									<a href={"/social/" + item.id}><h3 className="my-2 username">{username(item.fname, item.lname)}</h3></a>
									<p className="active_day">Active {time(item.active, "ago")}</p>
									{type === "find" && (
										<>
											<a className={request[item.id] ? "disabled  btn friend_btn" : " btn friend_btn"} onClick={() => addFriend(item.id)}>
												{request[item.id] ? "Request Sent" : "Add Friend"}
											</a>
										</>
									)}

									{type === "request" && (
										<>
											{!request["delete" + item.id] && (
												<>
													<a className={request[item.id] ? "disabled mt-2 btn friend_btn" : "mt-2 btn friend_btn"} onClick={() => requestReview(item.id, "accept")}>
														{request[item.id] ? "Request Accepted" : "Accept Friend"}
													</a>
												</>
											)}

											{!request["accept" + item.id] && (
												<>
													<a className={request[item.id] ? "disabled mt-2 btn friend_btn delete" : "mt-2 btn friend_btn delete"} onClick={() => requestReview(item.id, "delete")}>
														{request[item.id] ? "Request Deleted" : "Request Delete"}
													</a>
												</>
											)}
										</>
									)}
									{type === "friend" && (
										<>
											<a className={request[item.id] ? "disabled mt-2 btn friend_btn" : "mt-2 btn friend_btn"} onClick={() => requestReview(item.id, "delete")}>
												{request[item.id] ? "Friend Deleted" : "Delete Friend"}
											</a>
										</>
									)}
									{type === "block" && (
										<>
											<a className={"mt-2 btn friend_btn"} onClick={() => unblockHandler(i, item.receiverId)}>
												Unblock Friend
											</a>
										</>
									)}
								</div>
							</div>
						)
					})}
				</div>
			</InfiniteScroll>
		</>
	)
}
export default Friend
