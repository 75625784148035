import React, { useState, useRef, useEffect, useContext } from "react"
import axios from "axios"
import { url, imgPath } from "../../../helpers/url"
import "./admin_post.css"
import "./comment.css"
import ReactHtmlParser from "react-html-parser"
import InfiniteScroll from "react-infinite-scroller"
import { username, trimText, urlParser, substring, bannerSize, screen } from "../../../helpers/functions"
import { getTime, getDate, time, feedTime } from "../../../helpers/time"
import { NavLink, Link, useParams } from "react-router-dom"
import { Toast } from "../../toastify/toastify"
import { Toastify } from "../../toastify/toastify"
import { Context } from "../../../helpers/context"

import ReactPlayer from 'react-player/lazy'


const VideoPlayer = ({ video }) => {
	return (<>
		{video && <div className="video_player_wrapper">
			<ReactPlayer className="video_player" fallback={<div><p>Loading</p></div>} url={video} controls={true} volume={true} light={true} />
		</div>}

	</>)
}

const SharePostBox = ({ route, postId, userId, shareUserId, userFname, userLname, userImage, content, image, video, createdAt, type, }) => {

	return (
		<div className="share_post_wrapper">
			<div className="post_box d-flex">
				<div className="d-flex flex-row username_box">
					<Link to={`/${route}/` + shareUserId}>
						<img src={userImage ? imgPath + userImage + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} width="50" className="roundedImage" />
					</Link>
					<div className="d-flex flex-column username">
						<Link to={`/${route}/` + shareUserId}>
							<span className="font-weight-bold">{username(userFname, userLname)}</span>
						</Link>
						<p className="post_time">{feedTime(createdAt, "ago")}</p>
					</div>
				</div>
			</div>

			<div className="">
				{content && <p className="text-justify post_content_p">{content}</p>}
				<VideoPlayer video={video} />
				{image && <img src={imgPath + image} className="post_content_image" />}
				<Link className="view_post" to={`/${route}/post/${postId}/${shareUserId}`}>View Post</Link>
			</div>

		</div>
	)
}

const CommentBox = ({ route, id, mainUserId, data, user, addComment, editComment, deleteComment, count, status, type }) => {
	const [commentcontent, setCommentcontent] = useState("")
	const [commentImage, setCommentImage] = useState("")
	const [comment, setComment] = useState([])
	const [commentCount, setCommentCount] = useState([])
	const [conUser, setConUser] = useContext(Context)

	useEffect(() => {
		setComment(data)
		setCommentCount(count)
	}, [data])

	const [textarea, setTextarea] = useState(60)
	const textareaRef = useRef(null)
	const commentChnageHandler = (e) => {
		const total = textareaRef.current.scrollHeight
		setTextarea(total)
		setCommentcontent(e.target.value)
	}


	const postComment = (e) => {
		e.preventDefault()
		if (!conUser.type) {
			return setConUser({ ...conUser, loginPopup: true, pageYOffset: window.pageYOffset })
		}
		if (id && (trimText(commentcontent) || commentImage)) {
			let data = {
				comment: trimText(commentcontent),
				image: commentImage ? commentImage : "",
				postId: id,
				mainUserId: mainUserId,
				type: "create",
			}
			axios.post(`${url}/${route}/comment/api`, data).then(({ data }) => {
				if (data.success) {

					let crCmt = {
						...data.comment,
						["registers.fname"]: conUser.fname,
						["registers.lname"]: conUser.lname,
						["registers.image"]: conUser.image,

					}

					setCommentcontent("")
					setCommentImage("")
					addComment(id, [crCmt, ...comment])

					if (data.notification) {
						conUser.socket.emit("addNotification", data.notification)
					}
				}
			})
		}
	}


	const editTextareaRef = useRef(null)
	const [commentEdit, setCommentEdit] = useState()
	const [commentEditContent, setCommentEditContent] = useState()
	const [commentEditImage, setCommentEditImage] = useState()
	const [editTextarea, setEditTextarea] = useState(60)

	useEffect(() => {
		if (editTextareaRef.current) {
			const total = editTextareaRef.current.scrollHeight
			setEditTextarea(total)
		}
	}, [commentEditContent])

	const editHandler = (item) => {
		setCommentEdit(item.id)
		setCommentEditContent(item.comment)
		setCommentEditImage(item.image)
	}


	const commentEditChnageHandler = (e) => {
		setCommentEditContent(e.target.value)
	}

	let inputCommentEditFile = useRef(null)
	const onCommentEditButtonClick = () => {
		inputCommentEditFile.current.click()
	}

	const imageEditCommentHandler = (e) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		formData.append("type", "global")
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setCommentEditImage(data.filename)
				e.target.value = ''
			}
		})
	}



	const editCommentHandler = (e, item, arrId) => {
		e.preventDefault()
		if (item.id && (trimText(commentEditContent) || commentEditImage)) {
			const formData = new FormData()
			formData.append("comment", trimText(commentEditContent))
			if (commentEditImage) {
				formData.append("image", commentEditImage)
			}
			formData.append("commentId", item.id)
			formData.append("type", "edit")
			axios.post(`${url}/${route}/comment/api`, formData).then(({ data }) => {
				if (data.success) {
					let copyComment = [...comment]
					console.log(copyComment[arrId])

					copyComment[arrId].comment = commentEditContent
					copyComment[arrId].image = commentEditImage
					copyComment[arrId].edited = 1

					editComment(copyComment)
					setCommentEditContent("")
					setCommentEditImage("")
					setCommentEdit("")
				}
			})
		}
	}

	const closeEditCommentHandler = (e, item) => {
		setCommentEdit("")
	}

	const deleteHandler = (id, postId, arrId) => {
		const copyItem = comment[arrId]
		axios.post(`${url}/${route}/comment/api`, { commentId: id, postId: postId, type: 'delete' }).then(({ data }) => {
			if (data.success) {
				deleteComment(postId, comment.filter(item => item.id !== id))
			}
		})
		if (copyItem.image) deleteImgHandler(copyItem.image)
	}

	const deleteImgHandler = (image) => {
		axios.post(url + "/media/delete/api", { image: image })
	}

	const deleteAddCmtImg = (image) => {
		deleteImgHandler(commentImage)
		setCommentImage("")
	}


	let inputCommentFile = useRef(null)
	const onCommentButtonClick = () => {
		inputCommentFile.current.click()
	}

	const imageCommentHandler = (e) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		formData.append("type", "global")
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setCommentImage(data.filename)
				e.target.value = ''
			}
		})
	}

	const [page, setPage] = useState(0)

	const readMoreHandler = () => {
		if (commentCount.length > 0 && commentCount.length > page) {
			axios.get(url + `/${route}/comment/api?page=${(page + 1) * 20}&postId=${id}`).then(({ data }) => {
				if (data.success) {
					setPage(page + 1)
					setComment([...comment, ...data.rows])
				}
			})
		}
	}


	const like = (id, arrId) => {
		if (!conUser.type) {
			return setConUser({ ...conUser, loginPopup: true, pageYOffset: window.pageYOffset })
		}

		axios.post(`${url}/${route}/post/comment/like/api`, { commentId: id, postId: comment[arrId].postId, title: substring(comment[arrId].comment, 20), mainUserId: comment[arrId].userId }).then(({ data }) => {
			if (data.success) {
				let updateComment = [...comment]
				if (data.type === "dislike") {
					updateComment[arrId]["love"] = updateComment[arrId]["love"] - 1
					updateComment[arrId]["comment_likes.userId"] = false
					setComment(updateComment)
				}
				if (data.type === "like") {
					updateComment[arrId]["love"] = updateComment[arrId]["love"] + 1
					updateComment[arrId]["comment_likes.userId"] = true
					setComment(updateComment)
				}
				if (data.notification) {
					conUser.socket.emit("addNotification", data.notification)
				}
			}
		})
	}



	return (
		<div className="enter_post comment_enter">
			<div className="send_box">
				<form onSubmit={postComment}>
					{((conUser.userId === user.id) || (status === 'friend') || (type === 'feed') || (route === 'influencer')) && (<>

						<div className="row comment_enter_row">
							<textarea ref={textareaRef} style={{ height: textarea < 300 ? textarea : 300 + "px" }} onChange={commentChnageHandler} name="text" id="" className={textarea > 320 ? "global_scroll w-100" : "w-100 hoverflow"} cols="30" rows="1" placeholder="Write a comment" value={commentcontent}></textarea>

							<div className="add_photo" onClick={onCommentButtonClick}>
								<i className="fa fa-camera" title="Add Image"></i>
								<span>Add Photo</span>
							</div>
							<input onChange={imageCommentHandler} type="file" id="file" ref={inputCommentFile} style={{ display: "none" }} />

							<div className="add_post">
								<button className="btn post_btn" type="submit">
									Comment
								</button>
							</div>
						</div>
						{commentImage && (
							<div className="row add_comment_row">
								<i className="fa fa-close" onClick={deleteAddCmtImg}></i>
								<img className="postImage" src={commentImage ? imgPath + commentImage : ""} alt="" />
							</div>
						)}

					</>)}

					{comment.length > 0 &&
						<div className="comment_item_wrapper m_top">
							{comment.map((item, id) => {
								return (
									<>
										<div className="commet_item_wrapper" id={"comment-" + item.id}>

											<div className="comment_item m_bottom">
												<div className="comment_user_image">
													<Link to={`/${route}/` + item.userId}>
														<img src={item["registers.image"] ? imgPath + item["registers.image"] + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} className="roundedImage" />
													</Link>
												</div>
												<div className="comment_user_content">
													<Link to={`/${route}/` + item.userId}>
														<span className="comment_user_name">{username(item["registers.fname"], item["registers.lname"])}</span>
													</Link>
													<p className="comment_time">{feedTime(item.createdAt, "ago")}</p>

													{item.comment && <div className="font14 half_black m_top feed_comment_description">{item.comment}</div>}
													{item.image && <img src={imgPath + item.image} className="comment_image" />}

													{/* Below this code for the comment like and report */}
													<div className="d-flex justify-content-between user_like  comment_like">
														<div className="d-flex flex-row icons d-flex align-items-center like_box">
															<div className="like" onClick={() => like(item.id, id)}>
																<i className={item["comment_likes.userId"] ? "fa fa-thumbs-up like" : "fa fa-thumbs-up dislike"}></i>
																<span className={item["comment_likes.userId"] && "liked"}>{item.love} Like</span>
															</div>

															{item.userId === conUser.userId && <>
																<div className="comment" onClick={() => editHandler(item)}>
																	<i className={"fa fa-edit"}></i>
																	<span>Edit</span>
																</div>
																<div className="comment" onClick={() => deleteHandler(item.id, item.postId, id)}>
																	<i className={"fa fa-trash"}></i>
																	<span>Delete</span>
																</div>

															</>

															}
															{item.edited > 0 &&
																<div className="comment edited">
																	<span>(edited)</span>
																</div>
															}
														</div>
													</div>
												</div>
											</div>

											{commentEdit === item.id && <>
												<div className="row comment_enter_row update_comment_row">
													<div className="close_comment_update">
														<i className="fa fa-close" onClick={closeEditCommentHandler}></i>
													</div>

													<textarea ref={editTextareaRef} style={{ height: editTextarea < 300 ? editTextarea : 300 + "px" }} onChange={commentEditChnageHandler} name="text" id="" className={editTextarea > 320 ? "global_scroll w-100" : "w-100 hoverflow"} cols="30" rows="1" placeholder="Write a comment" value={commentEditContent}></textarea>

													<div className="add_photo" onClick={onCommentEditButtonClick}>
														<i className="fa fa-camera" title="Add Image"></i>
														<span>Update Photo</span>
													</div>
													<input onChange={imageEditCommentHandler} type="file" id="file" ref={inputCommentEditFile} style={{ display: "none" }} />

													<div className="add_post">
														<button onClick={(e) => editCommentHandler(e, item, id)} className="btn post_btn">
															Update
														</button>
													</div>

													{commentEditImage && (
														<div className="comment_update_image">
															<img className="postImage" src={imgPath + commentEditImage} alt="" />
														</div>
													)}

												</div>


											</>
											}

										</div>





									</>
								)
							})}


							{(commentCount.length > 0 && commentCount.length > page) &&
								<button className="read_more_comment" onClick={readMoreHandler}>Read More</button>
							}
						</div>
					}
				</form>
			</div>
		</div>
	)
}

const Post = ({ allowPosting, route, type, data, isUser, isStatus, isLoad }) => {

	let inputFile = useRef(null)
	const [content, setContent] = useState("")
	const [image, setImage] = useState()

	const [userpost, setUserpost] = useState([])
	const [user, setUser] = useState({})
	const [conUser, setConUser] = useContext(Context)
	const [social, setSocial] = useState({ social: {} })
	const [load, setLoad] = useState(false)
	const [request, setRequest] = useState([])
	const [friendRequest, setFriendRequest] = useState([])
	const [friend, setFriend] = useState([])
	const [status, setStatus] = useState({})



	useEffect(() => {
		if (isLoad) {
			setUserpost(data)
			setUser(isUser)

			if (isUser && isUser.social) setSocial(JSON.parse(isUser.social))
			if (isStatus) setStatus(isStatus)

			setLoad(isLoad)
		}

	}, [isUser])

	useEffect(() => {
		if (route !== 'influencer') {
			axios.get(`${url}/${route}/request?type=pending`).then(({ data }) => {
				if (data.success) {
					setFriendRequest(data.rows)
				}
			})
			axios.get(`${url}/${route}/request?type=friend`).then(({ data }) => {
				if (data.success) {
					setFriend(data.rows)
				}
			})
		}
	}, [route])

	const [hasMore, setHasMore] = useState(true)
	const [page, setPage] = useState(1)

	const findItem = () => {
		if (hasMore && userpost.length > 20) {
			setHasMore(false)
			setPage(page + 1)
			axios.get(`${url}/${route}/feed/api?type=${type}&page=${page}`).then(({ data }) => {
				if (data.success && data.rows.length > 0) {
					setUserpost([...userpost, ...data.rows])
					setHasMore(true)
				}
				if (data.rows.length === 0 && data.success) {
					setHasMore(false)
				}
			})
		}
	}


	const [textarea, setTextarea] = useState(120)
	const textareaRef = useRef([])

	const postContent = (e) => {
		const total = textareaRef.current.scrollHeight
		if (total > 120) {
			setTextarea(total)
		}
		setContent(e.target.value)
	}


	const [media, setMedia] = useState('')
	const mediaChanger = (e) => {
		setMedia(e.target.value)
	}

	const imageHandler = (e) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setImage(data.filename)
				e.target.value = ''
			}
		})
	}


	const [video, setVideo] = useState('')
	const videoHandler = (e) => {
		const value = e.target.value
		setVideo(value)
	}


	const [edit, setEdit] = useState({})

	const editClickOutside = () => {
		const editClick = (e) => {
			if (e.target.className !== 'fa fa-cog') {
				setEdit({})
				window.removeEventListener("click", editClick)
			}
		}
		window.addEventListener("click", editClick)
	};

	const editUi = (arrId) => {
		editClickOutside()
		setEdit({ [arrId]: !edit[arrId] })
	}

	const [model, setModel] = useState({})

	const showEditModel = (arrId) => {
		setModel({ [arrId]: !model[arrId] })
		setEdit({ [arrId]: !edit[arrId] })
		const copyPost = userpost[arrId]
		setModelcontent(copyPost.content)
		if (copyPost.video) setModelMedia('video'); setModelVideo(copyPost.video);
		if (copyPost.image) setModelMedia('image'); setModelimage(copyPost.image);
	}

	const modelCloseHandler = (arrId) => {
		if (modelcontent) setModelcontent('');
		if (modelimage) setModelimage('');
		if (modelvideo) setModelVideo('');
		setEdit({})
		setModel({})
	}


	const modelTextareaRef = useRef()
	const [modelTextarea, setModelTextarea] = useState(120)


	const [modelcontent, setModelcontent] = useState()
	const modelConHandler = (e) => {
		setModelcontent(e.target.value)
	}


	useEffect(() => {
		if (modelTextareaRef.current) {
			const total = modelTextareaRef.current.scrollHeight
			setModelTextarea(total)
		}
	}, [modelcontent])


	const [modelimage, setModelimage] = useState('')
	const modelImgHandler = (e) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setModelimage(data.filename)
				e.target.value = ''
			}
		})
	}
	const deleteModelimg = (item) => {
		const image = item.image ? item.image : modelimage
		axios.post(url + "/media/delete/api", { image: image }).then(({ data }) => {
			if (data.success) {
				setModelimage('')
			}
		})
	}

	const deleteImgHandler = (item) => {
		axios.post(url + "/media/delete/api", { image: image }).then(({ data }) => {
			if (data.success) {
				setImage("")
			}
		})
	}


	const [modelMedia, setModelMedia] = useState('')

	const modelMediaChanger = (e, arrId) => {
		const value = e.target.value
		if (value === 'image' && modelvideo) {
			setModelVideo('')
		}
		setModelMedia(value)
	}

	const [modelvideo, setModelVideo] = useState('')

	const modelVideoHandler = (e) => {
		const value = e.target.value
		setModelVideo(value)
	}


	const addPost = (e) => {
		e.preventDefault()
		if ((trimText(content)) || image || video) {

			let formData = {
				type: 'create',
				content: trimText(content),
				image: image,
				video: video,
			}
			if (modelimage && modelvideo) {
				formData['image'] = ""
				deleteModelimg(modelimage)
			}


			axios.post(`${url}/${route}/post/api`, formData).then((result) => {
				let updateData = result.data
				if (updateData.success) {
					setContent("")
					if (image) {
						setImage("")
					}
					if (video) {
						setVideo("")
						setMedia("")
					}

					setUserpost([{ ...updateData.post, postId: updateData.post.id, postImage: updateData.post.image, userImage: updateData.user.image, fname: updateData.user.fname, lname: updateData.user.lname }, ...userpost])
				}
			})
		}
	}

	const deletePost = (id, shareId, arrId) => {
		if (id || id === 0) {
			axios.post(`${url}/${route}/post/api`, { id: id, shareId: shareId, type: "delete" }).then((result) => {
				if (result.data.success) {
					let copypost = [...userpost]
					const splice = copypost.splice(arrId, 1)
					setUserpost(copypost)
					setEdit({})

					if (splice[0].image) deleteModelimg({ image: splice[0].image })

				}
			})
		}
	}


	const updatePost = (e, id, arrId) => {
		e.preventDefault()
		if ((trimText(modelcontent)) || modelimage || modelvideo) {
			let formData = {
				id: id,
				type: 'update',
				content: trimText(modelcontent),
				image: modelimage,
				video: modelvideo,
			}
			if (modelimage && modelvideo) {
				formData['image'] = ""
				deleteModelimg(modelimage)
			}

			axios.post(`${url}/${route}/post/api`, formData).then((result) => {
				if (result.data.success) {
					let updatePost = userpost
					updatePost[arrId].content = modelcontent

					updatePost[arrId].image = modelimage
					updatePost[arrId].video = modelvideo

					if (modelimage && modelvideo) {
						updatePost[arrId].image = ""
					}

					setModelcontent('')
					setModelimage('')
					setModelVideo('')
					setModel({ ...model, [arrId]: false })
					setUserpost([...updatePost])
				}
			})
		}
	}

	const onButtonClick = (e) => {
		inputFile.current.click()
	}

	const ref = useRef([])

	const onModelClick = (id) => {
		ref.current[id].click()
	}



	const like = (id, arrId) => {
		if (!conUser.type) {
			return setConUser({ ...conUser, loginPopup: true, pageYOffset: window.pageYOffset })
		}

		axios.post(`${url}/${route}/post/like/api`, { postId: id, mainUserId: userpost[arrId].userId, title: substring(userpost[arrId].content, 20), total: userpost[arrId].love }).then(({ data }) => {
			if (data.success) {
				let updatePost = [...userpost]
				if (data.type === "dislike") {
					updatePost[arrId]["love"] = userpost[arrId]["love"] - 1
					updatePost[arrId]["postLike"] = false
					setUserpost([...updatePost])
				}
				if (data.type === "like") {
					updatePost[arrId]["love"] = userpost[arrId]["love"] + 1
					updatePost[arrId]["postLike"] = true
					setUserpost([...updatePost])
				}

				if (data.notification) {
					conUser.socket.emit("addNotification", data.notification)
				}

			}
		})
	}

	const isActive = (item) => {
		if (getTime(item) + 60000 > getDate().getTime()) {
			return true
		}
		return false
	}

	const requestReview = (receiverId, type) => {
		if (request["accept" + receiverId] || request["delete" + receiverId]) {
		} else {
			axios.post(`${url}/${route}/requestreview`, { receiverId: receiverId, type: type }).then((item) => {
				if (item.data.success) {
					if (type === "delete") {
						setRequest({ ...request, [receiverId]: receiverId, ["delete" + receiverId]: receiverId })
					}
				}
				if (type === "accept") {
					setRequest({ ...request, [receiverId]: receiverId, ["accept" + receiverId]: receiverId })
				}
			})
		}
	}
	const [commentopen, setCommentopen] = useState()
	const [comment, setComment] = useState([])
	const [commentCount, setCommentCount] = useState([])

	const [foundComment, setFoundComment] = useState({})

	const commentOpenHandler = (id) => {
		if (commentopen !== id) {
			setCommentopen(id)
			if (!foundComment[id]) {
				axios.get(`${url}/${route}/comment/api?page=0&postId=${id}`).then(({ data }) => {
					if (data.success) {
						if (data.rows.length === 0) setFoundComment({ ...foundComment, [id]: true })
						setComment(data.rows)
						setCommentCount(data.count)
					}
				})
			}

		} else {
			setCommentopen("")
		}
	}

	const addComment = (postId, array) => {
		const post = userpost.filter(id => {
			if (id.id === postId) {
				id.comment += 1
			}
			return id
		})
		setUserpost(post)
		setComment(array)
	}


	const editComment = (array) => {
		setComment(array)
	}



	const deleteComment = (postId, array) => {
		const post = userpost.filter(id => {
			if (id.id == parseInt(postId)) {
				id.comment -= 1
			}
			return id
		})
		setUserpost(post)
		setComment(array)
	}



	const [shareId, setShareId] = useState()

	const shareInputFile = useRef(null)
	const shareOnButtonClick = (e) => {
		shareInputFile.current.click()
	}

	const [shareImage, setShareImage] = useState('')
	const shareImageHandler = (e) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setShareImage(data.filename)
				e.target.value = ''
			}
		})
	}

	const [shareContent, setShareContent] = useState("")
	const [shareTextarea, setShareTextarea] = useState(120)
	const shareTextareaRef = useRef([])

	const sharePostContent = (e, id) => {
		const total = shareTextareaRef.current[id].scrollHeight
		if (total > 120) {
			setShareTextarea(total)
		}
		setShareContent(e.target.value)
	}

	const shareHandler = (id, type) => {
		if (!conUser.type) {
			return setConUser({ ...conUser, loginPopup: true, pageYOffset: window.pageYOffset })
		}

		if (type === "open") {
			setShareId(id)
		} else {
			setShareContent("")
			setShareImage("")
			setShareId("")
		}
	}



	const postShare = (e, item, arrId) => {
		e.preventDefault()
		axios.post(`${url}/${route}/share/api`, { ...item, image: shareImage, content: shareContent, title: substring(item.content, 20) }).then(({ data }) => {
			if (data.success) {
				let copyPost = [...userpost]
				copyPost[arrId].share = copyPost[arrId].share + 1
				setUserpost(copyPost)
				setShareId()
				Toast("Your post has been shared")
				if (data.notification) {
					conUser.socket.emit("addNotification", data.notification)
				}
			}
		})
	}


	useEffect(() => {
		const notificationClick = (e) => {
			if (e.target.className !== 'fa fa-list') {
				setTool('')
			}
		};
		window.addEventListener("click", notificationClick);
		return () => window.removeEventListener("click", notificationClick);
	}, [])

	const [reportBox, setReportBox] = useState('')
	const handleTools = async (receiverId, type) => {
		if (type === 'delete') {
			const { data } = await axios.post(`${url}/${route}/requestreview`, { receiverId: receiverId, type: type })
			if (data.success) {
				setStatus({})
			}
		}
		if (type === 'report') {
			setReportBox(receiverId)
		}
		if (type === 'block') {
			const { data } = await axios.post(`${url}/${route}/requestreview`, { receiverId: receiverId, type: type })
			if (data.success) {
				setStatus({ status: 'blockme' })
			}
		}
	}

	const [tool, setTool] = useState('')

	const toolsUi = (id) => {
		if (tool && tool === id) {
			setTool('')
		} else {
			setTool(id)
		}
	}


	const [text, setText] = useState()
	const [reported, setReported] = useState(false)
	const changeHandler = (e) => {
		setText(e.target.value)
	}
	const reportUi = async (type) => {
		if (type === 'submit' && reportBox) {
			const { data } = await axios.post(`${url}/${route}/report/api`, { comment: text, userId: reportBox })
			if (data.success) {
				setReported(true)
			}
		}
		if (type === 'cancel') {
			setReportBox('')
			setReported(false)
		}

	}

	const proReqHandler = async (id, type) => {
		if (type === 'add') {
			const { data } = await axios.post(`${url}/social/request`, { receiverId: id })
			if (data.success) {
				setStatus({ status: 'waiting' })
			}
		}
		if (type === 'accept') {
			const { data } = await axios.post(`${url}/social/requestreview`, { receiverId: id, type: 'accept' })
			setStatus({ status: 'friend' })
		}
	}


	useEffect(() => {
		if (route === 'influencer' && type === 'feed') {


			if (document.querySelector(".post_box_wrapper")) {

				const start = (page - 1) * 21
				const end = page * 21
				const post = userpost.slice(start, end)


				const observerCallback = (entries, observer) => {
					entries.forEach(entry => {
						const id = entry.target.id
						if (entry.isIntersecting) {
							observer.unobserve(entry.target);
							axios.post(url + '/influencer/post/view/api', { id: id })
						}
					});
				};

				const observer = new IntersectionObserver(observerCallback, {
					root: null, // Root element, null means the browser viewport
					rootMargin: '0px',
					threshold: 0.5 // Trigger the callback when 100% of the target is visible
				});


				post.forEach(item => {
					observer.observe(document.getElementById(item.id));
				})
			}
		}



	}, [userpost])


	return (
		<>
			<Toastify />

			<div className={"container social_post_container " + type}>
				<div className="row social_post">


					{/* it for profile */}
					{((type === "profile" || type === "single") && load && user) && <>
						<div className="col-lg-12 user_profile_info">
							<div className="banner" style={{
								backgroundImage: `url('${user.background ? imgPath + bannerSize(JSON.parse(user.background)) : process.env.REACT_APP_SOCIAL_BANNER_IMAGE}')`
							}}>

								{/* {screen() <= 991 &&
									<div className="name_right">

										{(user.id === conUser.userId) &&
											<Link className="follow" to={"/profile/edit"}>Edit Profile</Link>
										}


										{user.id != conUser.userId &&

											<div className="add_friend">
												{console.log(status)}

												{
													status.status === 'pending' && <button className="button" onClick={() => proReqHandler(user.id, "accept")}>Accept Request</button>
												}
												{
													(!status.status && load) && <button className="button" onClick={() => proReqHandler(user.id, "add")}>Add Friend</button>
												}

												{
													status.status === 'waiting' && <button className="button" disabled>{route === 'influencer' ? 'Following' : 'Sent Request'}</button>
												}

												{
													status.status === 'friend' && <button className="button friend" disabled>Friend</button>
												}
												{
													status.status === 'blockme' && <button className="button" disabled>Blocked</button>
												}

											</div>
										}

										{status.status === 'friend' &&
											<div className="report">
												<i className="fa fa-list" onClick={() => toolsUi(1)}></i>
												{tool && <div className="tools_all"> <div className="report_items">
													<Link to={`/${route}/messages/` + user.id}>Message</Link>
													<a onClick={() => handleTools(user.id, 'delete')}>Unfriend</a>
													<a onClick={() => handleTools(user.id, 'block')}>Block</a>
													<a onClick={() => handleTools(user.id, 'report')}>Report</a>
												</div>
												</div>
												}
											</div>
										}

									</div>
								} */}

							</div>

							<div className="user_profile_wrapper">
								<div className="user_profile">

									<div className="left">
										<div className="img">
											<img src={user.image ? imgPath + user.image + '-' + 200 + '.webp' : process.env.REACT_APP_USER_IMG_200} alt="" />
										</div>
									</div>
									<div className="right">
										<div className="name">
											<div className="name_main">
												{screen() > 991 &&
													<div className="name_left">
														<p className="username">{username(user.fname, user.lname)}</p>
													</div>
												}
												<div className="name_right">

													{(user.id === conUser.userId) &&
														<Link className="follow" to={"/profile/edit"}>Edit Profile</Link>
													}


													{user.id != conUser.userId &&

														<div className="add_friend">
															{console.log(status)}
															{
																status.status === 'pending' && <button className="button" onClick={() => proReqHandler(user.id, "accept")}>Accept Request</button>
															}
															{
																(!status.status && load) && <button className="button" onClick={() => proReqHandler(user.id, "add")}>Add Friend</button>
															}

															{
																status.status === 'waiting' && <button className="button" disabled>{route === 'influencer' ? 'Following' : 'Sent Request'}</button>
															}

															{
																status.status === 'friend' && <button className="button friend" disabled>Friend</button>
															}
															{
																status.status === 'blockme' && <button className="button" disabled>Blocked</button>
															}

														</div>
													}

													{status.status === 'friend' &&
														<div className="report">
															<i className="fa fa-list" onClick={() => toolsUi(1)}></i>
															{tool && <div className="tools_all"> <div className="report_items">
																<Link to={`/${route}/messages/` + user.id}>Message</Link>
																<a onClick={() => handleTools(user.id, 'delete')}>Unfriend</a>
																<a onClick={() => handleTools(user.id, 'block')}>Block</a>
																<a onClick={() => handleTools(user.id, 'report')}>Report</a>
															</div>
															</div>
															}
														</div>
													}

												</div>

											</div>


											{reportBox &&
												<div className="report_row"  >
													<div className="report_box" >
														{reported ? <><div className="alert alert-success">
															Thanks for your feedback we will review it soon.
														</div>
															<button className="button" onClick={() => reportUi('cancel')}>Close</button></> : <>
															<label htmlFor="">Let us know your feedback.</label>
															<textarea onChange={changeHandler} id="" cols="30" rows="5" ></textarea>
															<button className="button" onClick={() => reportUi('submit')}>Submit</button>
															<button className="button" onClick={() => reportUi('cancel')}>Cancel</button>
														</>}
													</div>
												</div>
											}
											{screen() > 991 &&
												<div className="description_wrapper">
													<p className="details">{user.description}</p>
													<div className="social_icon">
														{Object.keys(social.social).map(item => <a target="_blank" href={social.social[item].link}><img src={url + "/uploads/default/" + social.social[item].name + "-36.png"} alt="" /></a>)}
													</div>

												</div>
											}
										</div>
									</div>
								</div>

								{screen() <= 991 &&
									<div className="user_profile_mobile">
										<div className="description_wrapper">

											<div className="name_left">
												<p className="username">{username(user.fname, user.lname)}</p>
											</div>

											<p className="details">{user.description}</p>
											<div className="social_icon">
												{Object.keys(social.social).map(item => <a target="_blank" href={social.social[item].link}><img src={url + "/uploads/default/" + social.social[item].name + "-36.png"} alt="" /></a>)}
											</div>

										</div>

									</div>
								}

							</div>
						</div>

						<div className="col-lg-9 first">

							{(user && conUser && user.id === conUser.userId && allowPosting) &&


								<div className="enter_post">
									<div className="send_box">

										<form onSubmit={addPost}>
											<div className="row">
												<textarea ref={textareaRef} style={{ height: textarea < 300 ? textarea : 300 + "px" }} onChange={postContent} name="text" className={textarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={content}></textarea>


												{media === 'video' &&
													<div className="video_url">
														<input onChange={videoHandler} type="text" placeholder="Paste the video URL" name="video_url" />
														<p className="video_text">Only supported popular website video like Youtube/ Vimeo etc...</p>
														<VideoPlayer video={video} />

													</div>
												}

												<div className="select_media">
													<img src={url + "/uploads/default/media (1).png"} alt="" />
													<select name="" id="" onChange={mediaChanger}>
														<option value="">Select Media</option>
														<option selected={media === 'image'} value="image">Image</option>
														<option selected={media === 'video'} value="video">Video</option>
													</select>
												</div>


												{media === 'image' &&
													<div className="add_photo" onClick={onButtonClick}>
														<i className="fa fa-camera" title="Add Image"></i>
														<span>Add Photo</span>
													</div>
												}


												<input onChange={imageHandler} type="file" id="file" ref={inputFile} style={{ display: "none" }} />

												<div className="add_post">
													<button className="btn post_btn" type="submit">
														Post
													</button>
												</div>
											</div>

											{image && (
												<div className="row model_image_row">
													<div className="model_image">
														<i className="fa fa-close" onClick={deleteImgHandler}></i>
														<img className="postImage" src={imgPath + image} alt="" />
													</div>
												</div>
											)}
										</form>
									</div>
								</div>
							}


							<div className="row user_posts">
								<div className="col">
									<InfiniteScroll
										pageStart={0}
										loadMore={findItem}
										hasMore={hasMore}
										// load={<div className="loader" key={0}>Loading ...</div>}
										initialLoad={false}
										className="scroll_wrapper"
									>
										{userpost.map((item, id) => {

											if (model[id]) {
												return (
													<div className="enter_post_model update_post_model" key={"item-" + id} id={item.id}>
														<div className="enter_post post_model">
															<div className="send_box">
																<form onSubmit={(e) => updatePost(e, item.id, id)}>
																	<div className="row p-0 ">
																		<i className="fa fa-close" onClick={() => modelCloseHandler(id)}></i>


																		<textarea ref={modelTextareaRef} style={{ height: modelTextarea < 300 ? modelTextarea : 300 + "px" }} onChange={modelConHandler} name="text" className={modelTextarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={modelcontent}></textarea>

																		{modelMedia === 'video' &&
																			<div className="video_url">
																				<input onChange={modelVideoHandler} type="text" placeholder="Paste the video URL" name="video_url" value={modelvideo} />
																				<p className="video_text">Only supported popular website video like Youtube/ Vimeo etc...</p>
																				<VideoPlayer video={modelvideo} />
																			</div>
																		}


																		<div className="select_media">
																			<img src={url + "/uploads/default/media (1).png"} alt="" />

																			<select name="" id="" onChange={(e) => modelMediaChanger(e, id)}>
																				<option value="">Select Media</option>
																				<option selected={modelMedia === 'image'} value="image">Image</option>
																				<option selected={modelMedia === 'video'} value="video">Video</option>
																			</select>
																		</div>

																		{modelMedia === 'image' &&
																			<div className="add_photo" onClick={() => onModelClick(id)}>
																				<i className="fa fa-camera" title="Add Image"></i>
																				<span>Update Photo</span>
																			</div>
																		}



																		<div className="add_post">
																			<button className="btn post_btn" type="submit">
																				Update
																			</button>
																		</div>

																		<input key={id} onChange={modelImgHandler} type="file" id={id} ref={(el) => (ref.current[id] = el)} style={{ display: "none" }} />
																	</div>

																	<div className="row p-0 mt-2 model_image_row">

																		{modelimage && <div className="model_image">
																			<i className="fa fa-close" onClick={() => deleteModelimg(modelimage)}></i>
																			<img className="postImage" src={imgPath + modelimage} alt="" />
																		</div>}

																	</div>
																</form>
															</div>
														</div>
													</div>
												)
											} else {


												return (
													<>
														{/* Below this post box for the influencer profile */}
														{route === 'influencer' &&
															<div className="post_box_wrapper" id={item.id} key={item.id}>
																<>
																	<div className="post_box d-flex" key={"item-" + id}>
																		<div className="d-flex flex-row username_box">
																			<img src={user.image ? imgPath + user.image + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} width="50" className="roundedImage" />
																			<div className="d-flex flex-column username">
																				<span className="font-weight-bold">{username(user.fname, user.lname)}</span>
																				<p className="post_time">{feedTime(item.createdAt, "ago")}</p>
																			</div>
																		</div>


																		{(user && conUser && user.id === conUser.userId) &&
																			< div className="post_info_icon edit_box" >
																				<i onClick={() => editUi(id)} className="fa fa-cog"></i>
																				{edit[id] && (
																					<div className="post_info">

																						{conUser.type === 'influencer' &&
																							<a className="d-block" onClick={() => showEditModel(id)}>
																								Edit
																							</a>
																						}



																						<a className="d-block" onClick={() => deletePost(item.id, item.shareId, id)}>
																							Delete
																						</a>
																					</div>
																				)}

																			</div>}

																	</div>



																	<div className="">

																		<p className="text-justify post_content_p">{ReactHtmlParser(urlParser(item.content))} </p>
																		{item.image && <img src={imgPath + item.image} className="post_content_image" />}

																		<VideoPlayer video={item.video} />

																		{item.shareId &&
																			<SharePostBox route={route} postId={item.shareId} userId={item.userId} shareUserId={item.shareUserId} userFname={item.shareFname} userLname={item.shareLname} userImage={item.shsreUserImage} content={item.shareContent} image={item.shareImage} video={item.shareVideo} createdAt={item.shareCreatedAt} type={type} />
																		}


																		{(conUser.type === 'influencer' || (conUser.type !== 'influencer' && conUser.userId !== user.id)) && <>

																			<div className="d-flex justify-content-between user_like">
																				<div className="d-flex flex-row icons d-flex align-items-center like_box">
																					<div className="like" onClick={() => like(item.id, id)}>
																						<i className={item["postLike"] ? "fa fa-thumbs-up like" : "fa fa-thumbs-up dislike"}></i>
																						<span className={item["postLike"] && "liked"}>{item.love} Like</span>
																					</div>

																					<div className="comment" onClick={() => commentOpenHandler(item.id)}>
																						<i className="fa fa-comment"></i>
																						<span>{item.comment} Comment</span>
																					</div>

																					{(status.status === 'friend') &&
																						<div className="share">
																							<div className="chare_item" onClick={() => shareHandler(item.id, "open")}>
																								<i className="fa fa-share"></i>
																								<span>{item.share} Share</span>
																							</div>
																						</div>
																					}

																					{(status.status !== 'friend') &&
																						<div className="share" style={{ cursor: 'unset' }}>
																							<div className="chare_item">
																								<i className="fa fa-share"></i>
																								<span>{item.share} Share</span>
																							</div>
																						</div>
																					}

																				</div>
																			</div>



																			{/* Share model for profile page start*/}
																			{item.id === shareId && (

																				<div className="share_model">
																					<img className="fa-close" src={url + "/uploads/default/icons8-close-25.png"} onClick={() => shareHandler(item.id, 'close')} alt="" />

																					{/* Below is writing option for new share post */}

																					<div className="enter_post">
																						<div className="send_box">
																							<form onSubmit={(e) => postShare(e, item, id)}>
																								<div className="row">
																									<textarea ref={(el) => (shareTextareaRef.current[item.id] = el)} style={{ height: shareTextarea < 300 ? shareTextarea : 300 + "px" }} onChange={(e) => sharePostContent(e, item.id)} name="text" className={shareTextarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={shareContent}></textarea>

																									<div className="add_photo" onClick={shareOnButtonClick}>
																										<i className="fa fa-camera" title="Add Image"></i>
																										<span>Add Photo</span>
																									</div>

																									<input onChange={shareImageHandler} type="file" id="file" ref={shareInputFile} style={{ display: "none" }} />

																									<div className="add_post">
																										<button className="btn post_btn" type="submit">
																											Share
																										</button>
																									</div>
																								</div>

																								{shareImage && (
																									<div className="row">
																										<img className="postImage" src={imgPath + shareImage} alt="" />
																									</div>
																								)}
																							</form>
																						</div>
																					</div>


																					{/* Below is share profile and share post data */}

																					<SharePostBox route={route} postId={item.id} userId={item.userId} shareUserId={item.userId} userFname={item.fname} userLname={item.lname} userImage={item.userImage} content={item.content} image={item.image} createdAt={item.createdAt} type={type} />


																				</div>
																			)}
																			{/* Share model for profile page end*/}



																			{/*Enter comment section  */}
																			{commentopen === item.id && <CommentBox route={route} id={item.id} mainUserId={item.userId} data={comment} editComment={editComment} deleteComment={deleteComment} count={commentCount} user={user} addComment={addComment} status={status.status} type={type} />}
																			{/* Comment box end */}
																		</>

																		}


																	</div>
																</>

															</div >
														}

														{/* Below this post box for the social profile */}
														{route === 'social' &&
															<div className="post_box_wrapper" id={item.id} key={item.id}>


																{/* Below this post box for the non influencer page  */}

																<>
																	<div className="post_box d-flex" key={"item-" + id}>
																		<div className="d-flex flex-row username_box">
																			<img src={user.image ? imgPath + user.image + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} width="50" className="roundedImage" />
																			<div className="d-flex flex-column username">
																				<span className="font-weight-bold">{username(user.fname, user.lname)}</span>
																				<p className="post_time">{feedTime(item.createdAt, "ago")}</p>
																			</div>
																		</div>


																		{(user && conUser && user.id === conUser.userId) &&
																			< div className="post_info_icon edit_box" >
																				<i onClick={() => editUi(id)} className="fa fa-cog"></i>
																				{edit[id] && (
																					<div className="post_info">


																						<a className="d-block" onClick={() => showEditModel(id)}>
																							Edit
																						</a>




																						<a className="d-block" onClick={() => deletePost(item.id, item.shareId, id)}>
																							Delete
																						</a>
																					</div>
																				)}

																			</div>}

																	</div>



																	<div className="">

																		<p className="text-justify post_content_p">{ReactHtmlParser(urlParser(item.content))} </p>
																		{item.image && <img src={imgPath + item.image} className="post_content_image" />}

																		<VideoPlayer video={item.video} />

																		{item.shareId &&
																			<SharePostBox route={route} postId={item.shareId} userId={item.userId} shareUserId={item.shareUserId} userFname={item.shareFname} userLname={item.shareLname} userImage={item.shsreUserImage} content={item.shareContent} image={item.shareImage} video={item.shareVideo} createdAt={item.shareCreatedAt} type={type} />
																		}

																		<div className="d-flex justify-content-between user_like">
																			<div className="d-flex flex-row icons d-flex align-items-center like_box">
																				<div className="like" onClick={() => like(item.id, id)}>
																					<i className={item["postLike"] ? "fa fa-thumbs-up like" : "fa fa-thumbs-up dislike"}></i>
																					<span className={item["postLike"] && "liked"}>{item.love} Like</span>
																				</div>

																				<div className="comment" onClick={() => commentOpenHandler(item.id)}>
																					<i className="fa fa-comment"></i>
																					<span>{item.comment} Comment</span>
																				</div>

																				{(status.status === 'friend') &&
																					<div className="share">
																						<div className="chare_item" onClick={() => shareHandler(item.id, "open")}>
																							<i className="fa fa-share"></i>
																							<span>{item.share} Share</span>
																						</div>
																					</div>
																				}

																				{(status.status !== 'friend') &&
																					<div className="share" style={{ cursor: 'unset' }}>
																						<div className="chare_item">
																							<i className="fa fa-share"></i>
																							<span>{item.share} Share</span>
																						</div>
																					</div>
																				}

																			</div>
																		</div>



																		{/* Share model for profile page start*/}
																		{item.id === shareId && (

																			<div className="share_model">
																				<img className="fa-close" src={url + "/uploads/default/icons8-close-25.png"} onClick={() => shareHandler(item.id, 'close')} alt="" />

																				{/* Below is writing option for new share post */}

																				<div className="enter_post">
																					<div className="send_box">
																						<form onSubmit={(e) => postShare(e, item, id)}>
																							<div className="row">
																								<textarea ref={(el) => (shareTextareaRef.current[item.id] = el)} style={{ height: shareTextarea < 300 ? shareTextarea : 300 + "px" }} onChange={(e) => sharePostContent(e, item.id)} name="text" className={shareTextarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={shareContent}></textarea>

																								<div className="add_photo" onClick={shareOnButtonClick}>
																									<i className="fa fa-camera" title="Add Image"></i>
																									<span>Add Photo</span>
																								</div>

																								<input onChange={shareImageHandler} type="file" id="file" ref={shareInputFile} style={{ display: "none" }} />

																								<div className="add_post">
																									<button className="btn post_btn" type="submit">
																										Share
																									</button>
																								</div>
																							</div>

																							{shareImage && (
																								<div className="row">
																									<img className="postImage" src={imgPath + shareImage} alt="" />
																								</div>
																							)}
																						</form>
																					</div>
																				</div>


																				{/* Below is share profile and share post data */}

																				<SharePostBox route={route} postId={item.id} userId={item.userId} shareUserId={item.userId} userFname={item.fname} userLname={item.lname} userImage={item.userImage} content={item.content} image={item.image} createdAt={item.createdAt} type={type} />


																			</div>
																		)}
																		{/* Share model for profile page end*/}



																		{/*Enter comment section  */}
																		{commentopen === item.id && <CommentBox route={route} id={item.id} mainUserId={item.userId} data={comment} editComment={editComment} deleteComment={deleteComment} count={commentCount} user={user} addComment={addComment} status={status.status} type={type} />}



																		{/* Comment box end */}
																	</div>
																</>

															</div >
														}
													</>
												)
											}
										})}
										{(userpost.length === 0 && load) && <>
											<div className="post_box_wrapper">
												<h4>No posts</h4>
											</div>
										</>

										}
									</InfiniteScroll>
								</div>
							</div>
						</div>
					</>
					}



					{/* It is for feed page*/}
					{((type === "feed") && load) && (
						<div className="col-lg-9 first">

							{allowPosting &&
								<div className="enter_post">
									<div className="send_box">
										<form onSubmit={addPost}>
											<div className="row">
												<textarea ref={textareaRef} style={{ height: textarea < 300 ? textarea : 300 + "px" }} onChange={postContent} name="text" className={textarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={content}></textarea>


												{media === 'video' &&
													<div className="video_url">
														<input onChange={videoHandler} type="text" placeholder="Paste the video URL" name="video_url" />
														<p className="video_text">Only supported popular website video like Youtube/ Vimeo etc...</p>
														<VideoPlayer video={video} />
													</div>
												}


												<div className="select_media">
													<img src={url + "/uploads/default/media (1).png"} alt="" />
													<select name="" id="" onChange={mediaChanger}>
														<option value="">Select Media</option>
														<option selected={media === 'image'} value="image">Image</option>
														<option selected={media === 'video'} value="video">Video</option>
													</select>
												</div>



												{media === 'image' &&
													<div className="add_photo" onClick={onButtonClick}>
														<i className="fa fa-camera" title="Add Image"></i>
														<span>Add Photo</span>
													</div>
												}

												<input onChange={imageHandler} type="file" id="file" ref={inputFile} style={{ display: "none" }} />

												<div className="add_post">
													<button className="btn post_btn" type="submit">
														Post
													</button>
												</div>
											</div>



											{image && (
												<div className="row">
													<img className="postImage" src={imgPath + image} alt="" />
												</div>
											)}
										</form>
									</div>
								</div>
							}
							{/* user Posts*/}
							<div className="row user_posts">
								<div className="col">
									<InfiniteScroll
										pageStart={0}
										loadMore={findItem}
										hasMore={hasMore}
										// load={<div className="loader" key={0}>Loading ...</div>}
										initialLoad={false}
										className="scroll_wrapper"
									>
										{userpost.map((item, id) => {
											return (
												<>
													<div className="post_box_wrapper" id={item.id} key={item.id}>

														<div className="post_box d-flex" key={"item-" + id}>
															<div className="d-flex flex-row username_box">
																<Link to={`/${route}/` + item.userId}>
																	<img src={item.userImage ? imgPath + item.userImage + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} width="50" className="roundedImage" />
																</Link>
																<div className="d-flex flex-column username">
																	<Link to={`/${route}/` + item.userId}>
																		<span className="font-weight-bold">{username(item.fname, item.lname)}</span>
																	</Link>
																	<p className="post_time">{feedTime(item.createdAt, "ago")}</p>
																</div>
															</div>
														</div>
														<div className="">
															{item.content && <p className="text-justify post_content_p">{ReactHtmlParser(urlParser(item.content))} </p>}

															<VideoPlayer video={item.video} />

															{item.image && <img src={imgPath + item.image} className="post_content_image" />}


															{item.shareId &&
																<SharePostBox route={route} postId={item.shareId} userId={item.userId}
																	shareUserId={item.shareUserId} userFname={item.shareFname} userLname={item.shareLname} userImage={item.shsreUserImage} content={item.shareContent} image={item.shareImage} video={item.shareVideo} createdAt={item.shareCreatedAt} type={type} />
															}

															<div className="d-flex justify-content-between user_like">
																<div className="d-flex flex-row icons d-flex align-items-center like_box">
																	<div className="like" onClick={() => like(item.id, id)}>
																		<i className={item["postLike"] ? "fa fa-thumbs-up like" : "fa fa-thumbs-up dislike"}></i>
																		<span className={item["postLike"] && "liked"}>{item.love} Like</span>
																	</div>

																	<div className="comment" onClick={() => commentOpenHandler(item.id)}>
																		<i className="fa fa-comment"></i>
																		<span>{item.comment} Comment</span>
																	</div>


																	<div className="share">
																		<div className="chare_item" onClick={() => shareHandler(item.id, "open")}>
																			<i className="fa fa-share"></i>
																			<span>{item.share} Share</span>
																		</div>
																	</div>


																</div>
															</div>

															{/* Share model for feed page start*/}
															{item.id === shareId && (

																<div className="share_model">
																	<img className="fa-close" src={url + "/uploads/default/icons8-close-25.png"} onClick={() => shareHandler(item.id, 'close')} alt="" />

																	{/* Below is writing option for new share post */}

																	<div className="enter_post">
																		<div className="send_box">
																			<form onSubmit={(e) => postShare(e, item, id)}>
																				<div className="row">

																					{route !== 'influencer' && <>

																						<textarea ref={(el) => (shareTextareaRef.current[item.id] = el)} style={{ height: shareTextarea < 300 ? shareTextarea : 300 + "px" }} onChange={(e) => sharePostContent(e, item.id)} name="text" className={shareTextarea > 320 ? "w-100 global_scroll" : "hoverflow w-100"} cols="30" rows="3" placeholder="What’s on your mind?" value={shareContent}></textarea>

																						<div className="add_photo" onClick={shareOnButtonClick}>
																							<i className="fa fa-camera" title="Add Image"></i>
																							<span>Add Photo</span>
																						</div>
																						<input onChange={shareImageHandler} type="file" id="file" ref={shareInputFile} style={{ display: "none" }} />

																					</>}

																					<div className="add_post">
																						<button className="btn post_btn" type="submit">
																							Share
																						</button>
																					</div>
																				</div>

																				{shareImage && (
																					<div className="row">
																						<img className="postImage" src={imgPath + shareImage} alt="" />
																					</div>
																				)}
																			</form>
																		</div>
																	</div>


																	{/* Below is share profile and share post data */}

																	<SharePostBox route={route} postId={item.id} userId={item.userId}
																		shareUserId={item.userId} userFname={item.fname} userLname={item.lname} userImage={item.userImage} content={item.content} image={item.image} video={item.video} createdAt={item.createdAt} type={type} />


																</div>
															)}
															{/* Share model for feed page end*/}

															{/*Enter comment section  */}
															{commentopen === item.id && <CommentBox route={route} id={item.id} mainUserId={item.userId} data={comment} editComment={editComment} addComment={addComment} deleteComment={deleteComment} count={commentCount} user={user} status={status.status} type={type} />}

															{/* Comment box end */}
														</div>
													</div>
												</>
											)
										})}
									</InfiniteScroll>
								</div>
							</div>
						</div>
					)}


					{/* Left Section */}
					{route === 'social' &&
						<div className="col-lg-3 last">
							{friendRequest.length > 0 && (
								<div className="friend_request_box m_bottom">
									<h2 className="font16 half_black mb10">Friend Requests</h2>

									{friendRequest.map((item) => {
										const isRequest = request[item.id] && "disabled"
										return (
											<div className="friend_request_wrapper m_bottom" id={"request-" + item.id}>
												<Link to={`/${route}/` + item.userId} className="nobright">
													<div className="friend_request_profile">
														<div className="img">
															<img src={item.image} className="m_bottom m_top" alt="" />
														</div>
														<div className="profile">
															<p className="font14 half_black ">{username(item.fname, item.lname)}</p>
															<p className="font12 grey">{item.total} mutual friends</p>
														</div>
													</div>
												</Link>

												<div className="request_handler">
													{!request["delete" + item.id] && (
														<button className={"accept " + isRequest} onClick={() => requestReview(item.id, "accept")}>
															{request[item.id] ? "Request Accepted" : "Accept"}
														</button>
													)}

													{!request["accept" + item.id] && (
														<button className={"ignore " + isRequest} onClick={() => requestReview(item.id, "delete")}>
															{request[item.id] ? "Request Deleted" : "Ignore"}
														</button>
													)}
												</div>
											</div>
										)
									})}
									<div className="view_all_request">
										<NavLink className="green m_top text-center pointer d-block" to={`/${route}/friend-request`}>
											View All
										</NavLink>
									</div>
								</div>
							)}
							{friend.length > 0 && (

								<div className="friend_request_box m_top">
									<h2 className="font16 half_black mb10">My Friends</h2>
									{friend.map((item) => {
										return (
											<Link to={`/${route}/` + item.userId} className="nobright" id={"friend-" + item.id}>
												<div className="friend_request_profile m_bottom">
													<div className="img">
														<img src={item.image ? imgPath + item.image + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} className="m_bottom m_top" alt="" />
														<div className={isActive(item.active) ? "ball active" : "ball inactive"}></div>
													</div>
													<div className="profile">
														<p className="font14 half_black ">{username(item.fname, item.lname)}</p>
														<p className="font12 grey">{isActive(item.active) ? "Active now" : time(item.active) + " ago"}</p>
													</div>
												</div>
											</Link>
										)
									})}

									<div className="view_all_request">
										<NavLink className="green m_top text-center pointer d-block" to={`/${route}/friends`}>
											View All
										</NavLink>
									</div>
								</div>

							)}
						</div>
					}
				</div>
			</div >
		</>
	)
}

export default Post
