import React, { useState, useContext, useEffect } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import Loader from "../../helpers/load"
import { Context } from "../../helpers/context"

import Autocomplete from "react-google-autocomplete"
import "./registration.css"
import { license_type, state_names } from "../../helpers/business-type"
const Registration = ({ userType, title, button }) => {
	const [type, setType] = useState(userType)
	useEffect(() => {
		setType(userType)
	}, [userType])
	const [user, setUser] = useContext(Context)
	if (typeof window !== "undefined" && user.type) {
		const currentPage = localStorage.getItem("page") ? localStorage.getItem("page") : "/"
		window.location.href = currentPage
	}

	const [value, setValue] = useState({
		fname: "",
		lname: "",
		passA: "",
		passB: "",
		email: "",
		address: "",
		phone: "",
		business_type: "",
		business: "",
		city: "",
		state: "",
		zip: "",
		emailEx: "",
		success: false,
	})

	const onType = (e) => {
		let updateState = {
			...value,
			[e.target.name]: e.target.value,
		}
		setValue(updateState)
	}

	const onTypeEmail = (e) => {
		let updateState = {
			...value,
			email: e.target.value.toLowerCase(),
		}
		setValue(updateState)
	}

	const [term, setTerm] = useState(false)
	const onCheck = () => {
		setTerm(!term)
	}

	const [load, setLoad] = useState(false)
	const [none, setNone] = useState("block")

	const [license, setLicense] = useState([1])
	const [count, setCount] = useState(1)
	const multiLicense = () => {
		setCount(count + 1)
		setLicense([...license, count + 1])
	}
	const [licenseObj, setLicenseObj] = useState({})
	const licenseChange = (e, id) => {
		let updateState = {
			...licenseObj,
			[id]: {
				...licenseObj[id],
				[e.target.name]: e.target.value,
			}
		}
		setLicenseObj(updateState)
	}
	const closeChildBox = (item) => {
		const filter = license.filter(i => i !== item)
		const licenseObjCopy = { ...licenseObj }
		delete licenseObjCopy[item]
		setLicense(filter)
		setLicenseObj(licenseObjCopy)
	}

	const [isEmail, setIsEmail] = useState(false)
	const [passError, setPassError] = useState(false)
	const [passTypeError, setPassTypeError] = useState(false)


	function isSpecial(str) {
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		return format.test(str)
	}

	function allCases(string) {
		const
			upper = /[A-Z]/.test(string),
			lower = /[a-z]/.test(string);
		return upper && lower;
	}

	const passwordChecker = (value) => {
		return ((value.length < 8) || (value.includes(" ")) || !isSpecial(value) || !allCases(value))
	}

	const [passeye, setPasseye] = useState(false)
	const [passeye2, setPasseye2] = useState(false)

	const addDiv = () => {
		var addressInputElement = document.querySelector(".user_address_row")
		var pacContainer = document.querySelector(".pac-container")
		if (addressInputElement && pacContainer) {
			addressInputElement.appendChild(pacContainer)
		}
	}

	const [currentPlace, setCurrentPlace] = useState("")
	const [cor, setCor] = useState([])
	const addressChanger = (e) => {
		const ad = e.target.value
		setCurrentPlace(ad)
	}


	const [latitude, setLatitude] = useState()
	const [longitude, setLongitude] = useState()
	const [addressError, setAddressError] = useState(false)

	const [city, setCity] = useState()
	const [zip, setZip] = useState()
	const [state, setState] = useState()

	const placeController = (place) => {
		let address
		let zip = "";
		let city;
		let state;
		let number;

		place.address_components.map(item => {
			// console.log("item")
			// console.log(item)
			if (item.types[0] === "route") address = item.long_name
			if (item.types[0] === "street_number") number = item.long_name
			if (item.types[0] === "postal_code") zip = item.short_name
			if (item.types[0] === "administrative_area_level_3" || item.types[0] === "locality") city = item.long_name
			if (item.types[0] === "administrative_area_level_1") state = item.short_name
		})

		if (number) {
			address = number + " " + address
		}

		setCurrentPlace(address)
		setZip(zip)
		setCity(city)
		setState(state)
		setLatitude(place.geometry.location.lat())
		setLongitude(place.geometry.location.lng())
	}

	const isLatLng = (latitude, longitude) => {
		if (type === 'customer') {
			return true
		} else if (type === 'business' && latitude && longitude) {
			return true
		} else {
			return false
		}
	}

	const formValue = (e) => {
		e.preventDefault()

		if (!passwordChecker(value.passA) && value.email && value.email.includes('.com') && value.email.includes('@') && value.passA === value.passB && term && isLatLng(latitude, longitude)) {
			setIsEmail(false)
			setNone("d-none")
			setLoad(true)
			const keys = Object.keys(licenseObj)
			const business_type = []
			keys.map(item => {
				const data = licenseObj[item].license_type
				if (data === 'Adult-Use Cultivation' ||
					data === 'Adult-Use Cultivation' ||
					data === 'Adult-Use Mfg' ||
					data === 'Adult-Use Nonstorefront' ||
					data === 'Adult-Use Retail') {
					business_type.push({ value: 'Recreational', label: 'Recreational' })
				}
				else if (data === 'Medical Cultivation' ||
					data === 'Medical Mfg' ||
					data === 'Medical Nonstorefront' ||
					data === 'Medical Retail') {
					business_type.push({ value: 'Medical', label: 'Medical' })
				}
			})
			axios.post(`${url}/user/api`, { ...value, business_type: business_type, address: currentPlace, city: city, state: state, zip: zip, latitude: latitude, longitude: longitude, license: licenseObj, type: type }).then((data) => {
				const result = data.data
				const updateState = {
					emailEx: result.email,
					business: result.business,
					success: result.success,
				}
				setValue({ ...value, ...updateState })
				setLoad(false)
				setNone("block")
			})
		}
		if (passwordChecker(value.passA)) {
			setPassTypeError(true)
		}
		if (value.passA !== value.passB) {
			setPassError(true)
		}

		if (!value.email.includes('.com') || !value.email.includes('@')) {
			setIsEmail(true)
		}


		if (!latitude || !longitude) {
			setAddressError(true)
		} else {
			setAddressError(false)
		}
	}


	return (
		<>
			{load && <Loader />}
			<section className={"login-page section-b-space " + none}>
				<div className="container registration_container">
					{type === "business" && (
						<div className="business_reg_title">
							<h3 className="mb-3 title_color">{title}</h3>
						</div>
					)}
					<div className={"row registration boxshadow " + type}>
						{value.success ? (
							<div className="alert alert-success ">
								<p>Your Sign Up confirmation link has been sent to your email.</p>
							</div>
						) : (
							<>
								{type !== "business" && (
									<div className="col-lg-6 text-center first">
										<img src={url + "/uploads/default/singup.png"} alt="" />
									</div>
								)}

								<div className={type !== "business" ? "col-lg-6 scecond" : "scecond col-lg-12"}>
									{type !== "business" && <h3 className="mb-3 title_color">{title}</h3>}

									<div className="theme-card">
										<form method="post" onSubmit={formValue} >
											{type === 'business' &&
												<p className="form_title contact_header">Contact</p>
											}
											<div className={"registraion_main_form " + type}>
												<div className="input_group">

													<label htmlFor="fname">First Name</label>
													<input required name="fname" onChange={onType} type="text" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="lname">Last Name</label>
													<input required name="lname" onChange={onType} type="text" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="email">Email</label>
													<input required name="email" onChange={onTypeEmail} type="email" className="form-control mb-3" />
													{value.emailEx ? <p className="alert alert-danger">This email already exist on our records</p> : ""}
													{isEmail && <p className="alert alert-danger">This is not a valid email.</p>}
												</div>

												<div className="input_group">
													<label htmlFor="passA">Password</label>
													<div className="eye_pass_input registation">
														<i className={passeye ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye(!passeye)}></i>

														<input required name="passA" onChange={onType} type={passeye ? "text" : "password"} className="form-control mb-3" />
														{(passwordChecker(value.passA) && passTypeError) &&
															<div className="alert alert-danger">
																{(value.passA.length < 8) && <p>Password must be at least 8 characters</p>}
																{(value.passA.includes(" ")) && <p>Password must be alphanumeric (contain letters and numbers)</p>}
																{!isSpecial(value.passA) && <p>Password must contain at least 1 special character, e.g., ! @ # ?</p>}
																{!allCases(value.passA) && <p>Password must contain at least one capital and lowercase letter</p>}
															</div>
														}
													</div>

												</div>

												<div className="input_group">
													<label htmlFor="passB">Confirm Password</label>
													<div className="eye_pass_input registation">
														<i className={passeye2 ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye2(!passeye2)}></i>

														<input required name="passB" onChange={onType} type={passeye2 ? "text" : "password"} className="form-control mb-3" />
														{(value.passA && value.passA.length > 0 && value.passA !== value.passB && passError) ? <p className="alert alert-danger">Password didn't match</p> : ""}
													</div>
													{/* {value.passA && value.passA.length > 0 && value.passA === value.passB ? <p className="alert alert-success">Password matched</p> : ""} */}
												</div>

											</div>


											{type === "business" && (
												<>
													<div className="input_group">
														<p className="form_title contact_header">Business</p>
														<label htmlFor="address">Address</label>
														<Autocomplete
															onFocus={addDiv}
															className="form-control"
															options={{
																// types: ['geocode',],
																// (regions)
																// 'address'
																types: ['address'],
																componentRestrictions: { country: "us" },
															}}
															apiKey={process.env.REACT_APP_MAP_KEY || "AIzaSyBzKBrHQzHmz9VlbAF0ybGou6uYftxyIlQ"}
															onPlaceSelected={placeController}
															onChange={addressChanger}
															value={currentPlace}
															placeholder="Address"
														/>
														{addressError &&
															<div className="alert alert-danger mt-3">
																Please click on the valid address
															</div>
														}
													</div>

													<div className="input_group">
														<label htmlFor="city">City</label>
														<input required name="city" onChange={(e) => setCity(e.target.value)} type="text" className="form-control mb-3" value={city} />
													</div>

													<div className="input_group">
														<label htmlFor="state">State/ Province</label>
														<select className="form-control mb-3" name="state" id="" onChange={(e) => setState(e.target.value)} >
															<option>Select State</option>
															{state_names.map(item => {
																return (
																	<option value={item} selected={item === state}>{item}</option>
																)
															})}

														</select>

													</div>

													<div className="input_group">
														<label htmlFor="zip">Zip Code</label>
														<input required name="zip" onChange={(e) => setZip(e.target.value)} type="number" className="form-control mb-3" value={zip} />
													</div>

													<div className="input_group">
														<label htmlFor="phone">Phone</label>
														<input required name="phone" onChange={onType} type="text" className="form-control mb-3" />
													</div>

													<div className="input_group">
														<label htmlFor="business_name">Business Name</label>
														<input required name="business_name" onChange={onType} type="text" className="form-control mb-3" />

														{value.business ? <p className="alert alert-danger">This business name already exist on our records</p> : ""}
													</div>

													{/* <div className="input_group">
														<label htmlFor="business_type">Business Type</label>

														<select required className="form-control mb-3" name="business_type" onChange={(e) => onType("business_type", e)} id="">
															<option value="">Select a business type</option>
															<option value="Brand">Brand</option>
															<option value="CBD Store">CBD Store</option>
															<option value="Dispensary">Dispensary</option>
															<option value="Delivery">Delivery</option>
															<option value="Doctor">Doctor</option>
														</select>
													</div> */}

												</>
											)}

											{type === "business" &&
												<div className="user_license">
													<h3 className="form_title">License</h3>

													{
														license.map(item => {
															return (
																<div className={item > 1 ? "license_fileds more" : "license_fileds"}>
																	{item > 1 &&
																		<div className="close_child_main">
																			<i onClick={() => closeChildBox(item)} className="fa fa-close close_child"></i>
																		</div>
																	}

																	<div className="input_group">
																		<label htmlFor="license">License number</label>
																		<input required name="license" onChange={(e) => licenseChange(e, item)} type="text" className="form-control mb-3" />
																	</div>
																	<div className="input_group">
																		<label htmlFor="license_type">License type</label>
																		<select required className="form-control mb-3" name="license_type" onChange={(e) => licenseChange(e, item)} id="">
																			<option value="">Select a license type</option>
																			{license_type.map(item => <option value={item}>{item}</option>)}
																		</select>
																	</div>
																	<div className="input_group">
																		<label htmlFor="expiration">Expiration</label>
																		<input required name="expiration" onChange={(e) => licenseChange(e, item)} type="date" className="form-control mb-3" />
																	</div>
																</div>
															)
														})
													}
													<div className="license_plus">
														<p className="" onClick={multiLicense}>Add More License <i className="fa fa-plus"></i></p>
													</div>
												</div>
											}
											<div className="form-group terms">
												<div className="check_all">
													<div className="check">
														<input type="checkbox" onChange={onCheck} checked={term} />
													</div>
													<div className="text">
														<label htmlFor="">
															<a href="/terms" target="_blank">Terms & Conditions</a>
														</label>
													</div>
												</div>
											</div>

											<button className="btn input_button" type="submit">
												{button}
											</button>
										</form>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
			</section>
		</>
	)
}
export default Registration
