import React, { useEffect, useState, useRef } from "react"
import Products from "../../components/products/products"
import { url } from "../../helpers/url"
import axios from "axios"
import Pagination from "../../components/pagination/pagination"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import { dynamicView } from "../../helpers/dynamic_view"
import SVG from "../../components/dynamic-svg/dynamic-svg"
import "./merchandise.css"
import RangeSlider from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';
import { screen } from "../../helpers/functions"
import { Helmet } from "react-helmet"

const Shop = (props) => {
    const [product, setProduct] = useState([])
    const [count, setCount] = useState([])
    const [load, setLoad] = useState(false)
    const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
    const [category, setCategory] = useState([])
    const [weight, setWeight] = useState([])
    const [coupon, setCoupon] = useState(false)
    useEffect(() => {
        axios.get(`${url}/products/api?page=0&type=merchandise&limit=20`).then((data) => {
            const result = data.data
            if (result.success) {
                setCordinates({ ...cordinates, ...result.cordinates })
                setProduct(result.rows)
                if (result.count.length > 0) {
                    setCount(result.count)
                }
                setLoad(true)
            }
        })
        axios.get(`${url}/category/api?page=0`).then(({ data }) => {
            setCategory(data.rows)
        })
        axios.get(`${url}/products/weight/api`).then(({ data }) => {
            if (data.success && data.weight && data.weight.variation_value) {
                setWeight(data.weight.variation_value.split("|"))
            }
        })

        dynamicView("page")
    }, [])

    const [next, setNext] = useState(true)
    function getPaginationId(id) {
        const length = 20
        const page = id > 0 ? (id - 1) * length : 0
        axios.get(`${url}/products/api?page=${page}&type=merchandise`).then(({ data }) => {
            if ((length !== data.rows.length) || count.length === id) {
                setNext(false)
            } else {
                setNext(true)
            }
            setProduct(data.rows)
            setCount(data.count)
            setLoad(true)
        })
    }

    const [single, setSingle] = useState()

    const getProduct = (product) => {
        // if (product.variation) {
        //     setSingle(product)
        // }
    }

    const [search, setSearch] = useState("")
    const searchHandler = (e) => {
        setSearch(e.target.value)
    }
    const filterBySearch = (e) => {
        e.preventDefault()
        if (search && search.trim()) {
            axios.post(url + "/search/merchandise/api", { search: search.trim(), page: 0, limit: 20 }).then(({ data }) => {
                if (data.success) {
                    setProduct(data.rows)
                    setCount(data.count)
                    sidebarHandler()
                }
            })
        }
    }

    const [filterCat, setFilterCat] = useState()
    const filterByCategory = (e) => {
        setFilterCat(e.target.value)
        axios.post(url + "/search/merchandise/category/api", { category: e.target.value, page: 0, limit: 20 }).then(({ data }) => {
            if (data.success) {
                setProduct(data.rows)
                setCount(data.count)
                sidebarHandler()
            }
        })

    }

    const [range, setRange] = useState([1, 500])
    const min = 1
    const max = 500

    const filterPriceHandler = (value) => {
        setRange(value)
    }

    const filterByPrice = () => {
        axios.post(url + "/search/merchandise/price/api", { value: range, page: 0, limit: 20 }).then(({ data }) => {
            if (data.success) {
                setProduct(data.rows)
                setCount(data.count)
                sidebarHandler()
            }
        })
    }

    const [curWeight, setCurWeight] = useState()
    const filterByWeight = (cur) => {
        if (cur) {
            const trimCur = cur.trim()
            setCurWeight(cur)
            axios.post(url + "/search/merchandise/weight/api", { search: trimCur, page: 0, limit: 20 }).then(({ data }) => {
                if (data.success) {
                    setProduct(data.rows)
                    setCount(data.count)
                    sidebarHandler()
                }
            })
        }

    }

    const filterBySorting = (e) => {
        const sort = e.target.value
        axios.post(url + "/merchandise/sort/api", { type: sort, page: 0, limit: 20, }).then(({ data }) => {
            if (data.success) {
                setProduct(data.product.rows)
                setCount(data.product.count)
            }
        })
    }


    const sidebarRef = useRef(null)
    const [openSidebar, setOpenSidebar] = useState(false)
    useEffect(() => {
        const click = (e) => {
            if (sidebarRef && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                setOpenSidebar(false)
            }
        };
        window.addEventListener("click", click);
        return () => window.removeEventListener("click", click);
    }, [])

    const sidebarHandler = (redirect = true) => {
        if (screen() <= 800) {
            setOpenSidebar(!openSidebar)
        }
        if (redirect) {
            window.location.href = "/merchandise#sorting"
        }
    }
    const clearFilter = () => {
        axios.get(`${url}/products/api?page=0&type=merchandise&limit=20`).then((data) => {
            const result = data.data
            if (result.success) {
                setFilterCat('')
                setCurWeight('')
                setSearch('')
                setRange([1, 500])
                setCordinates({ ...cordinates, ...result.cordinates })
                setProduct(result.rows)
                if (result.count.length > 0) {
                    setCount(result.count)
                }
                sidebarHandler()
            }

        })
    }
    return (
        <>

            {/* <LoginAlert /> */}
            <section className="mercahndise_main_section">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Merchandise"}</title>
                    <link rel="canonical" href={"https://letsmango.com/merchandise"} />
                    <meta name="description" content={"Merchandise"} />
                </Helmet>
                <Breadcumbs title={"Merchandise"} link={"/merchandise"} linktext={"Merchandise"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
                <div className="container">
                    <div className="merchandise_main">
                        <div className="merchandise_left">
                            <div className="col">
                                <div
                                    className="merchandise_banner_items"
                                    style={{
                                        backgroundImage: `url("${url}/uploads/default/12.jpg")`,
                                    }}
                                >
                                    <div className="merchandise_banner_text">
                                        <h2>Browse our selection of CBD-infused goods</h2>
                                        <p>Elementum eu facilisis sed odio morbi quis commodo odio. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque</p>
                                        {/* <a href="/merchandise" className="global_btn">Shop Now</a> */}
                                    </div>
                                </div>
                                <div className="merchandise_sorting" id="sorting">
                                    <div className="left">
                                        <span>Sorting:</span>
                                        <select name="sort" onChange={filterBySorting} id="">
                                            <option value="">Default sorting</option>
                                            <option value="popularity">Popularity</option>
                                            <option value="Price: Low to High">Price: Low to High</option>
                                            <option value="Price: High to Low">Price: High to Low</option>
                                        </select>
                                    </div>
                                    <div className="middle" ref={sidebarRef}>
                                        {screen() <= 800 && <>
                                            <span onClick={() => sidebarHandler(false)}>Filter</span> <span><i className="fa fa-angle-down"></i></span>
                                        </>
                                        }
                                        {(screen() <= 800 && openSidebar) &&
                                            <div className="merchandise_right">
                                                <div className="search_heading">
                                                    <SVG />
                                                    <span>Search by Products</span>
                                                </div>
                                                <div className="search_input">
                                                    <form onSubmit={filterBySearch} class="merchandise_search_form">
                                                        <input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
                                                        <button className="" type="submit" class="subscribe_btn">
                                                            Search
                                                        </button>
                                                    </form>
                                                </div>
                                                <div className="search_heading">
                                                    <SVG />
                                                    <span>Product Categories</span>
                                                </div>
                                                <div className="search_radio">
                                                    {category.map((item) => {
                                                        return (
                                                            <div class="form-check">
                                                                <input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} id={item.category} value={item.category}
                                                                    checked={filterCat === item.category}
                                                                />
                                                                <label class="form-check-label" for={item.category}>
                                                                    {item.category}
                                                                </label>
                                                            </div>
                                                        )
                                                    })}
                                                </div>

                                                <div className="search_heading">
                                                    <SVG />
                                                    <span>Weight</span>
                                                </div>

                                                <div className="serach_weight">
                                                    {weight.map((item) => (
                                                        <span onClick={() => filterByWeight(item)} className={item === curWeight ? " active badge rounded-pill badge-lg" : "badge rounded-pill badge-lg"}>{item}</span>
                                                    ))}
                                                </div>

                                                {coupon &&
                                                    <div className="serach_coupon">
                                                        <div
                                                            className="search_coupon_text"
                                                            style={{
                                                                backgroundImage: `url("${url}/uploads/default/coupon_bg.png")`,
                                                            }}
                                                        >
                                                            <div className="search_coupon_text_items">
                                                                <h3>Everything is 20% off for this special day!</h3>
                                                                <p>Excluding items already on sale</p>
                                                                <div className="coupon_time">
                                                                    <span className="no">22</span>
                                                                    <span className="clone">:</span>
                                                                    <span className="no">00</span>
                                                                    <span className="clone">:</span>
                                                                    <span className="no">59</span>
                                                                </div>
                                                                <a className="global_btn" href="">
                                                                    Shop Now
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="search_heading">
                                                    <SVG />
                                                    <span>Filter by Price</span>
                                                </div>
                                                <div className="filter_price">
                                                    <RangeSlider defaultValue={range} step={1} onThumbDragEnd={filterByPrice} onInput={filterPriceHandler} max={max} min={min} />

                                                    <div className="range_price">
                                                        <div className="left">
                                                            <p>{min}$</p>
                                                        </div>
                                                        <div className="middle">
                                                            <p>{range[0] + '$-' + range[1]}$</p>
                                                        </div>
                                                        <div className="right">
                                                            <p>{max}$</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="search_radio clear_filter merchandise_clear">
                                                    <div className="search_radio" onClick={clearFilter}>
                                                        <i className="fa fa-close"></i>
                                                        <span>Clear Filter</span>
                                                    </div>
                                                </div>

                                            </div>
                                        }
                                    </div>

                                    <div className="right">
                                        <p>Showing 1–{product.length} of {count.length > 0 ? count.length * 20 : product.length} results</p>
                                    </div>
                                </div>
                            </div>

                            {product.length > 0 && load && <Products cartItem={getProduct} productType="merchandise" data={product} viewItem="true" menu="false" cordinates={cordinates} rows={count} />}

                            {product.length === 0 && load && <div className="alert alert-danger my-3">
                                <p className="title_color">There is no products</p>
                            </div>
                            }


                            {count.length > 0 && <Pagination product={product.length} count={count} clickId={getPaginationId} isNext={next} />}
                        </div>


                        {screen() > 800 &&
                            <div className="merchandise_right">
                                <div className="search_heading">
                                    <SVG />
                                    <span>Search by Products</span>
                                </div>
                                <div className="search_input">
                                    <form onSubmit={filterBySearch} class="merchandise_search_form">
                                        <input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
                                        <button className="" type="submit" class="subscribe_btn">
                                            Search
                                        </button>
                                    </form>
                                </div>
                                <div className="search_heading">
                                    <SVG />
                                    <span>Product Categories</span>
                                </div>
                                <div className="search_radio">
                                    {category.map((item) => {
                                        return (
                                            <div class="form-check">
                                                <input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} checked={filterCat === item.category} id={item.category} value={item.category} />
                                                <label class="form-check-label" for={item.category}>
                                                    {item.category}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>

                                <div className="search_heading">
                                    <SVG />
                                    <span>Weight</span>
                                </div>

                                <div className="serach_weight">
                                    {weight.map((item) => (
                                        <span onClick={() => filterByWeight(item)} className={item === curWeight ? " active badge rounded-pill badge-lg" : "badge rounded-pill badge-lg"}>{item}</span>
                                    ))}
                                </div>

                                {coupon &&
                                    <div className="serach_coupon">
                                        <div
                                            className="search_coupon_text"
                                            style={{
                                                backgroundImage: `url("${url}/uploads/default/coupon_bg.png")`,
                                            }}
                                        >
                                            <div className="search_coupon_text_items">
                                                <h3>Everything is 20% off for this special day!</h3>
                                                <p>Excluding items already on sale</p>
                                                <div className="coupon_time">
                                                    <span className="no">22</span>
                                                    <span className="clone">:</span>
                                                    <span className="no">00</span>
                                                    <span className="clone">:</span>
                                                    <span className="no">59</span>
                                                </div>
                                                <a className="global_btn" href="">
                                                    Shop Now
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="search_heading">
                                    <SVG />
                                    <span>Filter by Price</span>
                                </div>
                                <div className="filter_price">
                                    <RangeSlider defaultValue={range} step={1} onThumbDragEnd={filterByPrice} onInput={filterPriceHandler} max={max} min={min} />
                                    <div className="range_price">
                                        <div className="left">
                                            <p>{min}$</p>
                                        </div>
                                        <div className="middle">
                                            <p>{range[0] + '$-' + range[1]}$</p>
                                        </div>
                                        <div className="right">
                                            <p>{max}$</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="search_radio clear_filter merchandise_clear">
                                    <div className="search_radio" onClick={clearFilter}>
                                        <i className="fa fa-close"></i>
                                        <span>Clear Filter</span>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </section>
        </>
    )
}
export default Shop
