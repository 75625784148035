import React, { useState } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import { Row, Col, Form, FormControl } from "react-bootstrap"
import "./subscribe.css"

const Subscribe = () => {
	const [email, setEmail] = useState({ email: "", success: "" })
	const submit = (e) => {
		e.preventDefault()
		const email = e.target.email.value
		axios.post(`${url}/subscribe/api`, { email: email }).then((data) => {
			const result = data.data
			setEmail({ ...email, ...result })
		})
	}

	return (
		<>
			<Row className="subscribe">
				<Col className="subscribe_col">
					<div className="subscribe_items" style={{
						backgroundImage: `url("${url}/uploads/default/16.jpg")`
					}}>
						<div className="subscribe_content">
							<h3>Sign up for our newsletter</h3>
							<p>Our e-newsletter delivers info on promotions, new products, and sales. Directly to your inbox.</p>

							{email.success && <div className="alert alert-success">Thanks for your subscription</div>}

							{!email.success && (
								<>
									<Form className="subscribe_form justify-content-center form-inline" onSubmit={submit}>
										<FormControl name="email" type="email" placeholder="Enter Your Email" className="subscribe_email" />

										<button type="submit" className="subscribe_btn">
											Send
										</button>
									</Form>

								</>
							)}
							{email.email && <div className="alert alert-danger mt-2">This email already exists on our records</div>}
						</div>
					</div>
				</Col>

			</Row>
		</>
	)
}

export default Subscribe
