import React, { useEffect, useState, useRef } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import Pagination from "../../components/pagination/pagination"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import { dynamicView } from "../../helpers/dynamic_view"
import SVG from "../../components/dynamic-svg/dynamic-svg"
import BusinessCom from "../../components/business/business"
import Map from '../../components/new-map/map'
import { screen } from "../../helpers/functions"
import { Helmet } from "react-helmet"
import './business.css'

const Shop = (props) => {
    const [bus, setBus] = useState([])
    const [count, setCount] = useState([])
    const [load, setLoad] = useState(false)
    const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
    useEffect(() => {
        axios.get(`${url}/business/api?page=0`).then(({ data }) => {
            if (data.success) {
                setCordinates(data.cordinates)
                setBus(data.rows)
                setCount(data.count)
                setLoad(true)
            }
        })
        dynamicView("page")
    }, [])
    const [next, setNext] = useState(true)

    function getPaginationId(id) {
        const length = 20
        const page = id > 0 ? (id - 1) * length : 0
        axios.get(`${url}/business/api?page=${page}`).then(({ data }) => {
            if ((length !== data.rows.length) || count.length === id) {
                setNext(false)
            } else {
                setNext(true)
            }
            setBus(data.rows)
            setCount(data.count)
            setLoad(true)
        })
    }


    const [search, setSearch] = useState("")
    const searchHandler = (e) => {
        setSearch(e.target.value)
    }
    const filterBySearch = (e) => {
        e.preventDefault()
        if (search && search.trim()) {
            axios.post(url + "/search/business/api", { search: search.trim(), page: 0, limit: 20, lat: cordinates.lat, lng: cordinates.lng }).then(({ data }) => {
                if (data.success) {
                    setBus(data.rows)
                    setCount(data.count)
                    sidebarHandler()
                }
            })
        }
    }

    const [filterCat, setFilterCat] = useState()
    const filterByCategory = (e) => {
        setFilterCat(e.target.value)
        axios.post(url + "/search/business/category/api", { category: e.target.value, page: 0, limit: 20 }).then(({ data }) => {
            if (data.success) {
                setBus(data.rows)
                setCount(data.count)
                sidebarHandler()
            }
        })

    }

    const filterBySorting = (e) => {
        const sort = e.target.value
        axios.post(url + "/business/sort/api", { type: sort, page: 0, limit: 20, }).then(({ data }) => {
            if (data.success) {
                setBus(data.product.rows)
                setCount(data.product.count)
            }
        })
    }

    const business_category = ['Brand', 'CBD Store', 'Dispensary', 'Delivery', 'Doctor']

    const [currentPage, setCurrentPage] = useState('list')
    const isMapHandler = (type) => {
        setCurrentPage(type)
    }

    const sidebarRef = useRef(null)
    const [openSidebar, setOpenSidebar] = useState(false)
    useEffect(() => {
        const click = (e) => {
            if (sidebarRef && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
                setOpenSidebar(false)
            }
        };
        window.addEventListener("click", click);
        return () => window.removeEventListener("click", click);
    }, [])

    const sidebarHandler = (redirect = true) => {
        if (screen() <= 800) {
            setOpenSidebar(!openSidebar)
        }
        if (redirect) {
            window.location.href = "/dispensary#sorting"
        }
    }

    const clearFilter = () => {
        axios.get(`${url}/business/api?page=0`).then(({ data }) => {
            if (data.success) {
                setFilterCat('')
                setSearch('')
                setCordinates(data.cordinates)
                setBus(data.rows)
                setCount(data.count)
                setLoad(true)
                sidebarHandler()
            }
        })
    }

    return (
        <>
            <section className="mercahndise_main_section business_main_section">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{"Dispensary"}</title>
                    <link rel="canonical" href={"/dispensary"} />
                    <meta name="description" content={"Cannabis Dispensary"} />
                </Helmet>

                <Breadcumbs title={"Dispensaries"} link={"/dispensary"} linktext={"Dispensaries"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
                <div className="container">
                    <div className="merchandise_main">
                        <div className="merchandise_left">
                            <div className="col">
                                <div className="business_sorting" id="sorting">
                                    <div className="left">
                                        <div className={currentPage === 'list' ? "active dispensary_icon_1" : "dispensary_icon_1"} onClick={() => isMapHandler('list')}>
                                            <img src={currentPage === 'list' ? url + '/uploads/default/list-icon-white.png' : url + '/uploads/default/list-icon-black.png'} alt="" />
                                            <span>List View</span>
                                        </div>
                                        <div className={currentPage === 'map' ? "active dispensary_icon_2" : "dispensary_icon_2"} onClick={() => isMapHandler('map')}>
                                            <img src={currentPage === 'map' ? url + '/uploads/default/map-icon-white.png' : url + '/uploads/default/map-icon-black.png'} alt="" />
                                            <span>Map View</span>
                                        </div>
                                    </div>

                                    {(currentPage === 'list' && screen() <= 800) &&
                                        <div className="middle" ref={sidebarRef}>
                                            <span onClick={() => sidebarHandler(false)}>Filter</span> <span><i className="fa fa-angle-down"></i></span>

                                            {(screen() <= 800 && openSidebar) &&
                                                <div className="merchandise_right">
                                                    <div className="search_heading">
                                                        <SVG />
                                                        <span>Search by Dispensaries</span>
                                                    </div>
                                                    <div className="search_input">
                                                        <form onSubmit={filterBySearch} class="merchandise_search_form">
                                                            <input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
                                                            <button className="" type="submit" class="subscribe_btn">
                                                                Search
                                                            </button>
                                                        </form>
                                                    </div>
                                                    <div className="search_heading">
                                                        <SVG />
                                                        <span>Business Categories</span>
                                                    </div>
                                                    <div className="search_radio">
                                                        {business_category.map((item) => {
                                                            return (
                                                                <div class="form-check">
                                                                    <input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} id={item} value={item}
                                                                        checked={filterCat === item}
                                                                    />
                                                                    <label class="form-check-label" for={item}>
                                                                        {item}
                                                                    </label>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="search_radio clear_filter merchandise_clear">
                                                        <div className="search_radio" onClick={clearFilter}>
                                                            <i className="fa fa-close"></i>
                                                            <span>Clear Filter</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    }

                                    {currentPage === 'list' &&
                                        <div className="right">
                                            <span>Sorting:</span>
                                            <select name="sort" onChange={filterBySorting} id="">
                                                <option value="">Default sorting</option>
                                                <option value="popularity">Popularity</option>
                                                <option value="reviews">Reviews</option>
                                            </select>
                                        </div>
                                    }
                                </div>
                            </div>
                            {(bus.length > 0 && load && cordinates.lat && cordinates.lng && currentPage === 'list') && <BusinessCom cordinates={cordinates} data={bus} title="Dispensaries Near Me" />}

                            {(bus.length === 0 && load) && (
                                <div className="alert alert-danger my-3">
                                    <p className="title_color">There are no business page</p>
                                </div>
                            )}
                            {(currentPage === 'list' && count.length > 0) && <Pagination product={bus.length} count={count} clickId={getPaginationId} isNext={next} />}
                        </div>
                        {(currentPage === 'list' && screen() > 800) &&
                            <div className="merchandise_right">
                                <div className="search_heading">
                                    <SVG />
                                    <span>Search by Dispensaries</span>
                                </div>
                                <div className="search_input">
                                    <form onSubmit={filterBySearch} class="merchandise_search_form">
                                        <input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
                                        <button className="" type="submit" class="subscribe_btn">
                                            Search
                                        </button>
                                    </form>
                                </div>
                                <div className="search_heading">
                                    <SVG />
                                    <span>Business Categories</span>
                                </div>
                                <div className="search_radio">
                                    {business_category.map((item) => {
                                        return (
                                            <div class="form-check">
                                                <input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} id={item} value={item}
                                                    checked={filterCat === item} />

                                                <label class="form-check-label" for={item}>
                                                    {item}
                                                </label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="search_radio clear_filter merchandise_clear">
                                    <div className="search_radio" onClick={clearFilter}>
                                        <i className="fa fa-close"></i>
                                        <span>Clear Filter</span>
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                    {(currentPage === 'map') && <Map />}
                </div>
            </section>
        </>
    )
}
export default Shop
