import { useEffect } from "react"
import { dynamicView } from "../../helpers/dynamic_view"
import { url } from "../../helpers/url"
import Breadcumbs from "../breadcumbs/breadcumbs"
import Accordion from "../accordion/accordion"
import "./about.css"

const About = () => {
  useEffect(() => {
    dynamicView("page")
  }, [])

  return (
    <>

      <div className="about">

        <Breadcumbs title={"About Us"} link={"/about"} linktext={"About"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
        <div className="container">


          <div className="row discover">
            <div className="col-lg-6">
              <h2>Our knowledgeable team can help you design experiences tailored to your needs by accessing one of the flower selections</h2>
              <p>Elementum eu facilisis sed odio morbi quis commodo odio. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Accumsan sit amet nulla facilisi morbi tempus. Suscipit tellus mauris a diam maecenas sed enim ut sem. Turpis egestas maecenas pharetra convallis posuere</p>
            </div>
            <div className="col-lg-6 discover_img">
              <div className="img">
                <img src={url + "/uploads/default/19.jpg"} alt="" />
              </div>
            </div>
          </div>
          {/* <div className="row counter">
            <div className="col-lg-3 counter_box  col-md-6 col-sm-6">
              <h2>180+</h2>
              <p>New products to explore. Arcu vitae elementum curabitur vitae nunc sed</p>
            </div>
            <div className="col-lg-3 counter_box  col-md-6 col-sm-6">
              <h2>3X</h2>
              <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc </p>
            </div>
            <div className="col-lg-3 counter_box  col-md-6 col-sm-6">
              <h2>100%</h2>
              <p>Mauris a diam maecenas sed enim ut sem curabitur vitae nunc sed </p>
            </div>
            <div className="col-lg-3 counter_box col-md-6 col-sm-6">
              <h2>11K</h2>
              <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc </p>
            </div>
          </div> */}


        </div>


        {/* <div className="brand_container_wrapper">
          <div className="container">
            <div className="row brand_heading instagram">
              <div className="col">
                <h2>Our clients</h2>
                <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Placeat distinctio voluptates sapiente velit quae a nostrum maiores illum sequi reiciendis.</p>
              </div>
            </div>
            <div className="brand_logo row">
              <div className="col brand_logo_main">
                <div className="brand_logo_item">
                  <img src={url + '/uploads/default/frame1.png'} alt="" />
                </div>
                <div className="brand_logo_item">
                  <img src={url + '/uploads/default/frame2.png'} alt="" />
                </div>
                <div className="brand_logo_item">
                  <img src={url + '/uploads/default/frame3.png'} alt="" />
                </div>
                <div className="brand_logo_item">
                  <img src={url + '/uploads/default/frame4.png'} alt="" />
                </div>
                <div className="brand_logo_item">
                  <img src={url + '/uploads/default/frame5.png'} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="works">
            <div className="row brand_heading instagram">
              <div className="col">
                <h2>How We Works</h2>
                <p>Accumsan sit amet nulla facilisi morbi tempus. Suscipit tellus mauris a diam maecenas sed enim ut sem.</p>
              </div>
            </div>

            <div className="row works_item">
              <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <img src={url + '/uploads/default/works1.png'} alt="" />
                <h2>Free Shipping & Returns</h2>
                <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc  </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <img src={url + '/uploads/default/works2.png'} alt="" />
                <h2>Money Back Guarantee</h2>
                <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc  </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <img src={url + '/uploads/default/works3.png'} alt="" />
                <h2>Online Support</h2>
                <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc  </p>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 mb-4">
                <img src={url + '/uploads/default/works4.png'} alt="" />
                <h2>100% Secure Payment</h2>
                <p>Quis risus sed vulputate odio ut. Arcu vitae elementum curabitur vitae nunc  </p>
              </div>
            </div>
          </div>
          <div className="strategy row">
            <div className="col-lg-8 col-md-7">
              <div className="content-box">
                <h2>How did it all started</h2>
                <p>Elit scelerisque mauris pellentesque pulvinar. Quam adipiscing vitae proin sagittis nisl. Tempor commodo ullamcorper a lacus vestibulum sed arcu non odio. In dictum non consectetur a. Nunc vel risus commodo viverra</p>
                <p>Commodo viverra maecenas accumsan lacus vel facilisis volutpat. Nec tincidunt praesent semper feugiat nibh sed. Felis eget nunc lobortis mattis aliquam. Iaculis eu non diam phasellus vestibulum. Aliquam id diam maecenas ultricies mi eget mauris.</p>
              </div>
              <div className="content-box">
                <h2>What do we stand for</h2>
                <p>Quisque non tellus orci ac auctor augue mauris augue neque. Lacus sed viverra tellus in hac habitasse platea dictumst. Euismod elementum nisi quis eleifend quam adipiscing vitae proin.</p>
                <p>Dui faucibus in ornare quam viverra orci sagittis eu. Libero nunc consequat interdum varius sit amet. Ut aliquam purus sit amet luctus venenatis lectus. Pharetra pharetra massa massa ultricies mi quis. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor aliquam nulla. Lectus sit amet est placerat in</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-5">
              <div className="img">
                <img src={url + '/uploads/default/strategy.png'} alt="" />
              </div>
              <div className="quote">
                <p>“ Aliquet risus feugiat in ante metus dictum at tempor. Id leo in vitae turpis massa sed elementum tempus egestas. Placerat orci nulla pellentesque dignissim enim sit amet. Justo eget magna fermentum iaculis eu non diam phasellus ”</p>
              </div>
            </div>
          </div>

          {/* <div className="row brand_heading instagram">
            <div className="col">
              <h2>Meet the Specialist Team</h2>
              <p>Vivamus at augue eget arcu dictum varius. Pharetra et ultrices neque ornare.</p>
            </div>
          </div>

          <div className="team row">
            <div className="col-lg-3 mb-4 col-md-6">
              <img src={url + '/uploads/default/team1.png'} alt="" />
              <h2>Richard Edwards</h2>
              <p>CEO & Founder</p>
            </div>
            <div className="col-lg-3 mb-4 col-md-6">
              <img src={url + '/uploads/default/team2.png'} alt="" />
              <h2>Richard Edwards</h2>
              <p>CEO & Founder</p>
            </div>
            <div className="col-lg-3 mb-4 col-md-6">
              <img src={url + '/uploads/default/team3.png'} alt="" />
              <h2>Richard Edwards</h2>
              <p>CEO & Founder</p>
            </div>
            <div className="col-lg-3 mb-4 col-md-6">
              <img src={url + '/uploads/default/team4.png'} alt="" />
              <h2>Richard Edwards</h2>
              <p>CEO & Founder</p>
            </div>
          </div> */}

          <div className="faq row">
            <div className="col-lg-4 col-md-12 fag_img_col">
              <img src={url + "/uploads/default/accordion.png"} alt="" />
            </div>
            <div className="col-lg-8 col-md-12">
              <div className="faq_heading">
                <h2>The Effects of Cannabis on Your Body</h2>
                <p>Elementum eu facilisis sed odio morbi quis commodo odio. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque. Accumsan sit amet nulla facilisi morbi tempus</p>
              </div>
              <Accordion />
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default About
