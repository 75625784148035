import "./favourite.css"
import { useContext, useState, useEffect } from "react"
import { url, } from "../../helpers/url"
import { math } from "../../helpers/functions"
import { Context } from "../../helpers/context"
import { Toastify, Toast } from "../toastify/toastify"
import axios from "axios"
import { dynamicTitle } from "../../helpers/functions"
import { Link } from "react-router-dom"
import Pagination from "../pagination/pagination"
const Favorite = ({ type, title, data, link, singleLink, cartItem, countItem }) => {
	const [user, setUser] = useContext(Context)
	const [notification, setNotification] = useState(false)
	const [fav, setFav] = useState([])
	const [count, setCount] = useState([])
	useEffect(() => {
		setFav(data)
		setCount(countItem)
	}, [data, countItem])

	const addToCart = (product, cartMode, curVariaiton, variationId) => {
		let updateCart
		if (localStorage.getItem('cart')) {
			updateCart = JSON.parse(localStorage.getItem('cart'))
		} else {
			updateCart = {}
		}

		if (cartMode === "add") {
			cartItem(product)
			setNotification(true)
			if (!curVariaiton) {
				let total = updateCart[product.id] ? updateCart[product.id].total : 0
				let min = product.min
				let stock = product.stock
				if (stock >= (total + min)) {
					updateCart[product.id] = {
						id: product.id,
						title: product.title,
						price: product.price,
						discount: product.discount,
						total: total + product.min,
						image: (product.image && JSON.parse(product.image).length > 0) ? JSON.parse(product.image)[0].url : process.env.REACT_APP_PORUDCT_IMAGE,
						stock: product.stock,
						min: product.min,
						url: product.url
					}
					Toast("Product is added to cart")
				}
				else {
					Toast("You can not add more item because it is out of stock")
				}
			}
			if (curVariaiton) {
				let total = updateCart[product.id + "_" + variationId] ? parseInt(updateCart[product.id + "_" + variationId].total) : 0
				let min = parseInt(curVariaiton.min)
				let stock = parseInt(curVariaiton.stock)
				if (stock >= (total + min)) {
					updateCart[product.id + "_" + variationId] = {
						id: product.id,
						title: dynamicTitle(product.title, curVariaiton),
						variationId: variationId,
						price: curVariaiton.price,
						discount: curVariaiton.discount,
						total: total ? total + 1 : min,
						image: curVariaiton.image.url,
						color: curVariaiton.Color,
						size: curVariaiton.Size,
						weight: curVariaiton.Weight,
						stock: stock,
						min: min,
						url: product.url
					}
					Toast("Product is added to cart")
				} else {
					Toast("You can not add more item because it is out of stock")
				}
			}
		}
		setUser({ ...user, ...{ cart: updateCart } })
		if (typeof window !== "undefined") {
			localStorage.setItem("cart", JSON.stringify(updateCart))
		}
	}

	const favoriteRmHandler = (id) => {
		const obj = type === "business" ? { businessId: id } : { productId: id }
		axios.post(`${url}/wishlist/${type === "business" ? "business" : "product"}/api`, obj).then(({ data }) => {
			if (data.success) {
				const filterFav = fav.filter((item) => item.id !== id)
				setFav(filterFav)
			}
		})
	}

	const [next, setNext] = useState(true)
	function getPaginationId(id) {
		const length = 10
		const page = id > 0 ? (id - 1) * length : 0
		axios.get(`${url}/favourite/api?${type}Query=${type}Query&page=${page}`).then(({ data }) => {
			if ((length !== data[type].rows.length) || data[type].count.length === id) {
				setNext(false)
			} else {
				setNext(true)
			}
			setFav(data[type].rows)
			setCount(data[type].count)

		})
	}

	return (
		<>
			<section className="favorite">
				<div className="container">
					{notification && <Toastify />}
					{fav.length > 0 && (
						<div className="favorite_header">
							<div className="favourite_header_item">
								<p>{title}</p>
								<Link className="" to={link}>
									View All
								</Link>
							</div>
							<div className="line"></div>
						</div>
					)}

					{fav.map((item, i) => {
						let image
						let price
						let discount
						let curVariaiton
						let variationId
						let currentURL
						if (item.variation && type === 'merchandise') {
							const parseVairation = JSON.parse(item.variation)
							const parseVairationKeys = Object.keys(parseVairation)
							variationId = parseVairationKeys[0]
							curVariaiton = parseVairation[variationId]
							price = curVariaiton.price
							discount = curVariaiton.discount
							if (curVariaiton.image) {
								image = curVariaiton.image.url
							}
							currentURL = "/merchandise/" + item.url
						}
						else if (!item.variation && type === 'merchandise') {
							if (item.image && JSON.parse(item.image).length > 0) {
								image = JSON.parse(item.image)[0].url
							}
							price = item.price
							discount = item.discount
							currentURL = "/merchandise/" + item.url
						}
						else if (type === 'product') {
							if (item.image && JSON.parse(item.image).length > 0) {
								image = JSON.parse(item.image)[0].url
							}
							price = item.price
							discount = item.discount
							currentURL = "/strain/" + item.url
						}
						else if (type === 'business' && item.profile && JSON.parse(item.profile)) {
							image = JSON.parse(item.profile).url
							currentURL = "/dispensary/" + item.url
						}

						return (
							<div className="row favourite_box">
								<div className="col">
									<div className="left_favourite_box">
										<div className="img">
											<img src={image} alt="" />
										</div>

										<div className="details">
											<Link to={currentURL}>
												<p className="title">{item.title}</p>
											</Link>
											{type === "merchandise" && (!discount || discount === 0) && <p className="favorite_price">${math(price).toFixed(2)}</p>
											}
											{type === "merchandise" && discount > 0 && <p className="favorite_price">${math(discount).toFixed(2)} <del className="discount">${math(price).toFixed(2)}</del></p>
											}

											{type === "business" && (
												<>
													<div className="location">
														<img src={url + "/uploads/default/fav_location.png"} alt="" /> <span>{item.address}</span>
													</div>
												</>
											)}
										</div>
									</div>
								</div>
								<div className="col">
									<div className="right_favourite_box">
										<div className="delete">
											<div className="delete_icon" onClick={() => favoriteRmHandler(item.id)}>
												<i className="fa fa-trash"></i>
												<span>Remove</span>
											</div>

											<div className="favourite_btn_main">
												{(type === "business" || type === "product") && (
													<Link to={singleLink + item.url} className="custom_btn">
														<div class="favourite_btn">
															View
														</div>
													</Link>
												)}
												{type === "merchandise" && (
													<div class="favourite_btn" onClick={() => addToCart(item, "add", curVariaiton, variationId)}>
														<img class="carticon" src={url + "/uploads/default/cart.png"} alt="" />
														<a class="custom_btn" rel="noopener noreferrer ">
															Add to Cart
														</a>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						)
					})}

					<div className="favourite_pagination">
						{count.length > 0 && <Pagination isNext={next} product={fav.length} count={count} clickId={getPaginationId} />}
					</div>

				</div>
			</section>
		</>
	)
}
export default Favorite
