import React, { useState, useEffect } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import Friend from "../../components/social/friend/friend"

const Friends = (props) => {
  const [friend, setFriend] = useState([])
  const [count, setCount] = useState([])
  const [load, setLoad] = useState(false)
  useEffect(() => {
    axios.get(`${url}/social/block?page=0`).then(({ data }) => {
      if (data.success) {
        const update = data.rows.map(item => {
          return {
            fname: item['registers.fname'],
            lname: item['registers.lname'],
            image: item['registers.image'],
            receiverId: item.receiverId,
            userId: item.userId,
            status: item.status,
            id: item.id,
          }
        })
        setFriend(update)
        setCount(data.count)
        setLoad(true)
      }

    })
  }, [])

  return (
    <>
      <div className="container friend_container">
        <Friend more={"social/block?type=0"} type="block" data={friend} count={count} load={load} title="Block List" message="You don't have any block friend" link="/social/find-friend" button="Find Friend" />
      </div>
    </>
  )
}
export default Friends
