
import { useState, useEffect } from "react"
import './pagination.css'
const Pagination = ({ count, clickId, isNext }) => {
  const [pagiantion, setPagination] = useState([])
  useEffect(() => {
    setNext(isNext)
    setPagination(count)
  }, [count])

  const [current, setCurrent] = useState(1)
  const [next, setNext] = useState(true)
  const clickHandler = (id) => {
    if (id === 0 && current > 1) {
      setCurrent(current - 1)
      clickId(current - 1)
    } else {
      setCurrent(id)
      clickId(id)
    }
  }

  return (
    <div className="row pagination_row">
      <div className="col">
        <nav aria-label="...">
          <ul class="pagination">
            {current > 1 &&
              <li className={current > 1 ? "page-item" : "page-item disabled"}>
                <a className="page-link" onClick={() => clickHandler(current - 1)} tabindex="-1">Previous</a>
              </li>
            }

            {
              pagiantion.map(item => <li className={current === item ? "page-item active" : "page-item"}><a className="page-link" onClick={() => clickHandler(item)}>{item}</a></li>)
            }
            {next && <li class="page-item">
              <a className="page-link" onClick={() => clickHandler(current + 1)}>Next</a>
            </li>}

          </ul>
        </nav>

      </div>
    </div>

  )
}
export default Pagination