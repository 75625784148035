import { url } from "../../helpers/url"
import { Row, Col, } from "react-bootstrap"
import "./footer.css"
import CookieConsent from "react-cookie-consent";
import ContactList from "../contact/contact";
const year = new Date().getFullYear()

const Footer = () => {
	return (
		<>
			<footer
				className="footer">
				<Row className="text-light footer_row">
					<div className="container ">
						<div className="footer_box row">

							<div className="mt-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<h6 className="footer_title text-left">
									<a href="#" className="d-block text-left logo" rel="noopener noreferrer">
										<img src={url + '/uploads/default/mango.png'} alt="" />
									</a>
								</h6>
								<p className=" description">Navigating the World of Cannabis Through Trusted Reviews.</p>
								<div className="box_item_title">
									<p className="follow">Follow Us</p>
								</div>
								<Col className="text-left p-0 d-flex">
									<div className="box_item mr-3">
										<a href="#" className="d-inline-block text-light text-left" rel="noopener noreferrer">
											<i className="fa fa-facebook"></i>
										</a>
									</div>
									<div className="box_item mr-3">
										<a href="#" className="d-inline-block text-light text-left" rel="noopener noreferrer">
											<i className="fa fa-twitter"></i>
										</a>
									</div>
									<div className="box_item mr-3">
										<a href="#" className="d-inline-block text-light text-left" rel="noopener noreferrer">
											<i className="fa fa-instagram"></i>
										</a>
									</div>
									<div className="box_item">
										<a href="#" className="d-inline-block text-light text-left" rel="noopener noreferrer">
											<i className="fa fa-whatsapp"></i>
										</a>
									</div>
								</Col>

							</div>
							<div className="mt-3 col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<h6 className="footer_title text-left">Contacts informatiom</h6>
								<ContactList />

							</div>
							<div className="mt-3 menu_list col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<h6 className="footer_title text-left">Shop Products</h6>
								<a href="/business-register" className="d-block text-light  text-left" rel="noopener noreferrer">
									Business Owner
								</a>
								<a href="/search-business" className="d-block text-light  text-left" rel="noopener noreferrer">
									Search Business
								</a>

								<a href="/writer" className="d-block text-light  text-left" rel="noopener noreferrer">
									News Writer
								</a>
								<a href="/subscription" className="d-block text-light  text-left" rel="noopener noreferrer">
									Subscription
								</a>
								<a href="/news" className="d-block text-light  text-left" rel="noopener noreferrer">
									Latest
								</a>
								<a href="/about" className="d-block text-light  text-left" rel="noopener noreferrer">
									About Us
								</a>
								<a href="/contact" className="d-block text-light  text-left" rel="noopener noreferrer">
									Contact Us
								</a>

							</div>

							<div className="mt-3 menu_list col-lg-3 col-md-6 col-sm-6 col-xs-12">
								<h6 className="footer_title text-left">Our Policy</h6>
								<a href="/terms" className="d-block text-light  text-left" rel="noopener noreferrer">
									Privacy Policy
								</a>
								<a href="/terms" className="d-block text-light  text-left" rel="noopener noreferrer">
									Terms & Conditions
								</a>

								<a href="#" className="d-block text-light  text-left" rel="noopener noreferrer">
									Developer
								</a>
								<a href="#" className="d-block text-light  text-left" rel="noopener noreferrer">
									FAQ
								</a>
							</div>
						</div>
					</div>
				</Row>
				<div className="container_wrapper">
					<div className="container">
						<Row className="copyright">
							<div className="col copyright_list">
								<div className="left">
									<p className="m-auto">Copyright © {year} Lets Mango, All rights reserved</p>
								</div>
								<div className="right">
									<img src={url + "/uploads/default/Payment.png"} alt="" />
								</div>
							</div>
						</Row>
					</div>
				</div>


				<CookieConsent
					location="bottom"
					buttonText="Close"
					cookieName="myAwesomeCookieName2"
					style={{ background: "#2B373B" }}
					buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
					expires={30}
				>
					<p>FDA (Food and Drug Administration) Required Disclosure:</p>
					<p>These products have not been evaluated for or intended to diagnose, treat, cure, or prevent any disease. Use only as instructed in the label. Consult your local physician before using cannabis for any medical condition, or in combination with other prescription medications.
					</p>
				</CookieConsent>

			</footer>
		</>
	)
}
export default Footer
