import React, { useState, useEffect, useRef, useContext } from "react"
import "./chat.css"
import axios from "axios"
import { url, imgPath } from "../../../helpers/url"
import { screen, } from "../../../helpers/functions"

import InfiniteScroll from "react-infinite-scroller"
import { getChatListDate, getMsgDate } from "../../../helpers/time"
import { substring } from "../../../helpers/functions"
import { NavLink, useLocation } from "react-router-dom"
import { dynamicHTML } from "../../../helpers/dynamic_html"
import { Context } from "../../../helpers/context"
import { Link } from "react-router-dom"

const Emoj = typeof window !== "undefined" && require("emoji-picker-react").default

const Chat = ({ type }) => {
	console.log(imgPath)
	const [user, setUser] = useContext(Context)
	const [chatbox, setChatbox] = useState([])
	const [oldChatbox, setOldChatbox] = useState([])
	const [msg, setMsg] = useState([])
	const [arrival, setArrival] = useState("")
	const [text, setText] = useState("")
	const [load, setLoad] = useState(false)
	const [loginId, setLoginId] = useState("")
	const [receiver, setReceiver] = useState("")
	const [image, setImage] = useState({})
	const [click, setClick] = useState(false)
	let inputFile = useRef(null)
	const msgRef = useRef(null)
	const emojRef = useRef(null)
	const location = useLocation()
	// Search text start
	const [timer, setTimer] = useState()
	const [search, setSearch] = useState()
	const [isSearch, setIsSearch] = useState(false)
	const changeDelay = (e) => {
		const searchText = e.target.value
		setSearch(searchText)

		if (searchText) {
			setIsSearch(true)
		} else {
			setIsSearch(false)
		}

		if (timer) {
			clearTimeout(timer)
			setTimer(null)
		}

		setTimer(
			setTimeout(() => {
				if (searchText && searchText.length > 2) {
					axios.get(`${url}/social/message/search?search=${searchText}`).then(({ data }) => {
						setIsSearch(false)
						if (data.success && data.length > 0) {
							setChatbox(data.chatbox)
						} else {
							setChatbox(oldChatbox)
						}
					})
				}
			}, 1000)
		)
	}
	// Search text end

	useEffect(() => {
		axios.get(`${url}/social/message?type=box&page=0`).then(({ data }) => {
			if (data.success) {
				if (type === 'dynamic') {
					let copyBox = data.chatBox
					const id = location.pathname.split('/')[3]
					copyBox.map(item => {
						if (item.receiverId === parseInt(id)) {
							item.total = 0
						}
						return item
					})
					setChatbox(copyBox)
					setOldChatbox(copyBox)
				} else {

					setChatbox(data.chatBox)
					setOldChatbox(data.chatBox)
				}
				setLoginId(data.loginId)
				setLoad(true)
			}
		})

		if (type === 'dynamic') {
			const id = location.pathname.split('/')[3]
			setText("")
			setImage({})
			setPage({ ...page, msg: 1 })
			setHasMore({ ...hasMore, msg: true })
			axios.get(`${url}/social/message?type=message&id=${id}&current=customer&page=0&dynamic=true`).then(({ data }) => {
				if (data.success) {
					const rcb = {
						fname: data.chatBox['registers.fname'],
						lname: data.chatBox["registers.lname"],
						receiverId: id,
						image: data.chatBox["registers.image"],
						type: data.chatBox.type,
					}
					setMsg(data.message.rows)
					setReceiver(rcb)
					setClick(true)
				}
			})
		}
	}, [])



	useEffect(() => {
		if (user.socket) {
			user.socket.on("getMessage", (data) => {
				setArrival(data)
			})
		}
	}, [user.socket])

	useEffect(() => {
		if (arrival && arrival.message.userId) {
			if (parseInt(receiver.receiverId) === parseInt(arrival.message.userId)) {
				setMsg([...msg, arrival.message])
				msgRef.current.scrollTop = msgRef.current.scrollHeight
			} else {
				// Filter to find chatbox is exist or not 
				let filter = chatbox.filter((box) => box.receiverId.toString() === arrival.user.userId.toString())
				if (filter.length > 0) {
					if (chatbox.length > 0 && chatbox[0].receiverId !== filter[0].receiverId) {
						// fitler to cut existing chat box and bring new one as first
						const totalChat = chatbox.filter((box) => box.receiverId.toString() !== arrival.user.userId.toString())
						filter[0].text = arrival.message.text
						filter[0].total = filter[0].total + 1
						setChatbox([...filter, ...totalChat])
						setOldChatbox([...filter, ...totalChat])
					} else {
						let copyChatBox = [...chatbox]
						copyChatBox[0].total = copyChatBox[0].total + 1
						copyChatBox[0].text = arrival.message.text
						setChatbox(copyChatBox)
						setOldChatbox(copyChatBox)
					}
				} else {
					const newBox = {
						order: new Date().getTime(),
						total: 1,
						receiverId: arrival.message.userId,
						userId: arrival.message.receiverId,
						text: arrival.message.text,
						total: 1,
						type: arrival.user.type,
						['registers.id']: arrival.user.userId,
						['registers.fname']: arrival.user['registers.fname'],
						['registers.lname']: arrival.user['registers.lname'],
						['registers.image']: arrival.user['registers.image'],
					}
					setChatbox([newBox, ...chatbox])
					setOldChatbox([newBox, ...chatbox])
				}
			}
		}
	}, [arrival])

	useEffect(() => {
		if (msgRef.current) {
			msgRef.current.scrollTop = msgRef.current.scrollHeight
		}
	})

	const [hasMore, setHasMore] = useState({ box: true, msg: true })
	const [page, setPage] = useState({ box: 0, msg: 0 })

	const showMessage = (id, boxId, type) => {
		setText("")
		setImage({})
		setPage({ ...page, msg: 1 })
		setHasMore({ ...hasMore, msg: true })
		axios.get(`${url}/social/message?type=message&id=${id}&current=${type}&page=0`).then((data) => {
			if (data.data.success) {
				let copyBox = [...chatbox]
				copyBox[boxId].total = 0;
				setChatbox(copyBox)
				const rcb = {
					fname: chatbox[boxId]["registers.fname"],
					lname: chatbox[boxId]["registers.lname"],
					receiverId: chatbox[boxId]["registers.id"],
					image: chatbox[boxId]["registers.image"],
					type: chatbox[boxId]["type"],
				}
				setMsg(data.data.message.rows)
				setReceiver(rcb)
				setClick(true)
			}
		})
	}

	const textMessages = (e) => {
		setText(e.target.value)
	}

	const sendMessage = () => {
		if (loginId && (text || image) && receiver.receiverId) {
			setIsEmoj(false)
			const formData = new FormData()
			formData.append("receiverId", receiver.receiverId)
			formData.append("text", text)
			if (image.url) formData.append("image", image.url)
			formData.append("type", "global")
			axios.post(`${url}/social/message`, formData).then(({ data }) => {
				if (data.success && data.message) {
					user.socket.emit("sendMessage", data)
					setMsg([...msg, data.message])
				}
			})
		}
		setText("")
		setImage({})
	}

	const imageHandler = (e,) => {
		const formData = new FormData()
		formData.append("image", e.target.files[0])
		axios.post(url + "/uploads", formData).then(({ data }) => {
			if (data.success) {
				setImage({ url: url + '/uploads/' + data.filename, filename: data.filename })

			}
		})
		e.target.value = ''
	}


	const deleteProImg = () => {
		if (image.filename) {
			axios.post(url + "/media/delete/api", {
				mode: 'delete',
				image: image.filename,
				type: 'sinlge'
			})
			setImage({})
		}
	}


	const onButtonClick = (e) => {
		inputFile.current.click()
	}


	const findItem = (type) => {
		setHasMore({ ...hasMore, [type]: false })
		setPage({ ...page, [type]: page[type] + 1 })
		if (type === "box") {
			axios.get(`${url}/social/message?type=box&page=${page[type]}`).then(({ data }) => {
				if (data.success && data.chatBox.rows.length > 0) {
					setChatbox([...chatbox, ...data.chatBox.rows])
					setOldChatbox([...chatbox, ...data.chatBox.rows])
					setHasMore({ ...hasMore, [type]: true })
				}
				if (data.chatBox.rows.length === 0 && data.success) {
					setHasMore({ ...hasMore, [type]: false })
				}
			})
		}

		if (type === "msg") {
			axios.get(`${url}/social/message?type=message&id=${receiver.receiverId}&current=customer&page=${page[type]}`).then(({ data }) => {
				if (data.success && data.message.rows.length > 0) {
					setMsg([...data.message.rows, ...msg])
					setHasMore({ ...hasMore, [type]: true })
				}
				if (data.message.rows.length === 0 && data.success) {
					setHasMore({ ...hasMore, [type]: false })
				}
			})
		}
	}

	const backChat = () => {
		setClick(false)
	}

	const [isEmoj, setIsEmoj] = useState(false)
	const emojClick = (event, pick) => {
		setText(text + pick.emoji)
	}
	const emojHandler = () => {
		setIsEmoj(!isEmoj)
	}

	useEffect(() => {
		const emojClickOutside = (e) => {
			if (emojRef && emojRef.current && !emojRef.current.contains(e.target)) {
				setIsEmoj(false)
			}
		};
		window.addEventListener("click", emojClickOutside);
		return () => window.removeEventListener("click", emojClickOutside);
	}, [])

	return (
		<>
			{load && chatbox.length === 0 && (
				<div className="row">
					<div className="titel col">
						<div className="empty_cart_wrapper">
							<h2>You don't have any messages</h2>
							<div className="empty_cart">
								<img src={url + "/uploads/default/message.png"} alt="" />
							</div>
							<div className="buy">
								<NavLink to="/social/find-friend" className="shopping_btn">
									Find Friend
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			)}

			{/* Chat box section */}

			{load && chatbox.length > 0 && (
				<div className="row chat_row">
					<div className="col-sm-12 col-md-5 col-lg-4 chat_box_main global_scroll" style={screen() < 921 && click ? { display: "none" } : {}}>
						{load && chatbox.length > 0 && <h2 className="font24 half_black chat_header">Messages</h2>}

						<div className="chat_search">
							<img src={url + "/uploads/default/map_search.png"} alt="" />
							<input type="text" onChange={changeDelay} name="search_text" value={search} placeholder="Search people or message" />
						</div>
						{isSearch && <p className="text-center green font24 m_top m_bottom">Loading...</p>}

						<InfiniteScroll
							pageStart={0}
							loadMore={() => findItem("box")}
							hasMore={hasMore.box && chatbox.length > 21 ? true : false}
							// load={<div className="loader" key={0}>Loading ...</div>}
							initialLoad={false}
							useWindow={screen() < 768 ? true : false}
							className="chat_all_box"
						>
							{!isSearch &&
								chatbox.map((item, id) => {
									return (

										<div className="chat_history" onClick={() => showMessage(item["registers.id"], id, item.type)} id={"chatbox-" + item.id}>
											<div className="profile">
												<div className="profile_img">
													<img src={item["registers.image"] ? imgPath + item["registers.image"] + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} className="profile" alt="Avatar" />
												</div>

												<div className="profile_username">
													<div className="username">
														<p className="font16 black_half">{item["registers.fname"]}</p>
														<p className="date">{getChatListDate(item.order)}</p>
													</div>

													<p className="last_chat">{substring(item.text, 38)}
														{(item.total !== 0) && <span className="badge">{item.total}</span>}
													</p>
												</div>
											</div>
										</div>
									)
								})}
						</InfiniteScroll>
					</div>

					{/* message section */}
					{click && (
						<div className="col-lg-8 col-md-7 col-sm-12 message_list message_list_main " style={screen() < 768 && !click ? { display: "none" } : {}}>
							<div className="profile_wrapper">
								<div className="profile">
									<Link to={"/social/" + receiver.receiverId}>
										<div className="profile_img">
											<img src={receiver.image ? imgPath + receiver.image + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} className="profile" alt="Avatar" />
										</div>
									</Link>
									<div className="profile_username">
										<Link to={"/social/" + receiver.receiverId}>
											<div className="username">
												<p className="font16 black_half">{receiver.fname}</p>
												<p className="username">@{receiver.fname}</p>
											</div>
										</Link>
									</div>
									{screen() < 921 && click && (
										<div onClick={backChat} className="chat_back">
											<i className="fa fa-arrow-left"></i>
											<span>Back</span>
										</div>
									)}
								</div>
							</div>

							<div className="chat_main message_list global_scroll" ref={msgRef}>
								{screen() < 768 && click && hasMore.msg && msg.length > 0 && (
									<div className="chat_back">
										<p onClick={() => findItem("msg")}>See older</p>
									</div>
								)}

								<InfiniteScroll
									pageStart={0}
									loadMore={() => findItem("msg")}
									hasMore={hasMore.msg}
									// load={<div className="loader" key={0}>Loading ...</div>}
									initialLoad={false}
									useWindow={false}
									isReverse={true}
								>
									{msg.map((item) => {
										if (parseInt(receiver.receiverId) === parseInt(item.receiverId)) {
											return (
												<div className="message_box sender" id={"message-" + item.id}>
													<div className="message">
														{item.text && <p>{dynamicHTML(item.text)}</p>}
														{item.image && (
															<p className="receiver_img">
																<img className="chat_img" src={item.image} alt="" />
															</p>
														)}
														<span className="time">{getMsgDate(item.createdAt)}</span>
													</div>
												</div>
											)
										} else {
											return (
												<div className="message_box receiver" id={"message-" + item.id}>
													<div className="img">
														<img src={receiver.image ? imgPath + receiver.image + '-' + 50 + '.webp' : process.env.REACT_APP_USER_IMG} alt="Avatar" className="right user_pro_img" />
													</div>

													<div className="message">
														{item.text && <p>{dynamicHTML(item.text)}</p>}

														{item.image && (
															<p className="receiver_img">
																<img className="chat_img" src={item.image} alt="" />
															</p>
														)}
														<span className="time">{getMsgDate(item.createdAt)}</span>
													</div>
												</div>
											)
										}
									})}
								</InfiniteScroll>
							</div>

							{receiver.type === "customer" && (
								<div className="send_box">
									<div className="send_message_tools">
										<div className="image">
											<img onClick={onButtonClick} src={url + "/uploads/default/photo_icon.png"} alt="" />

											<input onChange={imageHandler} type="file" id="file" ref={inputFile} style={{ display: "none" }} accept="image/*" />
										</div>
										<div className="text">
											<textarea onChange={textMessages} name="text" id="" className="w-100" value={text} placeholder="Start a new message" cols={0}></textarea>
										</div>

										<div className="emoj_main" ref={emojRef}>
											<div className="emoj_img">
												<img onClick={emojHandler} src={url + "/uploads/default/emoj.png"} alt="" />
											</div>
											{Emoj && isEmoj && <Emoj onEmojiClick={emojClick} />}
										</div>

										<div className="send_icon">
											<img onClick={sendMessage} src={url + "/uploads/default/send.png"} alt="" />
										</div>
									</div>
									{image.url &&
										<div className="view_image">
											<div className="view_img_tools">
												<i onClick={deleteProImg} className="fa fa-close"></i>
												<img className="postImage" src={image.url ? image.url : false} alt="" />
											</div>
										</div>
									}
								</div>
							)}
						</div>
					)}
					{!click && load && chatbox.length > 0 && (
						<div className="col-sm-12 col-md-7 col-lg-8 chat_start_main">
							<img src={url + "/uploads/default/message.png"} alt="" />
							<h2 className="chat_to_start">You don’t have a message selected.</h2>
							<p>Choose one from your existing messages, or start a new one.</p>
						</div>
					)}
				</div>
			)}
		</>
	)
}
export default Chat
