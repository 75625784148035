import React, { useState, useContext } from "react"
import { NavLink, Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"
import { url } from "../../helpers/url"
import { math } from "../../helpers/functions"
import { Context } from "../../helpers/context"
import axios from "axios"
import Loader from "../../helpers/load"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import { Helmet } from "react-helmet"
import "./cart.css"

const Shop = () => {
	const [cart, setCart] = useContext(Context)
	const cartItem = Object.keys(cart.cart)

	const quantityHandler = (id, type) => {
		let mainCart = cart.cart
		const stock = mainCart[id].stock
		const min = mainCart[id].min
		if (type === "plus" && stock >= (mainCart[id].total + min)) {
			console.log("plus")
			console.log(mainCart[id].total + 1)
			mainCart[id] = { ...mainCart[id], total: mainCart[id].total + 1 }
			setCart({ ...cart, ...mainCart })
			localStorage.setItem("cart", JSON.stringify(cart.cart))
		}
		if (mainCart[id].total > min && type === "minus") {
			mainCart[id] = { ...mainCart[id], total: mainCart[id].total - 1 }
			setCart({ ...cart, ...mainCart })
			localStorage.setItem("cart", JSON.stringify(cart.cart))
		}
	}

	const deleteCart = (item) => {
		delete cart.cart[item]
		setCart({ ...cart })
		localStorage.setItem("cart", JSON.stringify(cart.cart))
	}

	let total = 0

	const [load, setLoad] = useState(false)
	const [error, setError] = useState(false)
	const onCheckout = () => {
		setLoad(true)
		axios.post(`${url}/checkout/api`, { cart: cart.cart }).then((data) => {
			const result = data.data
			if (result.success && typeof window !== "undefined") {
				window.location.href = result.url
			}
			else if (!result.success && result.blockStripe) {
				const outOfStock = result.outOfStock
				const newCart = cart.cart
				const keys = Object.keys(outOfStock)
				keys.map((item) => {
					if (newCart[item] && !outOfStock[item].database) {
						newCart[item].stock = outOfStock[item].stock
						newCart[item].min = outOfStock[item].min
					}
					if (newCart[item] && outOfStock[item].database === 'delete') {
						newCart[item].database = 'delete'
					}
				})
				setCart({ ...cart, cart: newCart })
				localStorage.setItem('cart', JSON.stringify(newCart))
				setLoad(false)
			}
			else if (!result.success && typeof window !== "undefined") {
				setLoad(false)
				setError(true)
			}
		})
	}
	let totalQuantity = 0



	return (
		<>

			<Helmet>
				<meta charSet="utf-8" />
				<title>{"Cart"}</title>
				<link rel="canonical" href={"/cart"} />
				<meta name="description" content={"Dispensary cart"} />
			</Helmet>


			<Breadcumbs title={"Cart"} link={"/cart"} linktext={"Cart"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />

			<Container className="cart_container">
				{cartItem.length > 0 && cart.load && (
					<>
						{load && (
							<>
								<Row>
									<Col>
										<Loader />
									</Col>
								</Row>
							</>
						)}
						{!load && (
							<>
								<div className={" row cart"}>
									<div className="col-lg-8 cart_left_main">
										<div className="cart_left">
											{error && <div className="alert alert-danger">Something went wrong.</div>}

											{cartItem.map((item) => {
												const price = math(cart.cart[item].price)
												const discount = math(cart.cart[item].discount)
												const quantity = parseInt(cart.cart[item].total)
												totalQuantity += parseInt(cart.cart[item].total)
												if (discount > 0) {
													total += discount * quantity
												} else {
													total += price * quantity
												}


												return (
													<div className="cart_item_main">
														<div className="cart_item_details_wrapper">

															<div className="cart_item_details">
																<div className="cart_img_main">
																	<img className="img-fluid" src={cart.cart[item].image} alt="" />
																</div>
																<div className="cart_details">
																	<Link to={cart.cart[item].url}>{cart.cart[item].title}</Link>
																	<span>
																		{cart.cart[item].total > cart.cart[item].stock ? 'Out of Stock' : 'In Stock ' + cart.cart[item].stock}
																	</span>


																	{cart.cart[item].stock === 0 &&
																		<div className="alert alert-danger">
																			To proceed the order please remove it from the cart.
																		</div>
																	}
																	{(cart.cart[item].total > cart.cart[item].stock && cart.cart[item].stock > 0) &&
																		<div className="alert alert-danger">
																			To proceed the order please decrease item of this product.
																		</div>
																	}
																	{cart.cart[item].total < cart.cart[item].min &&
																		<div className="alert alert-danger">
																			You need to buy at least {cart.cart[item].min} Products.
																		</div>
																	}
																	{cart.cart[item].database === 'delete' &&
																		<div className="alert alert-danger">
																			You can not buy this item right now because this item is not available.
																		</div>
																	}
																</div>
																<div className="cart_current_price">
																	{cart.cart[item].discount > 0 ?
																		<p className="font24 green"><del className="discount">${math(cart.cart[item].price).toFixed(2)}</del> ${math(cart.cart[item].discount).toFixed(2)}</p>
																		:
																		<p className="font24 green">${math(cart.cart[item].price).toFixed(2)}</p>
																	}
																</div>

																<div class="cart_number_main">
																	<div className="left" onClick={() => quantityHandler(item, "minus")} >
																		<img src={url + '/uploads/default/icon-minus.png'} alt="" />
																	</div>
																	<div className="middle">
																		<span>{cart.cart[item].total}</span>
																	</div>
																	<div className="right" onClick={() => quantityHandler(item, "plus")} >
																		<img src={url + '/uploads/default/icon-plus.png'} alt="" />
																	</div>
																</div>
																<div className="single_total">
																	<p>${cart.cart[item].discount > 0 ? math(cart.cart[item].total * cart.cart[item].discount) : math(cart.cart[item].total * cart.cart[item].price)}</p>
																</div>
																<div className="single_delete">
																	<img onClick={() => deleteCart(item)} src={url + '/uploads/default/icon-delete.png'} alt="" />
																</div>
															</div>
														</div>
													</div>
												)
											})}
										</div>
										<div className="cart_header">
											<div className="left">
												<p>Products</p>
											</div>
											<div className="right">
												<p>{totalQuantity} Items</p>
											</div>
										</div>

									</div>
									<div className="col-lg-4 cart_summary_main">
										<div className="cart_summary">
											<h3 className="font24 half_black">Order Summary</h3>
											<div className="line"></div>
											<div className="subtotal_amount">
												<p className="subtotal_grey">Subtotal ({totalQuantity > 1 ? totalQuantity + ' items' : totalQuantity + ' item'})</p>
												<p className="sub_price green">${math(total).toFixed(2)}</p>
											</div>
											<div className="subtotal_input">
												<input type="text" placeholder="Enter Voucher Code" />
												<button className="subtotal_button coupon">Apply</button>
											</div>
											<div className="subtotal_amount total">
												<p className="subtotal_grey">Total</p>
												<p className="sub_price green">${math(total).toFixed(2)}</p>
											</div>

											<div className="checkout_btn">
												<button onClick={onCheckout} className="subtotal_button">
													Proceed to Checkout
												</button>
											</div>
										</div>
									</div>
								</div>
							</>
						)}
					</>
				)}

				{cartItem.length === 0 && cart.load && (
					<>
						<div className="empty_cart_wrapper">
							<div className="empty_cart">

								<div className="line"></div>
								<img src={url + "/uploads/default/empty_cart.png"} alt="" />
							</div>
							<div className="buy">
								<Link to="/merchandise" className="shopping_btn">
									Continue Shopping
								</Link>
							</div>
						</div>
					</>
				)}
			</Container>
		</>)
}
export default Shop
