import React, { useState, useEffect } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import Friend from "../../components/social/friend/friend"

const FindFriend = (props) => {
	const [friend, setFriend] = useState([])
	const [count, setCount] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		axios.get(`${url}/social/users?page=0`).then((data) => {
			if (data.data.success) {
				setFriend(data.data.rows)
				setCount(data.data.count)
				setLoad(true)
			}
		})
	}, [])

	return (
		<>
			<div className="container friend_container">
				<Friend more={"social/users?type=0"} type="find" data={friend} count={count} load={load} title="Find Friend" message="There is no friend  try later" />
			</div>
		</>
	)
}
export default FindFriend
