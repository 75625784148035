import React, { useState, useContext, useRef, useEffect } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import { Context } from "../../helpers/context"
import "./login.css"
const Login = (props) => {
	const ref = useRef()
	const [user, setUser] = useState({ status: "", login: true, type: "" })
	const [type, setType] = useContext(Context)
	const [width, setWidth] = useState(0)
	useEffect(() => {
		if (382 > ref.current.offsetWidth) {
			setWidth(ref.current.offsetWidth - 45)
		} else if (676 > ref.current.offsetWidth) {
			setWidth(ref.current.offsetWidth - 60)
		}
	}, [])

	if (typeof window !== "undefined") {
		const currentPage = localStorage.getItem("page") ? localStorage.getItem("page") : "/"
		if (type.type) {
			window.location.href = currentPage
		}

		if (typeof window !== "undefined" && user.login && user.status === "approved" && (user.type === "admin" || user.type === "business")) {
			window.location.href = "/admin/analytics/overview"
		}
		else if (typeof window !== "undefined" && user.login && user.status === "approved" && user.type === "editor") {
			window.location.href = '/admin/news'
		}
		else if (typeof window !== "undefined" && user.login && user.status === "approved" && user.type === "customer") {
			window.location.href = currentPage
		}
		else if (typeof window !== "undefined" && user.login && user.status === "approved" && user.type === "influencer") {
			window.location.href = "/influencer"
		}
	}


	const [email, setEmail] = useState('')

	const onTypeEmail = (e) => {
		setEmail(e.target.value.toLowerCase())
	}

	const [pass, setPass] = useState('')
	const onTypePass = (e) => {
		setPass(e.target.value)
	}


	const formSubmit = (e) => {
		e.preventDefault()

		axios
			.post(`${url}/api/login`, {
				email: email,
				password: pass,
			})
			.then((data) => {
				const result = data.data
				if (result.token && result.status !== "pending") {
					setUser({
						...user,
						login: result.login,
						status: result.status,
						type: result.type,
					})
					localStorage.setItem("letsmango.com", result.token)
				} else if ((result.status === "pending" || result.status === "disabled" || result.status === "email")) {
					setUser({
						...user,
						login: result.login,
						status: result.status,
						type: result.type,
					})
				}
				else {
					setUser({ ...user, status: "failed" })
				}
			})
	}

	const [passeye, setPasseye] = useState(false)

	return (
		<>
			{!type.type && (
				<section className="login-page section-b-space">
					<div className="container">
						<div
							className="row repeat login_main"
							style={{
								backgroundImage: `url('${url}/uploads/default/login.png')`,
							}}
							ref={ref}
						>
							<div className="col">
								{/* {props.location.search === "?success=true" && <div className="alert alert-success">Your account has been created successfully</div>} */}

								<div className="theme-card">
									<form
										className="theme-form"
										onSubmit={formSubmit}
										style={
											width
												? {
													width: `${width}px`,
												}
												: {}
										}
									>
										<h3 className="my-3 title_color">Login</h3>

										{user.status == "email" && <div className="alert alert-danger">You need to confirm the email to login (Check your inbox a confirmation link had been sent)</div>}
										{user.status == "failed" && <div className="alert alert-danger">Email or password didn't match</div>}
										{user.status === "pending" && <div className="alert alert-danger">Your account status is pending. The admin will approve shortly.</div>}
										{user.status === "disabled" && <div className="alert alert-danger">Your account is disabled.</div>}

										<div className="input_group">
											<label htmlFor="email">Email</label>
											<input name="email" id="email" type="email" className="form-control" placeholder="Enter your Email" onChange={onTypeEmail} required value={email} />
										</div>

										<div className="input_group">
											<label htmlFor="password">Password</label>
											<div className="eye_pass_input">
												<i className={passeye ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye(!passeye)}></i>
												<input id="password" type={passeye ? "text" : "password"} name="password" className="form-control" placeholder="Enter your password" required onChange={onTypePass} value={pass} />
											</div>
										</div>

										<p className="forget_pass">
											<a href="/forgot">Forgot Password</a>
										</p>

										<button className="btn input_button" type="submit">
											Login
										</button>
										<p className="login_alternative">
											Don’t have an account? <a href="/register">Sign Up</a>
										</p>
									</form>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</>
	)
}
export default Login
