import React, { useState } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import ContactList from "../../components/contact/contact"
import "./contact.css"

const Contact = () => {
	const [value, setValue] = useState({
		name: "",
		phone: "",
		message: "",
		email: "",
		subject: "",
		success: false,
	})
	const onType = (id, e) => {
		let updateState = { ...value, [id]: e.target.value }
		setValue(updateState)
	}

	const formValue = (e) => {
		e.preventDefault()
		axios.post(`${url}/contact/api`, value).then((data) => {
			const result = data.data
			if (result.success) {
				setValue({ ...value, success: result.success })
			}
		})
	}

	return (
		<>
			<section className="contact contact_section">
				<Breadcumbs title={"Contact"} link={"/contact"} linktext={"Contact"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
				<div className="container">
					<div className="title">
						<h3 className="mb-2 contact_title">Have any Questions?</h3>
						<p className="contact_description">Elementum eu facilisis sed odio morbi quis commodo odio. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque</p>
					</div>
					<div className="row boxshadow">
						<div className="col-lg-6 col-md-6 first">
							<div className="theme-card">
								<form className="contact_form" method="post" onSubmit={formValue}>
									{value.success && <div className="alert alert-success"><p>We have received your message. We will get back to you soon.</p></div>}

									{!value.success && (
										<>
											<div className="">
												<div className="input_group">
													<label htmlFor="address">Your Name</label>
													<input required name="name" onChange={(e) => onType("name", e)} type="text" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="phone">Subject</label>
													<input required name="subject" onChange={(e) => onType("subject", e)} type="text" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="phone">Your Email</label>
													<input required name="email" onChange={(e) => onType("email", e)} type="email" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="phone">Phone</label>
													<input required name="phone" onChange={(e) => onType("phone", e)} type="text" className="form-control mb-3" />
												</div>

												<div className="input_group">
													<label htmlFor="phone">Description</label>
													<textarea className="form-control mb-3" onChange={(e) => onType("message", e)} name="message" rows="5"></textarea>
												</div>
											</div>
											<div className="div">
												<button className="contact_btn" type="submit">
													Send a Message
												</button>
											</div>
										</>
									)}
								</form>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 google_map">
							<div className="google_map_main">
								<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2918.606401768087!2d-78.76532628875115!3d42.98656417102121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d373c12ce20f37%3A0xb5c8aeb453c423b7!2sN%20Forest%20Rd%2C%20Amherst%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1697726922822!5m2!1sen!2sbd" allowfullscreen={true} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
							</div>
							<div className="google_map_bottom">
								<ContactList />
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Contact
