import { useState, useEffect, useContext } from "react"
import './subscription.css'
import axios from "axios";
import { url } from "../../helpers/url";
import { Context } from '../../helpers/context'
import { Link } from "react-router-dom";
const Popup = () => {
  return (
    <div className="row">
      <div className="subscription_popup_box">
        <div className="alert alert-danger">
          To subscribe you have to  sign in as a parent <Link className="subscription_non_register" to="/business-register">business owner.</Link>
        </div>
      </div>
    </div>
  )
}
const Subscription = () => {
  const [user, setUser] = useContext(Context)
  const [subscriptionList, setSubscriptionList] = useState([])
  useEffect(() => {
    axios.get(url + '/subscription/api')
      .then(({ data }) => {
        if (data.success && data.plans) {
          setSubscriptionList(data.plans.data)
        }
      })
  }, [])


  const [planId, setPlanId] = useState(false)
  const postSubscription = (id) => {
    console.log("user")
    console.log(user)
    if (user.type === 'business' && user.businessType === 'parent') {
      axios.post(url + '/subscription/api', { id: id })
        .then(({ data }) => {
          if (data.session && data.session.url) {
            window.location.href = data.session.url
          }
        })
    } else {
      setPlanId(id)
    }

  }


  return (
    <>
      <div className="container">
        <div className="pricing_top_header mt-4">
          <h1 className="half_black text-center">Subscription</h1>
          <p className="half_black text-center mt-2">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi nesciunt praesentium placeat blanditiis omnis magni maxime ipsum, iure porro distinctio sapiente corrupti harum optio tempora facere ad nam odio aperiam!</p>
        </div>
      </div>
      {subscriptionList.length > 0 &&
        <div className="pricing_2">
          <div class="container">
            <div class="row">
              <div class="col-md-4 col-sm-6">
                <div class="pricingTable">
                  <div class="pricingTable-header">
                    <h3 class="title">Standard</h3>
                  </div>
                  <div class="price-value">
                    <span class="amount">$20</span>
                    <span class="duration">per month</span>
                  </div>
                  <ul class="pricing-content">
                    <li><i className="fa fa-check"></i>50GB Disk Space</li>
                    <li><i className="fa fa-check"></i>50 Email Accounts</li>
                    <li><i className="fa fa-close"></i>50GB Bandwidth</li>
                    <li><i className="fa fa-close"></i>Maintenance</li>
                    <li><i className="fa fa-close"></i>15 Subdomains</li>
                  </ul>

                  <div class="pricingTable-signup">
                    {subscriptionList[2].id === planId && <Popup />}
                    <a className="submit" onClick={() => postSubscription(subscriptionList[2].id)}>Free Trial</a>

                  </div>
                </div>
              </div>

              <div class="col-md-4 col-sm-6">
                <div class="pricingTable blue">
                  <div class="pricingTable-header">
                    <h3 class="title">Business</h3>
                  </div>
                  <div class="price-value">
                    <span class="amount">$20</span>
                    <span class="duration">per month</span>
                  </div>
                  <ul class="pricing-content">
                    <li><i className="fa fa-check"></i>50GB Disk Space</li>
                    <li><i className="fa fa-check"></i>50 Email Accounts</li>
                    <li><i className="fa fa-close"></i>50GB Bandwidth</li>
                    <li><i className="fa fa-close"></i>Maintenance</li>
                    <li><i className="fa fa-close"></i>15 Subdomains</li>
                  </ul>
                  <div class="pricingTable-signup">
                    {subscriptionList[1].id === planId && <Popup />}
                    <a className="submit" onClick={() => postSubscription(subscriptionList[1].id)}>Sign Up</a>
                  </div>
                </div>
              </div>


              <div class="col-md-4 col-sm-6">
                <div class="pricingTable green">
                  <div class="pricingTable-header">
                    <h3 class="title">Business</h3>
                  </div>
                  <div class="price-value">
                    <span class="amount">$100</span>
                    <span class="duration">per year</span>
                  </div>
                  <ul class="pricing-content">
                    <li><i className="fa fa-check"></i>50GB Disk Space</li>
                    <li><i className="fa fa-check"></i>50 Email Accounts</li>
                    <li><i className="fa fa-close"></i>50GB Bandwidth</li>
                    <li><i className="fa fa-close"></i>Maintenance</li>
                    <li><i className="fa fa-close"></i>15 Subdomains</li>
                  </ul>

                  <div class="pricingTable-signup">
                    {subscriptionList[0].id === planId && <Popup />}
                    <a className="submit" onClick={() => postSubscription(subscriptionList[0].id)}>Sign Up</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}
export default Subscription