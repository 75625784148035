import React from "react"
import WriterComponent from "../components/writer/writer"
const WriterContainer = (props) => {
  return (
    <>
      <WriterComponent {...props} />
    </>
  )
}

export default WriterContainer