import React, { useEffect, useState, useContext } from "react"
import ReactHtmlParser from "react-html-parser"
import { Container } from "react-bootstrap"
import { url } from "../../helpers/url"
import { math, dynamicTitle, substring } from "../../helpers/functions"
import { Context } from "../../helpers/context"
import axios from "axios"
import ReactStars from "react-rating-stars-component"
import SplideThumb from "../carousel/splide-thubnail"
import Share from "../social-share/social-share"
import { Toastify, Toast } from "../toastify/toastify"
import { Link } from "react-router-dom"
import "./single-product.css"
import { socket } from "../../helpers/socket"

const SingleProduct = ({ data, starClick }) => {
	const [user, setUser] = useContext(Context)
	const [product, setProduct] = useState({})
	const [image, setImage] = useState([])
	const [variationList, setVariationList] = useState()

	const [stock, setStock] = useState(0)
	const [min, setMin] = useState(1)
	const [curVariaiton, setCurVariaiton] = useState({})

	const strain = product.type === 'business' ? '/strain/' : '/merchandise/'

	useEffect(() => {
		if (data) {
			setProduct(data)
			if (data.variation) {
				const item = JSON.parse(data.variation)
				const keys = Object.keys(item)
				const color = []
				const size = []
				const weight = []
				let firstVariation
				keys.map((key, i) => {
					if (i === 0) {
						firstVariation = item[key]
						setVariationId(key)
					}
					if (item[key].Color) {
						color.push(item[key].Color)
					}
					if (item[key].Size) {
						size.push(item[key].Size)

					}
					if (item[key].Weight) {
						weight.push(item[key].Weight)
					}
				})
				if (firstVariation.Size) {
					setUpsize(firstVariation.Size)
				}
				if (firstVariation.Color) {
					setUpcolor(firstVariation.Color)
				}
				if (firstVariation.Weight) {
					setUpWeight(firstVariation.Weight)
				}
				if (firstVariation) {
					setCurVariaiton(firstVariation)
					setStock(parseInt(firstVariation.stock))
					setMin(parseInt(firstVariation.min))
					setTotal(parseInt(firstVariation.min))
					setVarCart(true)
				}
				setColor([...new Set(color)])
				setWeight([...new Set(weight)])
				setSize([...new Set(size)])
				setVariationList(item)
			} else {
				if (data.image && JSON.parse(data.image).length > 0) {
					setImage(JSON.parse(data.image))
				} else {
					setImage([{ url: process.env.REACT_APP_PORUDCT_IMAGE }])
				}

				setTotal(data.min)
				setMin(data.min)
				setStock(data.stock)
			}
		}
	}, [data])

	const catUrl = product.category && product.category.trim().split(" ").join("-")
	const [total, setTotal] = useState(1)

	const calculate = (type) => {
		if (total > min && type === "minus") {
			setTotal(total - min)
		}
		if (type === "plus" && stock >= (total + min)) {
			setTotal(total + min)
		}
	}

	const addToCart = (item, cartMode) => {
		let updateCart
		if (localStorage.getItem('cart')) {
			updateCart = JSON.parse(localStorage.getItem('cart'))
		} else {
			updateCart = {}
		}
		if (cartMode === "add") {
			Toast("Product is added to cart")
			if (!variationList) {
				updateCart[product.id] = {
					id: product.id,
					title: product.title,
					price: product.price,
					discount: product.discount,
					total: total,
					image: JSON.parse(product.image)[clickId ? clickId : 0].url,
					stock: product.stock,
					min: product.min,
					url: product.url
				}
			}
			if (curVariaiton.price) {
				updateCart[product.id + "_" + variationId] = {
					id: product.id,
					title: dynamicTitle(product.title, curVariaiton),
					variationId: variationId,
					price: curVariaiton.price,
					discount: curVariaiton.discount,
					total: total,
					image: curVariaiton.image.url,
					color: upColor,
					size: upSize,
					weight: upWeight,
					stock: curVariaiton.stock,
					min: curVariaiton.min,
					url: product.url
				}
			}
		}
		if (cartMode === "delete") {
			delete user.cart[item]
			setTotal(1)
		}
		setUser({ ...user, ...{ cart: updateCart } })
		if (typeof window !== "undefined") {
			localStorage.setItem("cart", JSON.stringify(updateCart))
		}
	}



	const [color, setColor] = useState([])
	const [size, setSize] = useState([])
	const [weight, setWeight] = useState([])
	const [current, setCurrent] = useState([])
	const [upColor, setUpcolor] = useState()
	const [upSize, setUpsize] = useState()
	const [upWeight, setUpWeight] = useState()
	const [variationId, setVariationId] = useState('')
	const [varCart, setVarCart] = useState(false)
	const variationHandler = (name, obj) => {
		let curColor = upColor
		let curSize = upSize
		let curWeight = upWeight
		if (obj === 'Color') {
			curColor = name
			setUpcolor(name)
		}
		if (obj === 'Size') {
			curSize = name
			setUpsize(name)
		}
		if (obj === 'Weight') {
			curWeight = name
			setUpWeight(name)
		}
		const update = []
		const keys = Object.keys(variationList)
		const items = variationList
		let price = 0;
		let loop = false
		let isOkay = false
		keys.map(item => {
			let okay = false
			if (variationList[item].Color && curColor !== variationList[item].Color) {
				okay = false
			}
			else if (variationList[item].Size && curSize !== variationList[item].Size) {
				okay = false
			}
			else if (variationList[item].Weight && curWeight !== variationList[item].Weight) {
				okay = false
			} else {
				okay = true
			}
			if (!isOkay && okay) {
				isOkay = true
				setVarCart(okay)
			}
			if (!isOkay && !okay) {
				isOkay = false
				setVarCart(okay)
			}
			if (items[item][obj] === name) {
				if (!loop) {
					loop = true
					setVariationId(item)
					setCurVariaiton(variationList[item])
					setStock(parseInt(variationList[item].stock))
					setMin(parseInt(variationList[item].min))
					setTotal(parseInt(variationList[item].min))
				}
				if (items[item].price > price) {
					price = items[item].price
				}
				if (items[item].image) {
					setImage({ url: url + '/uploads/' + items[item].filename })
					setClickId(0)
				}
				if (items[item].Color) {
					update.push(items[item].Color)
				}
				if (items[item].Size) {
					update.push(items[item].Size)
				}
				if (items[item].Weight) {
					update.push(items[item].Weight)
				}

			}
		})
		const filter = [...new Set(update)]
		setCurrent(filter)
	}


	const [clickId, setClickId] = useState(0)
	const currentImg = (index) => {
		setClickId(index)
	}


	const addToCursor = () => {
		if (variationList) {
			if (color.length > 0 && !upColor) return 'not-allowed'
			else if (size.length > 0 && !upSize) return 'not-allowed'
			else if (weight.length > 0 && !upWeight) return 'not-allowed'
		} else {
			return 'pointer'
		}
	}

	const sendFavorite = (data) => {
		if (user.type) {

			if (product['product_wishes.productId']) {
				Toast("You have unwished")
			} else {
				Toast("You have wished")
			}
			setProduct({ ...product, ['product_wishes.productId']: !product['product_wishes.productId'] })

			axios.post(`${url}/wishlist/product/api`, {
				productId: data.id,
				businessId: data.businessId,
				url: data.url,
				mainUserId: data.userId,
				title: substring(data.title, 20)
			})
				.then(({ data }) => {
					if (data.notification) {
						socket().emit("addNotification", data.notification)
					}
				})
		} else {
			setUser({ ...user, loginPopup: true, pageYOffset: window.pageYOffset })
		}
	}

	const starController = {
		edit: false,
		half: false,
		count: 5,
		size: 22,
		isHalf: true,
		color: '#C4C4C4',
		activeColor: '#F68924',

	}

	const starClickHandler = () => {
		starClick(true)
	}

	return (
		<>

			<Toastify />
			{product.id && (
				<Container>

					<div className="single_product">
						<div className="col">
							<div className="product_left">
								<SplideThumb data={curVariaiton.image ? [curVariaiton.image] : image} current={currentImg} clickId={clickId} />
							</div>
						</div>
						<div className="col">
							<div className="product_right">

								<h1 className="text-left title">{product.title}</h1>
								<div className="product_price" onClick={starClickHandler} >
									{product.review > 0 && (
										<div className="root_star justify-content-start">
											<ReactStars key={product.review / product.total} {...starController} value={product.review / product.total} />
											<span className="star_span single_star">{product.review / product.total}</span>
											<span className="review_border"></span>
											<span className="star_span">{product.total} {product.total > 1 ? 'Reviews' : 'Review'}</span>

										</div>
									)}
									{(curVariaiton.price && curVariaiton.price && curVariaiton.discount > 0) && (
										<div className={product.review == 0 ? "price text-left" : "price"}>
											<p className="mt-2">${math(curVariaiton.discount).toFixed(2)} <del className="discount">${math(curVariaiton.price).toFixed(2)}</del></p>
										</div>
									)
									}
									{(curVariaiton.price && curVariaiton.price && !curVariaiton.discount) &&
										(
											<div className={product.review == 0 ? "price text-left" : "price"}>
												<p className="mt-2">${math(curVariaiton.price).toFixed(2)}</p>
											</div>
										)
									}

									{(!curVariaiton.price && product.price && product.discount > 0) &&
										(
											<div className={product.review == 0 ? "price text-left" : "price"}>
												<p className="mt-2">${math(product.discount).toFixed(2)} <del className="discount">${math(product.price).toFixed(2)}</del></p>
											</div>
										)
									}
									{(!curVariaiton.price && product.price && !product.discount) &&
										(
											<div className={product.review == 0 ? "price text-left" : "price"}>
												<p className="mt-2">${math(product.price).toFixed(2)}</p>
											</div>
										)
									}
								</div>

								<div className="social_share">
									<div className="heart">
										<div className="heart_img">
											<img onClick={() => sendFavorite(product)} src={`${url}/uploads/default/${product['product_wishes.productId'] ? 'heart-full.png' : 'heart-blank.png'}`} alt="" />
										</div>

									</div>
									<Share product={product} />
								</div>
								{product.description && <>
									<div className="description_item mt-2 text-left">{ReactHtmlParser(product.description)}</div>
								</>}

								<div className="product_extra_details">
									<div className="left">
										{product.category && <span>Category: </span>}
										{(product.type === 'business' && product.size) && <span>Size: </span>}

										{(product.type === 'business' && product.plant_type) && <span>Plant Type: </span>}
										{product.type === 'business' && <span>THC: </span>}
										{product.type === 'business' && <span>CBD: </span>}
										{(product.effects) && <span>Poitive Effects: </span>}
										{(product.negetive) && <span>Negetive Effects: </span>}
										{(product.common_use) && <span>Common Usage: </span>}
										{(product.flavor) && <span>Flavors: </span>}
										{(product.terpen) && <span>Terpenes: </span>}
										{(product.tags) && <span>Tags: </span>}

										{product.brand && <span>Brand: </span>}
										{(curVariaiton.sku || (!curVariaiton.sku && product.sku)) && <span>SKU: </span>}
										{product.strain && <span>Strain: </span>}

									</div>
									<div className="right">
										{(product.category && product.type === 'merchandise') && (
											<p className="text-left">
												<Link to={"/category/merchandise/" + catUrl} className="sub_color">
													{product.category}
												</Link>
											</p>
										)}
										{(product.category && product.type === 'business') && (
											<p className="text-left">
												<Link to={"/category/" + catUrl} className="sub_color">
													{product.category}
												</Link>
											</p>
										)}
										{(product.type === 'business' && product.size) && <p className="text-left">{product.size}</p>}


										{(product.plant_type && product.type === 'business') && <p className="text-left">{product.plant_type}</p>}
										{(product.type === 'business') && <p className="text-left">{product.thc + product.thc_type}</p>}
										{(product.type === 'business') && <p className="text-left">{product.cbd + product.cbd_type}</p>}
										{(product.effects) && <p className="text-left">{product.effects}</p>}
										{(product.negetive) && <p className="text-left">{product.negetive}</p>}
										{(product.common_use) && <p className="text-left">{product.common_use}</p>}
										{(product.flavor) && <p className="text-left">{product.flavor}</p>}
										{(product.terpen) && <p className="text-left">{product.terpen}</p>}
										{(product.tags) && <p className="text-left">{product.tags}</p>}

										{product.brand && <p className="text-left">{product.brand}</p>}
										{(curVariaiton.sku) && <p className="text-left">{curVariaiton.sku}</p>}
										{(!curVariaiton.sku && product.sku) && <p className="text-left">{product.sku}</p>}
										{(product.strain) && <p className="text-left">{product.strain}</p>}

										{product.type === 'merchandise' &&
											<p className="text-left stock">{stock > 0 ? 'In Stock ' + stock : 'Out of Stock'}</p>
										}
									</div>
								</div>
								{product.type === "merchandise" && (<>
									{color.length > 0 && <div className="variation">
										<div className="variation_name">
											<p>Color</p>
										</div>
										<div className="variation_value">
											<div className="variation_value color">
												{
													color.map((item, i) => {
														let customClass = 'active_border'
														if (current.length > 0 && !current.includes(item)) {
															customClass = 'active_border opacitiy'
														} else if (item === upColor) {
															customClass = 'active_border no_opacitiy'
														}
														return (
															<div className={customClass}>
																<div onClick={() => variationHandler(item, 'Color')} className="color_name" style={{
																	background: item
																}}></div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>}

									{
										size.length > 0 && <div className="variation">
											<div className="variation_name">
												<p>Size</p>
											</div>
											<div className="variation_value">
												{size.map(item => {
													let customClass = 'item'
													if (current.length > 0 && !current.includes(item)) {
														customClass = 'opacitiy'
													} else if (item === upSize) {
														customClass = 'no_opacitiy'
													}

													return (
														<p className={customClass} onClick={() => variationHandler(item, 'Size')}>{item}</p>
													)
												})
												}
											</div>
										</div>
									}
									{
										weight.length > 0 && <div className="variation">
											<div className="variation_name">
												<p>Weight</p>
											</div>
											<div className="variation_value">
												{weight.map(item => {
													let customClass = 'item'
													if (current.length > 0 && !current.includes(item)) {
														customClass = 'opacitiy'
													} else if (item === upWeight) {
														customClass = 'no_opacitiy'
													}

													return (
														<p className={customClass} onClick={() => variationHandler(item, 'Weight')}>{item}</p>
													)
												})
												}
											</div>
										</div>
									}


									<div class="cart_number main_cart_number">
										<div className="quantity">
											<span className="input_quantity">Quantity:</span>
										</div>

										<div className="input_quantity_calculate">
											<div
												className="minus"
												onClick={() => calculate("minus")}
												style={{
													cursor: "pointer",
												}}
											>
												<div className="one"></div>
											</div>

											<div className="cart_count"> <span>{total}</span></div>
											<div
												className="plus"
												onClick={() => calculate("plus")}
												style={{
													cursor: "pointer",
												}}
											>
												<div className="one"></div>
												<div className="two"></div>
											</div>
										</div>

									</div>
								</>)}

								<div className="single_pro_btn_wrapper">
									{(product.type === "merchandise" && !product.variation && stock > 0) && (
										<div className="first_pro_btn" onClick={() => addToCart(product.id, "add")} style={{
											cursor: addToCursor()
										}}>
											<img className="carticon" src={url + "/uploads/default/cart.png"} alt="" />
											<a className="" rel="noopener noreferrer ">
												Add to Cart
											</a>
										</div>
									)}

									{/* Variation button */}
									{(product.type === "merchandise" && product.variation && varCart && stock > 0) && (
										<div className="first_pro_btn" style={{
											cursor: addToCursor(),
										}} onClick={() => addToCart(product.id, "add")}>
											<img className="carticon" src={url + "/uploads/default/cart.png"} alt="" />
											<a className="" rel="noopener noreferrer ">
												Add to Cart
											</a>
										</div>
									)}
									{(product.type === "merchandise" && product.variation && !varCart && stock > 0) && (
										<div className="first_pro_btn" style={{
											cursor: "not-allowed",
										}}>
											<img className="carticon" src={url + "/uploads/default/cart.png"} alt="" />
											<a className="" rel="noopener noreferrer ">
												Add to Cart
											</a>
										</div>
									)}

									{(product.type === "merchandise" && !stock) && (
										<div className="first_pro_btn" style={{
											cursor: 'not-allowed'
										}}>
											<img className="carticon" src={url + "/uploads/default/cart.png"} alt="" />
											<a className="" rel="noopener noreferrer ">
												Out of Stock
											</a>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</Container>
			)}
		</>
	)
}

export default SingleProduct
