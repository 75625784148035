import "./single-news.css"
import { url } from "../../helpers/url"
import { useState, useEffect, useContext } from "react"
import axios from "axios"
import ReactHtmlParser from "react-html-parser"
import { username, screen } from "../../helpers/functions"
import { useParams } from 'react-router-dom'
import { newsUserImg } from "../../helpers/image_url"
import { dynamicView } from "../../helpers/dynamic_view"
import { newsTime } from "../../helpers/time"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import Share from "../../components/social-share/social-share"
import News from '../../components/news/news'
import { Context } from "../../helpers/context"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { dynamicHTML } from "../../helpers/dynamic_html"
const SingleNews = () => {
  const [single, setSingle] = useState([])
  const [user, setUser] = useContext(Context)
  const [relatedNews, setRelatedNews] = useState([])
  const [relatedNewsCount, setRelatedNewsCount] = useState([])

  const [newsComment, setNewsComment] = useState([])
  const [newsCommentCount, setNewsCommentCount] = useState([])

  const [load, setLoad] = useState(false)

  const { id } = useParams()
  useEffect(() => {
    axios.get(`${url}/news/api?mode=one&url=${id}`).then(({ data }) => {
      if (data.success) {
        setSingle(data.data)
        if (data.relatedNews) {
          setRelatedNews(data.relatedNews.rows)
          setNewsCommentCount(data.relatedNews.count)
        }
        setNewsComment(data.newsComment.rows)
        setNewsCommentCount(data.newsComment.count)
      }
      setLoad(true)
    })
    dynamicView('page')
  }, [id])


  const [commentBox, setCommentBox] = useState(false)
  const commentBoxHandler = () => {
    if (user.type) {
      setCommentBox(!commentBox)
    } else {
      setUser({ ...user, loginPopup: true, pageYOffset: window.pageYOffset })
    }
  }

  const [comment, setComment] = useState('')
  const commentSubmitHandler = () => {
    if (user.type && comment && comment.trim()) {
      axios.post(`${url}/news/comment/api`, { comment: comment.trim(), newsId: single.id, }).then(({ data }) => {
        if (data.success) {
          setSingle({ ...single, comment: single.comment + 1 })
          setNewsComment([...newsComment, { ...data.comment, ['registers.fname']: data.comment.fname, ['registers.lname']: data.comment.lname, ['registers.image']: data.comment.image }])
          setComment('')
          setCommentBox(false)
        }
      })
    }
  }

  const [next, setNext] = useState(true)
  const [commentId, setCommentId] = useState(1)
  function getPaginationId() {
    if (single.id) {
      const length = 10
      let page = commentId > 0 ? commentId * length : 0
      axios.get(`${url}/news/comment/api?page=${page}&id=${single.id}`).then(({ data }) => {
        if (data.success) {
          if ((length !== data.rows.length) || data.count.length === id) {
            setNext(false)
          }
          setNewsComment([...newsComment, ...data.rows])
          setCommentId(commentId + 1)
        }
      })
    }
  }

  return (

    <>
      <section className="single_news_section">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{single.title}</title>
          <link rel="canonical" href={single.url} />
          <meta name="description" content={single.seoDes} />
        </Helmet>
        <Breadcumbs title={"News Details"} link={"/"} linktext={"News"} subLink={single.url} subLinkText={single.title} />
        <div className="container">
          <div className="news_bottom row">
            {(load && single.title) &&
              <>
                <div className="col-lg-12">
                  <div className="feature_news">
                    <img className="news_feature_img" src={JSON.parse(single.banner).url} alt="" />
                  </div>

                  <div className="news_additional">
                    <div className="left">
                      <div className="img">
                        <img src={url + '/uploads/default/calendar.png'} alt="" />
                      </div>
                      <div className="span">
                        <span>{newsTime(single.createdAt)}</span>
                      </div>
                    </div>
                    <a href="#news_total_comment">
                      <div className="right">
                        <div className="img">
                          <img src={url + '/uploads/default/news-comment.png'} alt="" />
                        </div>
                        <div className="span">
                          <span>{single.comment} Comments</span>
                        </div>
                      </div>
                    </a>
                    <div className="social_share">
                      <Share product={single} />
                    </div>

                  </div>

                  <div className="single_news_content">
                    <div className="news_title">
                      <h1>{single.title}</h1>
                    </div>
                    <div className="description">
                      {ReactHtmlParser(single.description)}
                    </div>
                    {single.tags &&
                      <div className="merchandise_main">
                        <div class="serach_weight">
                          <span class="tags">Tags: </span>
                          {single.tags.split(',').map(item => (
                            <Link to={"/" + item.trim() + "/news"} className="badge rounded-pill badge-lg ">{item}</Link>
                          ))}
                        </div>
                      </div>
                    }
                    <div className="news_author">
                      <div className="author_img">
                        <img src={single["registers.image"] ? single["registers.image"] : newsUserImg} alt="" />
                      </div>
                      <div className="author_about">
                        <p className="name">{single['registers.username'] || username(single["registers.fname"], single["registers.lname"])}</p>
                        <p className="category">{single["registers.position"]}</p>
                      </div>
                    </div>

                    <div className="news_total_comment" id="news_total_comment">
                      <h3>{single.comment} Comments</h3>
                    </div>
                    <div className="news_comment_list">
                      {newsComment.map(item => (
                        <div className="comment_item">
                          <div className="left">
                            <div className="img">
                              <img src={item['registers.image']} alt="" />
                            </div>
                            {screen() < 551 &&
                              <div className="comment_top">
                                <div className="left">
                                  <p>{item['registers.username'] || username(item['registers.fname'], item['registers.lname'])}</p>
                                </div>
                                <div className="right">
                                  <p>{newsTime(item.createdAt)}</p>
                                </div>
                              </div>
                            }
                          </div>
                          <div className="right">
                            {screen() > 551 &&
                              <div className="comment_top">
                                <div className="left">
                                  <p>{item['registers.username'] || username(item['registers.fname'], item['registers.lname'])}</p>
                                </div>
                                <div className="right">
                                  <p>{newsTime(item.createdAt)}</p>
                                </div>
                              </div>
                            }
                            <div className="comment_bottom">
                              <p>{dynamicHTML(item.comment)}</p>
                            </div>
                          </div>
                        </div>
                      )
                      )}
                    </div>
                    {(next && newsComment.length > 9) &&
                      <div className="load_more">
                        <button onClick={getPaginationId} className="load_more_btn">Load more</button>
                      </div>
                    }
                    {commentBox &&
                      <div className="news_comment_box">
                        <textarea onChange={(e) => setComment(e.target.value)} class="w-50" name="comment" rows="3" spellcheck="false"></textarea>
                      </div>
                    }


                    <div className={"news_comment_btn " + commentBox}>
                      <button class="write_review" onClick={!commentBox ? commentBoxHandler : commentSubmitHandler}>
                        <img src={commentBox ? url + "/uploads/default/news-write-submit.png" : url + "/uploads/default/news-write.png"} alt="" />
                        {commentBox ? "Submit" : " Write a Comment"}
                      </button>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
          {relatedNews.length > 0 &&
            <div className="latest_news">
              <div className="top">
                <h2>Related News</h2>
              </div>
              <div className="botom">
                <News rows={relatedNews} count={relatedNewsCount} />
              </div>
            </div>
          }
        </div>
      </section>
    </>
  )
}
export default SingleNews
