import React, { useEffect, useRef, useState } from "react"
import Products from "../../components/products/products"
import { url } from "../../helpers/url"
import axios from "axios"
import Pagination from "../../components/pagination/pagination"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import { dynamicView } from "../../helpers/dynamic_view"
import SVG from "../../components/dynamic-svg/dynamic-svg"
import { screen } from "../../helpers/functions"

const Shop = (props) => {
	const [product, setProduct] = useState([])
	const [count, setCount] = useState([])
	const [load, setLoad] = useState(false)
	const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
	const [category, setCategory] = useState([])

	useEffect(() => {
		axios.get(`${url}/products/api?page=0&type=business&limit=20`).then((data) => {
			const result = data.data
			if (result.success) {
				setCordinates({ ...cordinates, ...result.cordinates })
				setProduct(result.rows)
				if (result.count.length > 0) {
					setCount(result.count)
				}
				setLoad(true)
			}
		})
		axios.get(`${url}/category/api?page=0`).then(({ data }) => {
			setCategory(data.rows)
		})
		dynamicView("page")
	}, [])

	const [next, setNext] = useState(true)
	function getPaginationId(id) {
		const length = 20
		const page = id > 0 ? (id - 1) * length : 0
		axios.get(`${url}/products/api?page=${page}&type=product`).then(({ data }) => {
			if ((length !== data.rows.length) || count.length === id) {
				setNext(false)
			} else {
				setNext(true)
			}
			setProduct(data.rows)
			setCount(data.count)
			setLoad(true)
		})
	}
	const sidebarRef = useRef(null)
	const [openSidebar, setOpenSidebar] = useState(false)
	useEffect(() => {
		const click = (e) => {
			if (sidebarRef && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
				setOpenSidebar(false)
			}
		};
		window.addEventListener("click", click);
		return () => window.removeEventListener("click", click);
	}, [])

	const sidebarHandler = (redirect = true) => {
		if (screen() <= 800) {
			setOpenSidebar(!openSidebar)
		}
		if (redirect) {
			window.location.href = "/strain#sorting"
		}
	}


	const [search, setSearch] = useState("")
	const searchHandler = (e) => {
		setSearch(e.target.value)
	}

	const filterBySearch = (e) => {
		e.preventDefault()
		if (search && search.trim()) {
			axios.post(url + "/search/product/api", { search: search.trim(), page: 0, limit: 20 }).then(({ data }) => {
				if (data.success) {
					setProduct(data.rows)
					setCount(data.count)
					sidebarHandler()
				}
			})
		}
	}

	const [filterCat, setFilterCat] = useState()
	const filterByCategory = (e) => {
		setFilterCat(e.target.value)
		axios.post(url + "/search/product/category/api", { category: e.target.value, page: 0, limit: 20 }).then(({ data }) => {
			if (data.success) {
				setProduct(data.rows)
				setCount(data.count)
				sidebarHandler()
			}
		})

	}

	const percentagesTHC = [
		{ name: "0% - 10%", value: "0,10" },
		{ name: "10% - 20%", value: "10,20" },
		{ name: "30% - 40%", value: "30,40" },
		{ name: "40% - 50%", value: "40,50" },
	]

	const percentagesCBD = [
		{ name: "0% - 10%", value: "0,10" },
		{ name: "10% - 20%", value: "10,20" },
		{ name: "30% - 40%", value: "30,40" },
		{ name: "40% - 50%", value: "40,50" },
	]

	const [thc, setThc] = useState()
	const filterByTHC = (e, type) => {
		setThc(e.target.value)
		axios.post(url + "/search/product/others/api", { name: type, value: e.target.value, page: 0, limit: 20, }).then(({ data }) => {
			if (data.success) {
				setProduct(data.rows)
				setCount(data.count)
				sidebarHandler()
			}
		})
	}
	const [cbd, setCbd] = useState()
	const filterByCBD = (e, type) => {
		setCbd(e.target.value)
		axios.post(url + "/search/product/others/api", { name: type, value: e.target.value, page: 0, limit: 20, }).then(({ data }) => {
			if (data.success) {
				setProduct(data.rows)
				setCount(data.count)
				sidebarHandler()
			}
		})
	}

	const plant_type = ['Indica-Dominant', 'Sativa-Dominant', 'Hybrid']

	const [plant, setPlant] = useState('')
	const filterByPlant = (e, type) => {
		setPlant(e.target.value)
		axios.post(url + "/search/product/plant/api", { value: e.target.value, page: 0, limit: 20, }).then(({ data }) => {
			if (data.success) {
				setProduct(data.rows)
				setCount(data.count)
				sidebarHandler()
			}
		})
	}

	const filterBySorting = (e) => {
		const sort = e.target.value
		axios.post(url + "/product/sort/api", { type: sort, page: 0, limit: 20, }).then(({ data }) => {
			if (data.success) {
				setProduct(data.product.rows)
				setCount(data.product.count)
			}
		})
	}

	const clearFilter = () => {
		axios.get(`${url}/products/api?page=0&type=business&limit=20`).then((data) => {
			const result = data.data
			if (result.success) {
				setSearch('')
				setFilterCat('')
				setCbd('')
				setThc('')
				setPlant('')
				setCordinates({ ...cordinates, ...result.cordinates })
				setProduct(result.rows)
				if (result.count.length > 0) {
					setCount(result.count)
				}
				sidebarHandler()
			}
		})
	}

	return (
		<>
			<section className="mercahndise_main_section">
				<Breadcumbs title={"Business Product"} link={"/product"} linktext={"Business Product"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
				{/* {single && <Model><SingleProduct data={single} /></Model>} */}
				<div className="container">
					<div className="merchandise_main">
						<div className="merchandise_left">
							<div className="col">
								<div
									className="merchandise_banner_items"
									style={{
										backgroundImage: `url("${url}/uploads/default/12.jpg")`,
									}}
								>
									<div className="merchandise_banner_text">
										<h2>Browse our selection of CBD-infused goods</h2>
										<p>Elementum eu facilisis sed odio morbi quis commodo odio. Mauris rhoncus aenean vel elit scelerisque mauris pellentesque</p>
										{/* <a href="/merchandise" className="global_btn">Shop Now</a> */}
									</div>
								</div>
								<div className="merchandise_sorting" id="sorting">
									<div className="left">
										<span>Sorting:</span>
										<select name="sorting" id="" onChange={filterBySorting}>
											<option value="">Default sorting</option>
											<option value="popularity">Popularity</option>
											<option value="reviews">Reviews</option>
											<option value="thc">THC</option>
											<option value="cbd">CBD</option>
										</select>
									</div>
									<div className="middle" ref={sidebarRef}>
										{screen() <= 800 && <>
											<span onClick={() => sidebarHandler(false)}>Filter</span> <span><i className="fa fa-angle-down"></i></span>
										</>
										}
										{(screen() <= 800 && openSidebar) &&
											<div className="merchandise_right">
												<div className="search_heading">
													<SVG />
													<span>Search by Products</span>
												</div>
												<div className="search_input">
													<form onSubmit={filterBySearch} class="merchandise_search_form">
														<input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
														<button className="" type="submit" class="subscribe_btn">
															Search
														</button>
													</form>
												</div>
												<div className="search_heading">
													<SVG />
													<span>Product Categories</span>
												</div>
												<div className="search_radio">
													{category.map((item) => {
														return (
															<div class="form-check">
																<input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} id={item.category} value={item.category} checked={item.category === filterCat} />
																<label class="form-check-label" for={item.category}>
																	{item.category}
																</label>
															</div>
														)
													})}
												</div>
												<div className="search_heading">
													<SVG />
													<span>Plant Type</span>
												</div>

												<div className="search_radio">
													{
														plant_type.map(item => {
															return (
																<div class="form-check">
																	<input onChange={(e) => filterByPlant(e, 'plant_type')} class="form-check-input" type="radio" name="plant_type" id={item} value={item} checked={item === plant} />
																	<label class="form-check-label" for={item}>{item}</label>
																</div>
															)
														})
													}
												</div>
												<div className="search_heading">
													<SVG />
													<span>THC</span>
												</div>

												<div className="search_radio">
													{percentagesTHC.map((item) => (
														<div class="form-check">
															<input onChange={(e) => filterByTHC(e, 'thc')} class="form-check-input" type="radio" name="thc" id={item.name} value={item.value} checked={thc === item.value} />
															<label class="form-check-label" for={item.name}>
																{item.name}
															</label>
														</div>
													))}
												</div>
												<div className="search_heading">
													<SVG />
													<span>CBD</span>
												</div>
												<div className="search_radio">
													<div className="search_radio">
														{percentagesCBD.map((item) => (
															<div class="form-check">
																<input onChange={(e) => filterByCBD(e, 'cbd')} class="form-check-input" type="radio" name="cbd" id={item.name + "cbd"} value={item.value} checked={cbd === item.value} />
																<label class="form-check-label" for={item.name + "cbd"}>
																	{item.name}
																</label>
															</div>
														))}
													</div>
												</div>
												<div className="search_radio clear_filter">
													<div className="search_radio" onClick={clearFilter}>
														<i className="fa fa-close"></i>
														<span>Clear Filter</span>
													</div>
												</div>
											</div>
										}
									</div>
									<div className="right">
										<p>Showing 1–{product.length} of {count.length > 0 ? count.length * 20 : product.length} results</p>
									</div>
								</div>
							</div>

							{product.length > 0 && load && <Products productType="business" data={product} viewItem="true" menu="false" cordinates={cordinates} rows={count} />}

							{product.length === 0 && load && <div className="alert alert-danger my-3">
								<p className="title_color">There is no products</p>
							</div>}
							{count.length > 0 && <Pagination isNext={next} product={product.length} count={count} clickId={getPaginationId} />}
						</div>

						{screen() > 800 &&
							<div className="merchandise_right">
								<div className="search_heading">
									<SVG />
									<span>Search by Products</span>
								</div>
								<div className="search_input">
									<form onSubmit={filterBySearch} class="merchandise_search_form">
										<input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" value={search} />
										<button className="" type="submit" class="subscribe_btn">
											Search
										</button>
									</form>
								</div>
								<div className="search_heading">
									<SVG />
									<span>Product Categories</span>
								</div>
								<div className="search_radio">
									{category.map((item) => {
										return (
											<div class="form-check">
												<input onChange={filterByCategory} class="form-check-input" type="radio" name={"category"} id={item.category} value={item.category} checked={item.category === filterCat} />
												<label class="form-check-label" for={item.category}>
													{item.category}
												</label>
											</div>
										)
									})}
								</div>
								<div className="search_heading">
									<SVG />
									<span>Plant Type</span>
								</div>

								<div className="search_radio">
									{
										plant_type.map(item => {
											return (
												<div class="form-check">
													<input onChange={(e) => filterByPlant(e, 'plant_type')} class="form-check-input" type="radio" name="plant_type" id={item} value={item} checked={item === plant} />
													<label class="form-check-label" for={item}>{item}</label>
												</div>
											)
										})
									}
								</div>
								<div className="search_heading">
									<SVG />
									<span>THC</span>
								</div>

								<div className="search_radio">
									{percentagesTHC.map((item) => (
										<div class="form-check">
											<input onChange={(e) => filterByTHC(e, 'thc')} class="form-check-input" type="radio" name="thc" id={item.name} value={item.value} checked={thc === item.value} />
											<label class="form-check-label" for={item.name}>
												{item.name}
											</label>
										</div>
									))}
								</div>
								<div className="search_heading">
									<SVG />
									<span>CBD</span>
								</div>
								<div className="search_radio">
									<div className="search_radio">
										{percentagesCBD.map((item) => (
											<div class="form-check">
												<input onChange={(e) => filterByCBD(e, 'cbd')} class="form-check-input" type="radio" name="cbd" id={item.name + "cbd"} value={item.value} checked={cbd === item.value} />
												<label class="form-check-label" for={item.name + "cbd"}>
													{item.name}
												</label>
											</div>
										))}
									</div>
								</div>
								<div className="search_radio clear_filter">
									<div className="search_radio" onClick={clearFilter}>
										<i className="fa fa-close"></i>
										<span>Clear Filter</span>
									</div>
								</div>
							</div>
						}
					</div>
				</div>
			</section>
		</>
	)
}
export default Shop
