import React, { useState, useEffect } from "react"
import axios from "axios"
import { Container } from "react-bootstrap"
import { url } from "../../helpers/url"
import { newsTime } from "../../helpers/time"
import Subscribe from "../../components/subscribe/subscribe"
import Products from "../../components/products/products"
import Business from "../../components/business/business"
import Reviews from "../../components/reviews/reviews"
import Categories from "../../components/categories/categories"
import Splide from "../../components/carousel/spslide"
import { dynamicView } from "../../helpers/dynamic_view"
import "./home.css"
import ReactStars from "react-rating-stars-component"
import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
const Home = () => {
	const [product, setProduct] = useState([])
	const [discover, setDiscover] = useState([])
	const [category, setCategory] = useState([])
	const [slides, setSlide] = useState([])
	const [reviews, setReviews] = useState([])
	const [business, setBusiness] = useState([])
	const [news, setNews] = useState([])
	const [load, setLoad] = useState(false)
	const [cordinates, setCordinates] = useState({ lat: "", lng: "" })


	// useEffect(() => {
	// 	axios.get('https://graph.instagram.com/mediaId?fields=id,media_type,media_url,username,timestamp&access_token=accessToken')
	// 		.then(data => {
	// 			console.log(data)
	// 		})
	// }, [])

	useEffect(() => {
		axios.get(`${url}/home/api?limit=10`).then(({ data }) => {
			if (data.success) {
				setSlide(data.slides)
				setCategory(data.categories)
				setProduct(data.products)
				setCordinates({ ...cordinates, ...data.cordinates })
				setBusiness(data.businesses)
				setReviews(data.reviews)
				setReviews(data.reviews)
				setNews(data.news)
				setLoad(true)
			}

			if (localStorage.getItem("products")) {
				const item = localStorage.getItem("products")
				axios.post(`${url}/discover/api`, { discover: JSON.parse(item) }).then(({ data }) => {
					if (data.success) setDiscover(data.products)
				})
			}
		})
		dynamicView("page")
	}, [])
	const starController = {
		edit: false,
		half: false,
		count: 5,
		size: 30,
		isHalf: true,
		color: "#C4C4C4",
		activeColor: "#EFC368",
	}


	const dynamicContent = (str, url) => {
		if (str) {
			var plainString = str.replace(/<[^>]+>/g, " ")
			plainString.replace(/\s{2,}/g, " ").trim()
			return <p>{plainString.substring(0, 50)} <Link to={"/news/" + url}>read more</Link></p>
		}
	}

	return (
		<>
			{load && (
				<>
					<Helmet>
						<meta charSet="utf-8" />
						<title>{"Home"}</title>
						<link rel="canonical" href={"https://letsmango.com"} />
						<meta name="description" content={"Let's Mango"} />
					</Helmet>

					<Splide slides={slides} />
					<Container>
						{business.length > 0 && load && cordinates.lat && cordinates.lng && <Business cordinates={cordinates} data={business} slider="true" title="Dispensaries Near Me" link="/business" />}

						<Categories data={category} />
						{product.length > 0 && load && <Products homepage={true} cordinates={cordinates} data={product} title="Featured Products" description="Our featured products are carefully handpicked to match all users taste and bring the best experience ever" link="/strain" productType={"business"} />}

						<div className="row discover py-5">
							<div className="col-lg-6">
								<h2>Our knowledgeable team can help you design experiences tailored to your needs by accessing one of the flower selections</h2>
								<p>We understand that browsing through our huge collection of cannabis strains can be an overwhelming task for users. That's why we found a great solution for you! We have a special department made up of knowledgeable and expert advisors who will guide you step by step on your way to getting the best flower for your needs. Still having doubts on the best cannabis for your needs, simply get in touch with us and we will provide instant solutions. Our team of experts are more than pleased to answer any of your questions regarding our products. Not satisfied with your product? Our expert team will help you through the process of getting a refund and guide you on how to get the best product for your needs.
								</p>
							</div>
							<div className="col-lg-6 discover_img">
								<div className="img">
									<img src={url + "/uploads/default/15.jpg"} alt="" />
								</div>
							</div>
						</div>

						<div className="row discover_single_product">
							<div className="col-lg-6 col-md-6 left">
								<img src={url + "/uploads/default/18.jpg"} alt="" />
							</div>
							<div className="col-lg-6 col-md-6 right">
								<h2>CBD Facial Serum: Anti-Aging <i className="fa fa-plus"></i> Daily Moisturizer</h2>
								<div className="root_star">
									<ReactStars {...starController} value={5} />
									<span className="star_span">(2)</span>
								</div>
								<p className="description">CBD Anti-aging facial CBD Serum is your key to a revolutionary skincare experience. This potent facial serum contains compounds that revives the face from aging. This potent formula instantly uplifts and tightens the skin around your eyes and face, resulting to a firmer, smoother, and more youthful appearance. Enjoy the healing power of our serum and regain your confidence as you indulge in a skincare solution that truly delivers outstanding results.</p>
								<div className="feature">
									<div className="item">
										<i className="fa fa-check"></i>
										<p>Sativa-Dominant</p>
									</div>
									<div className="item">
										<i className="fa fa-check"></i>
										<p>THC 110.0-160.0mg/g</p>
									</div>
									<div className="item">
										<i className="fa fa-check"></i>
										<p>Euismod elementum</p>
									</div>
									<div className="item">
										<i className="fa fa-check"></i>
										<p>CBD 0.0-1.0mg/g</p>
									</div>
									<Link to="/strain" className="read_review">
										Read Review
									</Link>
								</div>
							</div>
						</div>



						{reviews.length > 0 && (
							<div className="home_review_carousel">
								<Reviews data={reviews} />
							</div>
						)}
					</Container>
					<Container className="subscribe_container">
						<Subscribe />
					</Container>

					<Container className="benefir_container">
						<div className="row benefit">
							<div className="col-lg-6 main_img second_col">
								<div className="img">
									<img src={url + "/uploads/default//17.jpg"} alt="" />
								</div>
							</div>



							<div className="col-lg-6 last_col">
								<h2>Therapeutic Effects of Cannabis and Cannabinoids</h2>
								<p className="description">CBD also known as Cannabidiol is one of the major compounds found in the cannabis and hemp plants. Over the last years, CBD has gained significant popularity for its therapeutic potential. With various CBD products available on the market, it’s essential to understand how it can benefit you.</p>
								<div className="benefit_row">
									<div className="left">
										<div className="img">
											<img src={url + '/uploads/default/icon2.png'} alt="" />
										</div>

									</div>
									<div className="right">
										<p>Chronic Pain </p>
										<p>Research suggests CBD can modulate and decrease inflammation which are the major causes of joint and arthritis pain. CBD has neuroprotective properties which interact with the pain receptors in the body to help ease neuropathic pain.</p>
									</div>
								</div>
								<div className="benefit_row">
									<div className="left">
										<div className="img">
											<img src={url + '/uploads/default/icon3.png'} alt="" />
										</div>

									</div>
									<div className="right">
										<p>Anxiety And Stress Reduction</p>
										<p>CBD may also help in reducing anxiety and stress levels by interacting with serotonin receptors in the brain, increasing the rate of serotonin. This eventually helps to decrease anxiety, depression, and aggression.</p>
									</div>
								</div>
								<div className="benefit_row">
									<div className="left">
										<div className="img">
											<img src={url + '/uploads/default/icon1.png'} alt="" />
										</div>

									</div>
									<div className="right">
										<p>Treating Sleep Disorders</p>
										<p>CBD may offer help those with difficulties sleeping. Research suggest that CBD could help improve sleep quality by addressing underlying causes such as anxiety or chronic pain.</p>
									</div>
								</div>
								<Link to="/dispensary" className="global_btn">
									Read Reviews
								</Link>
							</div>
						</div>



					</Container>


					<div className="container">
						<div className="row news_heading">
							<div className="col-lg-12">
								<h2>Latest News</h2>
							</div>
						</div>
						<div className="row news_content">

							<div className="col newes_list_main">
								{news.map(item => {
									let image = (item.profile && JSON.parse(item.profile)) && JSON.parse(item.profile).url
									return (
										<div className="news_list">
											<div className="left">
												<img src={image} alt="" />
											</div>
											<div className="right">
												<div className="news_date">
													<img src={url + '/uploads/default/calendar.png'} alt="" />
													<span>{newsTime(item.createdAt)}</span>
													<img src={url + '/uploads/default/comment_icon.png'} alt="" />
													<span>{item.comment ? item.comment + ' Comments' : 'No Comments'}</span>
												</div>
												<h2>{item.title} </h2>
												<div className="dynamic_content">
													{dynamicContent(item.description, item.url)}
												</div>
											</div>
										</div>
									)
								})}

							</div>
						</div>

					</div>

					{discover.length > 0 && load && <div className="container"><Products cordinates={cordinates} data={discover} title="Recently viewed" link="/strain" productType={"business"} />	</div>}





					{/* <div className="instagram">
						<div className="heading">
							<h2>Follow us on Instagram</h2>
							<p>Follow us for the latest in cannabis innovation, education, and peek into our exclusive strains. Plus, don't miss out on our excellent giveaways.</p>
						</div>
						<div className="instgram_image">
							<div className="img">
								<img src={url + '/uploads/default/insta1.png'} alt="" />
							</div>
							<div className="img">
								<img src={url + '/uploads/default/insta2.png'} alt="" />
							</div>
							<div className="img">
								<img src={url + '/uploads/default/insta3.png'} alt="" />
							</div>
							<div className="img">
								<img src={url + '/uploads/default/insta4.png'} alt="" />
							</div>
							<div className="img">
								<img src={url + '/uploads/default/insta5.png'} alt="" />
							</div>
						</div>
					</div> */}
				</>
			)}
		</>
	)
}

export default Home
