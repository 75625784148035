import ReactStars from "react-rating-stars-component"
import "./reviews.css"
import { url } from "../../helpers/url"
import { SplideSlide } from "@splidejs/react-splide"
import CarouselAll from "../../components/carousel/all-carousel"
const Reviews = ({ data }) => {
	return (
		<>
			<CarouselAll page={3} title="Featured Reviews">
				{data.map((item) => {
					const starController = {
						edit: false,
						half: false,
						count: 5,
						size: 30,
						isHalf: true,
					}

					return (
						<SplideSlide>
							<a href={item["businesses.url"]} className="review_anchor" id={"review-" + item.id}>
								<div className="home_review_box">
									<div className="home_review_box_item">
										<h3>{item["businesses.business_name"]}</h3>
										<div className="home_star_main">
											<ReactStars {...starController} value={item.total} />
											<span className="home_star_span">{item.total}</span>
										</div>
										<p>{item.comment}</p>
										<div className="quote_img">
											<img className="quote" src={url + "/uploads/default/quote.png"} alt="" />
										</div>
										<div className="review_profile_box">
											<div className="img">
												<img src={item["registers.image"]} alt="" />
											</div>
											<div className="content">
												<p className="name">{item["registers.fname"] + " " + item["registers.lname"]}</p>
												<p className="position">{item["registers.position"]}</p>
											</div>
										</div>
									</div>
								</div>
							</a>
						</SplideSlide>
					)
				})}
			</CarouselAll>
		</>
	)
}
export default Reviews
