import React, { useState, useEffect, useContext } from 'react';
import { url } from '../../helpers/url';
import axios from 'axios';
import { Context } from '../../helpers/context';
import './invoice.css'
import { Helmet } from "react-helmet"
import { Redirect } from 'react-router-dom';
const Invoice = (props) => {
  const [user, setUser] = useContext(Context)
  const [pdf, setPdf] = useState()
  const [load, setLoad] = useState(false)

  useEffect(async () => {
    if (props.match.params.id) {
      document.querySelector('.header_main').style.display = 'none'
      document.querySelector('.footer').style.display = 'none'
      const { data } = await axios.get(url + '/invoices/api?id=' + props.match.params.id, { responseType: 'blob' })
      const blob = new Blob([data], { type: 'application/pdf' })
      if (blob) {
        setPdf(URL.createObjectURL(blob))
        setLoad(true)
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"Invoice"}</title>
        <link rel="canonical" href={"https://letsmango.com/invoice"} />
        <meta name="description" content={"Let's Mango"} />
      </Helmet>
      {(user.load && !user.type) && <Redirect to="/login" />}
      {(pdf && load) && <iframe className='invoice_pdf' src={pdf} width="100%"></iframe>}

    </>);
}
export default Invoice;