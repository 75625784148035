import React, { useEffect, useState } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import Pagination from "../../components/pagination/pagination"
import Breadcumbs from "../../components/breadcumbs/breadcumbs"
import { dynamicView } from "../../helpers/dynamic_view"
import BusinessCom from "../../components/write-review/write-review"
const Shop = (props) => {
  const [bus, setBus] = useState([])
  const [count, setCount] = useState([])
  const [load, setLoad] = useState(false)
  const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
  useEffect(() => {
    axios.get(`${url}/business/api?page=0`).then(({ data }) => {
      if (data.success) {
        setCordinates(data.cordinates)
        setBus(data.rows)
        setCount(data.count)
        setLoad(true)
      }
    })
    dynamicView("page")
  }, [])
  const [next, setNext] = useState(true)
  function getPaginationId(id) {
    const length = 20
    const page = id > 0 ? (id - 1) * length : 0
    axios.get(`${url}/business/api?page=${page}`).then(({ data }) => {
      if ((length !== data.rows.length) || count.length === id) {
        setNext(false)
      } else {
        setNext(true)
      }
      setBus(data.rows)
      setCount(data.count)
      setLoad(true)
    })
  }



  return (
    <>
      <section className="mercahndise_main_section">
        <Breadcumbs title={"Write Review"} link={"/review"} linktext={"Write Review"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
        <div className="container">
          {(bus.length > 0 && load && cordinates.lat && cordinates.lng) && <BusinessCom cordinates={cordinates} data={bus} title="Dispensaries Near Me" />}

          {(load && bus.length === 0) && (<div className="alert alert-danger my-3">
            <p className="title_color">There are no Business Page</p>
          </div>

          )}
          {count.length > 0 && <Pagination isNext={next} product={bus.length} count={count} clickId={getPaginationId} />}
        </div>
      </section>
    </>
  )
}
export default Shop
