const Deals = (props) => {
  return (
    <>
      <section className="login-page section-b-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="alert alert-danger">No deals are found</div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Deals
