import React, { useState, useEffect, useContext } from "react"
import { Container } from "react-bootstrap"
import Products from "../../components/products/products"
import BusinessCom from "../../components/business/business"
import { Context } from "../../helpers/context"
import axios from "axios"
import "./search.css"
import { useLocation, useParams } from "react-router-dom"
import { url } from "../../helpers/url"

const turf = require("@turf/turf")
const Search = (props) => {
	const { search } = useLocation()
	const [product, setProduct] = useState([])
	const [filterPro, setFilterPro] = useState([])
	const [cat, setCat] = useState([])
	const [count, setCount] = useState([])
	const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
	const [load, setLoad] = useState(false)
	const [user, setUser] = useContext(Context)
	const [searchText, setSearchText] = useState('')
	const [filter, setFilter] = useState('')
	const [type, setType] = useState('')
	useEffect(() => {
		if (search) {
			const query = search.split('&')
			const searchtxt = query[0].split('?search=')[1]
			const lat = query[1].split('lat=')[1]
			const lng = query[2].split('lng=')[1]
			const type = query[3].split('type=')[1]
			setType(type)
			let apiURL
			if (type === 'Dispensary') {
				apiURL = url + '/search/business/api'
			}
			else if (type === 'Strain') {
				apiURL = url + '/search/product/api'
			}
			else if (type === 'Merchandise') {
				apiURL = url + '/search/merchandise/api'
			}

			if (apiURL) {
				axios.post(apiURL, {
					search: searchtxt.replace(/%20/g, ' '), page: 0, limit: 20, lat: lat, lng: lng
				}).then(({ data }) => {
					if (data.success && data.rows) {
						const category = []
						if (type === 'Dispensary') {
							const filterCat = data.rows.map(item => {
								if (item.category) {
									JSON.parse(item.category).map(newItem => {
										category.push(newItem.value)
									})
								}
							})
						} else {
							const filterCat = data.rows.map(item => {
								if (item.category) category.push(item.category)
							})
						}

						const parseCat = [... new Set(category)]
						setCat(parseCat)
						setCordinates({ lat: lat, lng: lng })
						setFilter(type)
						setProduct(data.rows)
						setFilterPro(data.rows)
						setCount(data.count)
						setLoad(true)
						if (data.rows.length > 0) {
							const device = navigator.userAgent;
							axios.post(url + '/analytics/search/api', { product: data.rows, device: device, type: type })
						}
					}
				})
			}
		}
	}, [search])



	const filterByCategory = (e) => {

		const value = e.target.value
		if (value) {
			if (type === 'Dispensary') {
				const filter = filterPro.filter((pro) => pro.category.includes(value))
				setProduct(filter)
			} else {
				const filter = filterPro.filter((pro) => pro.category === value)
				setProduct(filter)
			}
		} else {
			setProduct(filterPro)
		}
	}


	const filterByDistance = (e) => {
		const value = e.target.value
		if (value) {
			const filterItem = []
			const from = turf.point([cordinates.lat, cordinates.lng])
			filterPro.map((item) => {
				const to = turf.point([item.latitude, item.longitude])
				const distance = parseInt(Math.round(turf.distance(from, to, { units: "miles" })))
				const target = parseInt(value)
				if (distance <= target) {
					filterItem.push(item)
				}
			})
			if (filterItem.length > 0) {
				setProduct(filterItem)
			} else {
				setProduct(filterPro)
			}
		} else {
			setProduct(filterPro)
		}
	}


	// const [search, setSearch] = useState()
	// const changeHandler = (e) => {
	// 	setSearch(e.target.value)
	// }

	// const searchInput = (e) => {
	// 	e.preventDefault()
	// 	axios.get(`${url}/search/api?search=${search}&lat=${user.cordinates.lat}&lng=${user.cordinates.lng}&page=0`)
	// 		.then(({ data }) => {
	// 			if (data.success) {
	// 				setProduct(data.rows)
	// 				setFilterPro(data.rows)
	// 				setCount(data.count)
	// 				setLoad(true)
	// 				if (data.rows.length > 0) {
	// 					const device = navigator.userAgent;
	// 					axios.post(url + '/analytics/search/api', { product: data.rows, device: device })
	// 				}
	// 			}
	// 		})
	// }

	const getProduct = (product) => {
		// if (product.variation) {
		//     setSingle(product)
		// }
	}


	return (
		<>
			<Container>
				{/* <div className="row">
					<div className="col search_business">
						<form onSubmit={searchInput} className="text-center">
							<input onChange={changeHandler} type="text" name="text" value={search} />
							<button className="button" type="submit">
								Search
							</button>
						</form>
					</div>
				</div> */}

				<div className="search_page_main">
					<div className="row search_page py-5">
						<div className="col search_heading">
							<p className="font24 half_black">Search Results</p>
							{load && product.length === 0 &&
								<div className="alert-danger alert">
									Not found
								</div>
							}
						</div>

						<div className="filter_search col justify-content-end">

							<>
								{cat.length > 0 && (
									<div className="filter_category">
										<select name="category" onChange={filterByCategory} id="" className="form-control">
											<option value="">Filter By Category</option>
											{cat.map((item) => {
												return <option value={item}>{item}</option>
											})}
										</select>
									</div>
								)}

								{/* <div className="filter_search">
									<div className="">
										<select name="distance" onChange={filterByDistance} className="form-control">
											<option value="">Filter By Distance</option>
											<option value="25">25</option>
											<option value="50">50</option>
											<option value="75">75</option>
											<option value="100">100</option>
										</select>
									</div>
								</div> */}
							</>
						</div>
					</div>

					{(product.length > 0 && load && filter === 'Dispensary') && <BusinessCom cordinates={cordinates} data={product} rows={count} title="" />}

					{(product.length > 0 && load && filter === 'Strain') && <Products productType="business" data={product} viewItem="true" menu="false" cordinates={cordinates} rows={count} />}


					{(product.length > 0 && load && filter === 'Merchandise') &&
						<Products cartItem={getProduct} productType="merchandise" data={product} viewItem="true" menu="false" cordinates={cordinates} rows={count} />}

				</div>
			</Container>
		</>
	)
}
export default Search
