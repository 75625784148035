import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import axios from "axios"
import { url } from "../../helpers/url"
import BusinessCom from "../../components/business/business"
import "./search-business.css"
const Business = ({ type, row }) => {
	const [bus, setBus] = useState([])
	const [count, setCount] = useState([])
	const [cordinates, setCordinates] = useState({})
	const [load, setLoad] = useState(false)
	useEffect(() => {
		axios.get(`${url}/business/api?page=0`).then(({ data }) => {
			if (data.success) {
				setCordinates(data.cordinates)
				setBus(data.rows)
				setCount(data.count)
				setLoad(true)
			}
		})
	}, [row])

	const findBusines = (e) => {
		e.preventDefault()
		const search = e.target.text.value
		if (search && search.trim()) {
			axios.post(url + "/search/business/api", { search: search.trim(), page: 0, limit: 20, lat: cordinates.lat, lng: cordinates.lng }).then(({ data }) => {
				if (data.success) {
					setBus(data.rows)
					setCount(data.count)
				}
			})
		}
	}

	return (
		<Container>
			<div className="row">
				<div className="col search_business">
					<form onSubmit={findBusines} className="text-center">
						<input type="text" name="text" />
						<button className="button" type="submit">
							Search
						</button>
					</form>
				</div>
			</div>

			{bus.length > 0 && load && cordinates.lat && cordinates.lng && <BusinessCom cordinates={cordinates} data={bus} title="Dispensaries Near Me" />}

			{bus.length === 0 && (
				<>
					<h3>There are no Business Page</h3>
				</>
			)}
		</Container>
	)
}
export default Business
