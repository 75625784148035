import React, { Fragment, useState, useRef, useEffect, useContext } from "react"
import { imgPath, url, } from "../../helpers/url"
import { bannerSize } from "../../helpers/functions"
import "./profile.css"
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap"
import axios from "axios"
import { Context } from "../../helpers/context"
const BusinessPage = (props) => {
	const [user, setUser] = useContext(Context)
	const [content, setContent] = useState({
		fname: "",
		lname: "",
		username: "",
		email: "",
		position: "",
		password: "",
		type: "edit",
		exists: false,
		image: "",
	})
	const [postSuccess, setPostsuccess] = useState(false)
	useEffect(() => {
		axios.get(`${url}/admin/profile/api`).then(({ data }) => {
			const updateContent = { ...content, ...data.profile }
			setContent(updateContent)
			if (data.profile) {
				if (data.profile.background) {
					setProfile(JSON.parse(data.profile.background))
				}
				if (data.profile.image) {
					setProfile2(data.profile.image)
				}
				if (data.profile.social) {
					setSocial(JSON.parse(data.profile.social))
				}
			}


		})
	}, [])

	useEffect(() => {
		if (postSuccess)
			setTimeout(() => {
				localStorage.removeItem('letsmango.com')
				window.location.href = "/login"
			}, 3000);
	}, [postSuccess === true])

	const changeHandler = (e) => {
		const updateContent = {
			...content,
			[e.target.name]: e.target.value,
		}
		setContent(updateContent)
	}


	const [profile, setProfile] = useState()
	const [profile2, setProfile2] = useState()


	const imageHandle = (e, type) => {
		const formData = new FormData()
		if (Array.from(e.target.files)[0]) {
			formData.append("image", Array.from(e.target.files)[0])
			formData.append("type", type)
			axios.post(url + "/uploads/user", formData).then(({ data }) => {
				if (data.success) {
					if (type === 'banner') {
						setProfile(data.data)
					}
					if (type === 'profile') {
						setProfile2(data.filename)
					}

				}
			})
		}
	}

	const profileRef = useRef(null)
	const profileRefClick = (e) => {
		profileRef.current.click()
	}

	const profileRef2 = useRef(null)
	const profileRefClick2 = (e) => {
		profileRef2.current.click()
	}

	const [social, setSocial] = useState({
		social: {},
		total: 0
	})


	const addSocial = () => {
		let total = social.total + 1
		setSocial({
			social: {
				...social.social,
				[total]: { id: total, link: '', name: '' }
			},
			total: total
		})
	}

	const deleteSocial = (item) => {
		let copySocial = { ...social }
		delete copySocial.social[item]
		setSocial(copySocial)
	}

	const socialChange = (e, item) => {
		let copySocial = { ...social }
		copySocial.social[item][e.target.name] = e.target.value
		setSocial(copySocial)
	}

	const [email, setEmail] = useState(false)
	const [username, setUsername] = useState(false)

	const onSubmit = (e) => {
		e.preventDefault()
		const formData = {
			fname: content.fname,
			lname: content.lname,
			username: content.username,
			email: content.email,
			position: content.position,
			password: content.password,
			description: content.description,
			background: profile,
			social: social,
			type: "edit",
			image: profile2,
		}

		axios.post(`${url}/admin/profile/api`, formData).then((result) => {
			const updateData = result.data
			if (updateData.success) {
				setPostsuccess(true)
			} else {
				setEmail(updateData.email)
				setUsername(updateData.username)
			}

		})
	}

	const [passeye, setPasseye] = useState(false)
	return (
		<Fragment>

			<Container fluid={true} className="customer_profile">
				{(user.load && user.type) &&
					<Row>
						<Col className="col-md-12 col-lg-12 col-sm-12 customer_col">
							{postSuccess ? (
								<div className="alert alert-success">Your Profile has been updated successfully</div>
							) : (
								<Form onSubmit={onSubmit} className="mx-5" method="post" encType="multipart/form-data">
									{postSuccess && (
										<FormGroup className="row  w-100 d-block">
											<div className="alert alert-success">Page has been updated</div>
										</FormGroup>
									)}

									<div className="profile_box banner">
										<div className="profile_user" onClick={profileRefClick}>
											<div className="user_1"
												style={{
													backgroundImage: `url('${profile ? imgPath + bannerSize(profile) : process.env.REACT_APP_SOCIAL_BANNER_IMAGE}')`
												}}
											>
												<div className="user_2">
													<i class="fa fa-camera camera" aria-hidden="true"></i>
												</div>
											</div>
										</div>
									</div>

									<div className="input_group hidden_input">
										<Label className="">Banner Image</Label>
										<input ref={profileRef} onChange={(e) => imageHandle(e, 'banner')} className="form-control " id="validationCustom1" type="file" name="image" />
									</div>

									<div className="profile_box image">
										<div className="profile_user" onClick={profileRefClick2}>
											<div className="user_1">
												{profile2 && <img className="user" src={imgPath + profile2 + '-' + 200 + '.webp'} alt="" />}
												{!profile2 && <i class="fa fa-user user" aria-hidden="true"></i>}
											</div>
											<div className="user_2">
												<i class="fa fa-camera camera" aria-hidden="true"></i>
											</div>
										</div>
									</div>
									<div className="input_group hidden_input">
										<Label className="">Profile Picture</Label>
										<input ref={profileRef2} onChange={(e) => imageHandle(e, 'profile')} className="form-control " id="validationCustom1" type="file" name="image" />
									</div>

									<div className="input_group">
										<Label className="">First Name</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="text" required name="fname" value={content.fname} />
									</div>

									<div className="input_group mt-2">
										<Label className="">Last Name</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="text" required name="lname" value={content.lname} />
									</div>
									<div className="input_group mt-2">
										<Label className="">Username</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="text" required name="username" value={content.username} />
										{username &&
											<div className="alert alert-danger mt-3">This username already exists.</div>
										}
									</div>

									<div className="input_group mt-2">
										<Label className="">Email Name</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="text" required name="email" value={content.email} />
										{email && <div className="alert alert-danger  mt-3">This email already exists.</div>
										}
									</div>
									<div className="input_group mt-2">
										<Label className="">Position</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="text" name="position" value={content.position} />
									</div>

									<div className="input_group mt-2">
										<Label className="">Password</Label>
										<div className="eye_pass_input">
											<i className={passeye ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye(!passeye)}></i>
											<Input onChange={changeHandler} className="form-control" id="validationCustom1" type={passeye ? "text" : "password"} name="password" />
										</div>
									</div>


									{
										Object.keys(social.social).map(item => {
											return (
												<div className="social_media_wrapper">
													<i className="fa fa-close" onClick={(e) => deleteSocial(item)}></i>
													<div className="input_group mt-2">
														<Label className="">Select Social Media</Label>
														<select name="name" id="" required className="form-control" onChange={(e) => socialChange(e, item)} >
															<option value="">---</option>
															<option selected={social.social[item].name === 'Facebook'} value="Facebook">Facebook</option>
															<option selected={social.social[item].name === 'Twitter'} value="Twitter">Twitter</option>
															<option selected={social.social[item].name === 'Tiktok'} value="Tiktok">Tiktok</option>
															<option selected={social.social[item].name === 'Instagram'} value="Instagram">Instagram</option>
															<option selected={social.social[item].name === 'Youtube'} value="Youtube">Youtube</option>
															<option selected={social.social[item].name === 'Pinterest'} value="Pinterest">Pinterest</option>
															<option selected={social.social[item].name === 'Linkedin'} value="Linkedin">Linkedin</option>
														</select>
													</div>

													<div className="input_group mt-2">
														<Label className="">Social Link</Label>
														<Input className="form-control" id="validationCustom1" type="text" required name="link" onChange={(e) => socialChange(e, item)} value={social.social[item].link} />
													</div>
												</div>
											)
										})
									}




									<div className="input_group mt-2">
										<button onClick={addSocial} className="btn input_button add_social_btn" type="button">
											<i className="fa fa-plus"></i> Add Social Media Link
										</button>
									</div>


									<div className="input_group mt-2">
										<Label className="">Description</Label>
										<Input onChange={changeHandler} className="form-control" id="validationCustom1" type="textarea" name="description" value={content.description} />
									</div>

									<FormGroup className="row mt-3">
										<button className="btn input_button profile_input_btn" type="submit">
											Update Profile
										</button>
									</FormGroup>
								</Form>
							)}
						</Col>
					</Row>
				}

			</Container>

		</Fragment>
	)
}

export default BusinessPage
