import Alert from "../alert/alert"
const Cancel = (props) => {
  return (
    <>
      <div className="container mt-4">
        <Alert type="error" title="Whoops!" sub="Something Went Wrong" description="Please enter the correct details to proceed" />
      </div>
    </>
  )
}
export default Cancel
