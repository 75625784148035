import './terms.css'
import Breadcumbs from '../../components/breadcumbs/breadcumbs'
const Terms = () => {
  return (
    <>
      <Breadcumbs title={"Terms and Conditions"} link={"/terms"} linktext={"Terms and Conditions"} description={""} />
      <div className="container">

        <div className="terms">
          <h3 className="underline">BEFORE VIEWING THE CONTENTS OF THE WEBSITE:</h3>
          <ul>
            <li>
              The website should have a prompt confirming age is over 21 or asking for the date of birth to confirm the age is over 21.
            </li>
            <li>
              Prompt asking what Country &  State the person is entering is from
            </li>
          </ul>
          <h3>*BEFORE BEING ABLE TO MAKE AN ACCOUNT OR VIEW ANY CONTENT ON THE WEBSITE; ALL USERS SHOULD HAVE TO ACCEPT & AGREE TO THE TERMS OF USE*
          </h3>

          <div className="section section_1">
            <h3 className="center underline bold">TERMS OF USE</h3>
            <h3 className="underline bold">SECTION 1:</h3>
            <p><span className="bold">1.1</span> The website located at: <a href="https://letsmango.com">https://letsmango.com</a>, hereinafter known as the “<span className="bold">Website</span>,” is a copyrighted work belonging to <span className="italic">Let’s Mango, LLC,</span> and its affiliates. <span className="italic">Let’s Mango, LLC</span> provides content related to Marijuana strains and related products, which includes but is not limited to information, location, and review of local dispensaries, cannabis-related news articles, product reviews, and ratings provided by its Users. References to “user,” “you,” and “your” refer to you, a user of the Website and/or Services. </p>

            <p className="main_margin">Please also review <span className="italic">Let’s Mango, LLC</span> Privacy Policy (herein known as “Privacy Policy”), which governs <span className="italic">Let’s Mango, LLC</span> collection and use of your personal information. By accepting the terms of this Agreement you understand that through your access of the Website and any of the services offered herein, you consent to the collection and use of this information.
            </p>

            <p><span className="bold">1.2</span> Please understand the use of this Agreement set forth is legally binding terms for your use of the Website, services, and information being provided. By accessing or using the Website, you are accepting the terms of this Agreement and guarantee that you have the capacity to enter into this Agreement with <span className="italic">Let’s Mango, LLC</span>. If you do not agree with the terms of this Agreement after reading the entirety of the terms, or do not have the capacity to agree to the terms of this Agreement, DO NOT ACCESS AND/OR USE THE WEBSITE OR ITS SERVICES. If you are using the Website on behalf of a third party (company, entity, organization, ect.) you are representing that you are an authorized person to bind it to this Agreement.
            </p>
            <div className="signiture">
              <a href="mailto:support@letsmango.com" className=''>support@letsmango.com</a>
              <p>Provide email & address</p>
            </div>
          </div>
          <div className="section section_2">
            <p className="bold">Please read the terms herein carefully, as they contain an agreement of important information regarding your legal rights, remedies, and obligations. Please note that <span className="italic">Let’s Mango, LLC</span> reserves the right to change and/or amend the Terms of this Agreement at any time. It is the Users responsibility to periodically check the Terms of this Agreement, and stay up to date with any changes made.</p>
            <h3 className="underline bold">SECTION 2:</h3>
            <h3 className="underline">DISCLAIMERS & ACKNOWLEDGMENTS:</h3>

            <p><span className='bold'>2.1 </span>All content contained on the Website is for informational purposes only.<span className="italic">Let’s Mango, LLC</span> is not responsible for the accuracy of any information found on The Website, including the information posted by its Users. </p>

            <p><span className='bold'>2.2 </span><span className="italic">Let’s Mango, LLC</span> is not liable for your relationship or experience with any dispensary, medical provider, retail facility, or any other Users on the Website.
            </p>

            <p><span className='bold'>2.3 </span><span className="italic">Let’s Mango, LLC</span> does not cultivate, manufacture, package, test, or sell cannabis. Any third-party sellers and/or brands appearing on the Website are exclusively responsible for their products' quality, safety, and legality.
            </p>

            <p className='underline full_margin'>ACKNOWLEDGMENT OF STATE LAWS:</p>


            <p className=''><span className='bold'>2.4 </span>Users of the Website acknowledge that <span className="italic">Let’s Mango, LLC</span> is intended only for residents of states and localities with laws that regulate and allow medical or recreational marijuana usage.
            </p>
            <p className=''><span className='bold'>2.5 </span>Users understand and agree that in your state of residence, the use, possession, manufacturing, cultivation,  transportation, and distribution of Marijuana may be illegal.
            </p>
            <p className=''><span className='bold'>2.6 </span>Users agree to assume full responsibility for following the laws of your location while using the Website.
            </p>

            <p className='underline full_margin'>ACKNOWLEDGMENT OF FEDERAL LAWS:</p>

            <p className=''><span className='bold'>2.7 </span>Users of this website explicitly acknowledge that Marijuana is a Schedule 1 substance under the Controlled Substances Act. Manufacturing, distributing, interstate transportation, dispensing, and/or possession of Marijuana is illegal, and <span className="italic">Let’s Mango, LLC</span> is not liable for prosecution against Users participating in any of the above mentioned acts. Furthermore, the User acknowledges that medical use is not recognized as a valid defense under federal laws regarding Marijuana.
            </p>

            <p className='underline full_margin'>HEALTH INFORMATION:</p>

            <p className=''><span className='bold'>2.8 </span>Users of the Website acknowledge that none of the information found on the Website relating to Marijuana, or any other product, has been evaluated or approved by the FDA.
            </p>

            <p className=''><span className='bold'>2.9 </span><span className="italic">Let’s Mango, LLC</span> does not offer any medical advice. The content found on the Website is for educational and informational use only and is not intended to diagnose, prevent, cure or treat any medical conditions and/or illnesses.

            </p>
            <p className=''><span className='bold'>3.0 </span>If you are pregnant, nursing, taking medication, or have a medical condition, we recommend consulting with a physician before the use of any products displayed on the Website. Always consult a qualified health care provider if you are looking for medical attention or advice.</p>

          </div>

          <div className="section section_3">
            <h3 className="underline bold">SECTION 3:</h3>
            <p className='underline full_margin'>ACCESS AND ACCOUNTS</p>

            <p className=''><span className='bold'>3.1 </span>Users must be 21 years old or be a valid medicinal cannabis patient to view, access, or have an account with <span className="italic">Let’s Mango, LLC</span>. </p>

            <p className=''><span className='bold'>3.2 </span>While creating an account with the Website, (“Mango Account”) Users must provide personal information during the Mango Account registration process. Users agree that (a) all the information provided during registration will be true and accurate; and (b) Users will keep their account information updated and current at all times. <span className="italic">Let’s Mango, LLC</span> reserves the right to suspend or terminate your Mango Account at any time (reference section 4.0).
            </p>

            <p className=''><span className='bold'>3.3 </span>Users will not share or allow any third party to use the account, and Users will notify the <span className="italic">Let’s Mango, LLC</span> team if there is any unauthorized or suspicious activity on the account. Users are solely responsible for any and all activity on the Mango Account associated with the password and username; regardless if the activity was done by you. Let’s Mango, LLC is not liable for any loss or damage arising from same.
            </p>

            <p className=''><span className='bold'>3.4 </span>Mango Account’s cannot be used for commercial activities, including but not limited to, transactions, advertising, fundraising, contents or other promotions without written consent from <span className="italic">Let’s Mango, LLC</span>.
            </p>

            <p className=''><span className='bold'>3.5 </span><span className="italic">Let’s Mango, LLC</span> Accounts must be created by an authorized representative of whoever is the subject of the <span className="italic">Let’s Mango, LLC</span> Account. If for any reason, you believe a User on the Website is unauthorized, fake or misleading, please email Let's Mango, LLC, at <a href="mailto:support@letsmango.com">support@letsmango.com</a>.  In addition, Let’s Mango, LLC is not obligated to analyze every account and is not liable or responsible for any unauthorized Mango Account.

            </p>

            <p className='underline full_margin'>USER CONTENT</p>

            <p className=''><span className='bold'>3.6 </span>User Content, (“User Content”), refers to any and all information that the User posts or portrays on the Website, including but not limited to  the Users profile, reviews and/or photos. <span className="italic">Let’s Mango, LLC</span> is not responsible for any User Content found on the Website, including its accuracy, copyright compliance, or legality.</p>

            <p className=''><span className='bold'>3.7 </span>Users agree not to post anything unlawful, harassing, abusive, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, purposely misleading, obscene, patently offensive (etc., content that encourages or participates in racism, bigotry, hatred, or physical harm of any kind against any group or individual), in violation of any law, regulation, or obligations or restrictions.
            </p>

            <p className=''><span className='bold'>3.8 </span>Users are solely responsible for their User Content and take all risks associated with what you decide to post on your Mango Account. By using the Website and Services, you understand and agree that <span className="italic">Let’s Mango, LLC</span> does not create or post any information provided by other Users, including User Content, and <span className="italic">Let’s Mango, LLC</span> is not liable for any information provided by other users, including User Content.
            </p>

            <p className=''><span className='bold'>3.9 </span>By accepting these Terms, the User agrees to allow <span className="italic">Let’s Mango, LLC</span> nonexclusive, royalty-free rights to reproduce, store, display, incorporate, and/or distribute your User Content. Users consent to the use of their User Content and waive any claims of attribution with respect to their User Content.
            </p>

            <p className='underline full_margin'>TERMINATION OF ACCOUNT</p>

            <p className=''><span className='bold'>4.0 </span>Users fully acknowledge, understand, and agree that without any prior notice and under any circumstances, <span className="italic">Let’s Mango, LLC</span> can terminate your Mango Account and access to the Website. Cause for account termination can include but is not limited to: non-compliance with the Terms of the Website;  any attempts to breach the Website’s security measures; knowingly, willing, and or negligently engaging in any kind of fraudulent and/ or illegal activities. We reserve the right to review any and all User Content at any time and/or take necessary action against Users that violate any terms of this Agreement at any time.

            </p>
          </div>
          <div className="section section_4">
            <h3 className="underline bold">SECTION 4:</h3>
            <p className='underline full_margin'>ACCEPTABLE USE POLICY:</p>

            <p className=''><span className='bold'>4.1 </span>In order to leave a review on the Website, Users must have a valid <span className="italic">Let’s Mango, LLC</span> Account and email address. The only permissible reviews on the Website are based on Users' individual and personal experiences. Users agree not to post reviews that don’t reflect your personal experience or are intended for any other purpose than providing other Users on the Website with an accurate depiction of your experience. In addition, reviews on the Website must follow all Terms of Use in the Agreement (reference section 3).</p>

            <p className=''><span className='bold'>4.2 </span>To post Photos to the Website, Users agree that only the product they are reviewing should be in the image. Under no circumstance should any body parts, product wrapping, currency, paraphernalia, brand names, or any other product be in the photo. To the best of the Users' ability, the image should be a true and accurate depiction of the product they are reviewing.</p>

            <p className=''><span className='bold'>4.3 </span><span className="italic">Let’s Mango, LLC</span>, LLC might promote third-party individuals, corporations, or businesses, on the Website; however, Let’s Mango LLC is not responsible for your relationship or correspondence with any third-party services featured on the Website. Let’s Mango, LLC has no liability, obligation, or responsibility for any correspondence, purchase, transaction, or promotion between you and any third party. You agree that any interaction between any third party you interact with through the Website is your responsibility. </p>

            <p className=''><span className='bold'>4.5 </span><span className="italic">Let’s Mango, LLC</span> might promote third-party individuals, corporations, or businesses, on the Website; however, Let’s Mango LLC is not responsible for your relationship or correspondence with any third-party services featured on the Website. Let’s Mango, LLC has no liability, obligation, or responsibility for any correspondence, purchase, transaction, or promotion between you and any third party. You agree that any interaction between any third party you interact with through the Website is your responsibility.</p>

            <p className=''><span className='bold'>4.6 </span>You agree to indemnify and not hold <span className="italic">Let’s Mango, LLC</span> (and its officers, employees, and agents) responsible for any and all losses, damages, liabilities, claims, penalties, fines, costs, and/or expenses (including reasonable attorneys’ fees) arising from or relating to any claim or demand made by any third party due to your use or misuse of the Website; your User Content; your violation of this Agreement; or any legal violation; <span className="italic">Let’s Mango, LLC</span> reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of <span className="italic">Let’s Mango, LLC</span>.
            </p>

            <p className=''><span className='bold'>4.7 </span>Users agree not to use the Website to upload any computer viruses or software or in any way alter the software present on the Website. Users agree not to upload any unauthorized and unsolicited advertising. Users unequivocally understand that the harvesting, collecting, selling, or use of another Users information on the Website is strictly prohibited.</p>

            <p className=''><span className='bold'>4.8 </span><span className="italic">Let’s Mango, LLC</span> reserves the right to terminate and/or remove any User Content found on the Website (reference section 4.0)</p>

          </div>

          <div className="section section_5">
            <h3 className="underline bold">SECTION 5:</h3>
            <p className='underline full_margin'>COPYWRITING</p>

            <p className=''><span className='bold'>5.1 </span><span className="italic">Let’s Mango, LLC</span> takes copywriting very seriously. Users understand that in applicable situations and completely up to the company’s discretion, <span className="italic">Let’s Mango, LLC</span> has the right to terminate and/ or disable Let’s Mango Accounts that repeatedly violate or are accused of copywriting others’ information.
            </p>

            <p className=''><span className='bold'>5.2 </span>If you wish to report a User copywriting information, please email: <a href="mailto:support@letsmango.com">support@letsmango.com</a>. Please note that the more information you provide to the <span className="italic">Let’s Mango, LLC</span> team the better in situations that involve copywriting, including but not limited to: identifying the material on the Website you believe is copyrighted; detailed information to locate the material on the Website; explanation of why you believe the information is copyrighted and that you, in good faith believe that the User Content does not have authorization from the owner of the material you claim is being copyrighted.
            </p>

          </div>

          <div className="section section_6">
            <h3 className="underline bold">SECTION 6:</h3>
            <p className='underline full_margin'>DISPUTES </p>

            <p className=''><span className='bold'>6.1 </span>All Users agree that if a dispute arises between you and <span className="italic">Let’s Mango, LLC</span> and/ or the Website, the User and the company will first attempt to resolve the dispute through direct and informal discussion before arbitration. If, after attempting to resolve the dispute through direct communication,  the User or <span className="italic">Let’s Mango, LLC</span> feels the dispute cannot be handled informally, then scheduled arbitration will be discussed. Whichever party wishes to initiate arbitration agrees to notify the other party via email and certified mail immediately. The email and address to contact the <span className="italic">Let’s Mango, LLC</span> team regarding disputes, can contact us here: <a href="mailto:support@letsmango.com">support@letsmango.com</a>.

            </p>

            <p className=''><span className='bold'>6.2 </span>If a dispute deriving from or connected to <span className="italic">Let’s Mango, LLC</span>, the Website or content found on the Website cannot be handled amicably between the User and <span className="italic">Let’s Mango, LLC</span> (“the Parties”), the dispute will be solely handled through binding arbitration pursuant to the rules of the American Arbitration Association (“AAA”). The User agrees to arbitration with any claims brought under federal, state, or local law ordinances between <span className="italic">Let’s Mango, LLC</span>, and the User.</p>

            <p className=''><span className='bold'>6.4 </span>This Agreement is governed by the Federal Arbitration Act, and will be administered by the AAA pursuant to Consumer Arbitration Rules and any other rules applicable by the AAA. The aforementioned rules are accessible on: www.adr.org.</p>

            <p className=''><span className='bold'>6.3 </span>The User understands and waives their right to a trial by jury and agrees to have any and all disputes not handled amicably between the Parties be handled solely by individual arbitration. Individual arbitration meaning, the User acknowledges, understands, and agrees, to the fullest extent of the law, that you waive the right to be involved in a class action lawsuit, arbitration, and or collective action in regards to any and all disputes between the Parties.
            </p>

            <p className=''><span className='bold'>6.4 </span>All proceedings, rulings, decisions, and/or awards of arbitration between the Parties will be strictly confidential for the benefit of both parties.
            </p>

            <div className="signiture">
              <a href="mailto:support@letsmango.com" className=''>support@letsmango.com</a>
              <p> Provide email & address</p>
            </div>
          </div>
        </div>

      </div>
    </>)
}

export default Terms