export const license_type = [
  'Adult-Use Cultivation',
  'Adult-Use Mfg',
  'Adult-Use Nonstorefront',
  'Adult-Use Retail',
  'Distributor',
  'Event',
  'Medical Cultivation',
  'Medical Mfg',
  'Medical Nonstorefront',
  'Medical Retail',
  'Microbusiness',
  'Testing Lab'
]

export const state_names = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY']