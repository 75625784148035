import { useState } from "react"
import './accordion.css'

const Accordion = () => {
  const [clickId, setClickId] = useState({})
  const clickHandler = (id) => {
    setClickId({ ...clickId, [id]: !clickId[id] })
  }
  const total = [
    {
      title: 'Reduce Inflammation and Relieve Pain',
      description: 'Placerat orci nulla pellentesque dignissim enim sit amet venenatis urna. Tempus urna et pharetra pharetra massa massa ultricies mi. Elementum sagittis vitae et leo duis ut diam quam'
    },
    {
      title: 'Kill Cancer Cells and Slow Tumor Growth',
      description: 'Placerat orci nulla pellentesque dignissim enim sit amet venenatis urna. Tempus urna et pharetra pharetra massa massa ultricies mi. Elementum sagittis vitae et leo duis ut diam quam'
    },
    {
      title: 'Relax Tight Muscles in People with MS',
      description: 'Placerat orci nulla pellentesque dignissim enim sit amet venenatis urna. Tempus urna et pharetra pharetra massa massa ultricies mi. Elementum sagittis vitae et leo duis ut diam quam'
    },
    {
      title: 'Stimulate Appetite and Improve Weight',
      description: 'Placerat orci nulla pellentesque dignissim enim sit amet venenatis urna. Tempus urna et pharetra pharetra massa massa ultricies mi. Elementum sagittis vitae et leo duis ut diam quam'
    },
  ]
  return (
    <>
      {total.map((item, i) => {
        return (
          <div className="accordion_item">
            <div className="accourdion_top" onClick={() => clickHandler(i)}>
              <h2 className={clickId[i] ? 'active' : ''}>{item.title}</h2>
              <i className={clickId[i] ? "fa fa-minus" : "fa fa-plus"}></i>
            </div>
            <div className={clickId[i] ? "accourdion_bottom active" : "accourdion_bottom"}>
              <p>{item.description}</p>
            </div>
          </div>
        )
      })
      }
    </>

  )
}
export default Accordion