import React from 'react'
import './contact.css'
const ContactList = () => {
  return (
    <div className="contact_list">
      <div className="contact_item">
        <div className="left">
          <div className="icon">
            <i className="fa fa-map-marker"></i>
          </div>
        </div>
        <div className="right">
          <p>192 North Border Street</p>
          <p>Lithonia, GA 30038</p>
        </div>
      </div>
      <div className="contact_item">
        <div className="left">
          <div className="icon">
            <i className="fa fa-phone"></i>
          </div>
        </div>
        <div className="right">
          <p>+1-202-555-0133</p>
          <p>+1-202-555-0133</p>
        </div>
      </div>
      <div className="contact_item">
        <div className="left">
          <div className="icon">
            <i className="fa fa-envelope"></i>
          </div>
        </div>
        <div className="right contact_email">
          <a href="mailto:contact@letsmango.com">contact@letsmango.com</a>
        </div>
      </div>
      <div className="contact_item">
        <div className="left">
          <div className="icon">
            <i className="fa fa-clock-o"></i>
          </div>
        </div>
        <div className="right">
          <p>19:00 am to 5:00 pm</p>
          <p>Monday to Saturday</p>
        </div>
      </div>
    </div>
  )
}
export default ContactList