import React, { useState } from "react";
export const Context = React.createContext();
const Store = ({ children }) => {
  const [type, setType] = useState({
    type: '',
    businessType: '',
    name: '',
    fname: '',
    lname: '',
    image: '',
    userId: false,
    editor: '',
    notify: [],
    notifyLength: 0,
    state: '',
    cordinates: '',
    review: 0,
    total: 0,
    cart: {},
    load: false,
  });
  return (
    <Context.Provider value={[type, setType]} >
      {children}
    </Context.Provider >
  )
}

export default Store;