import Alert from "../alert/alert"
import { useEffect } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import { useLocation } from "react-router-dom"

const Success = () => {
  const location = useLocation()
  useEffect(() => {
    axios.get(url + "/subscription/success/api" + location.search)
  }, [])
  return (
    <>
      <div className="container mt-4">
        <Alert type="success" title="Congratulations" sub="Your subscription is completed" description="" link="/admin/my-subscription" a={true} />
      </div>
    </>
  )
}
export default Success
