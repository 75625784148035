import './write-review.css'
import ReactStars from "react-rating-stars-component"
import { useState, useEffect, useContext } from 'react'
import { Context } from "../../helpers/context"
import { url } from '../../helpers/url'
import axios from 'axios'
import { businessProfile } from "../../helpers/image_url"
const WriteReview = ({ data }) => {

  const [currentRate, setcurrentRate] = useState({})
  const [value, setValue] = useState(0)
  const [login, setLogin] = useState(true)
  const [user, setUser] = useContext(Context)
  const [load, setLoad] = useState(false)
  const [product, setProduct] = useState({})
  const [businessRow, setBusinessRow] = useState([])

  useEffect(() => {
    setBusinessRow(data)
  }, [data])


  // On Type comments
  const [comment, setComment] = useState(false)
  const [content, setContent] = useState("")
  const [image, setImage] = useState("")

  const starHandler = (value, item) => {
    window.location.href = "/dispensary/" + item.url + "#starbox"
  }

  const onType = (e) => {
    setContent(e.target.value)
  }



  const [reviewed, setReviewed] = useState({})
  const writeReviw = () => {
    const html = `<a class="notify_user" href="${url}/product/${product.url}">You have started ${product.title}</a>`
    const formData = new FormData()
    if (!currentRate.userId) {
      formData.append("total", value)
      formData.append("businessId", product.id)
      formData.append("comment", content)
      formData.append("notify", html)
      formData.append("image", image)
      setLoad(true)
      axios.post(`${url}/star/business/api`, formData).then(({ data }) => {
        if (data.success) {
          setLoad(false)
          setReviewed({ ...reviewed, id: product.id })
        }
      })
    }
  }

  const imageHandle = (e) => {
    setImage(e.target.files[0])
  }



  return (
    <div className="write-review row">

      {businessRow.map(item => {
        let profile = item.profile ? JSON.parse(item.profile) : businessProfile
        return (
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-5" id={"review-business-" + item.id}>
            <div className="write_review_box">
              <div className="left">
                <div className="img">
                  <img src={profile.url} alt="" onClick={() => starHandler('', item)} />
                </div>
              </div>
              <div className="right">
                <h2>{item.title}</h2>
                <p>Do you recommend this business?</p>
                <ReactStars color="#ccc" activeColor={"red"} classNames={"react_root_star"} count={5} size={34} onChange={(value) => starHandler(value, item)} value={value} />



                {(value > 0 && item.id === product.id && reviewed.id !== product.id) &&
                  <div className="review_form">
                    <div className="comment">
                      <textarea onChange={onType} className="" name="comment" rows="3" value={content} />
                    </div>
                    <div className="comment_file_box title_color">
                      <label htmlFor="">Upload images/videos</label>
                    </div>
                    <div className="comment_file_box title_color mb-3">
                      <input type="file" name="image" onChange={imageHandle} />
                    </div>
                    <a onClick={writeReviw} className={`btn btn-solid no_animate w-auto reviewbtn ${(value && content) ? "" : "disabled"}`}>
                      Rate It
                    </a>
                  </div>
                }
                {
                  reviewed.id === item.id && <div className="alert alert-danger">
                    Thanks for your review
                  </div>
                }
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
export default WriteReview