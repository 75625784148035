import "./news.css"
import { url } from "../../helpers/url"
import { substring } from "../../helpers/functions"
import { useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import { ArrowRightCircle } from "react-feather"
import { newsTime } from "../../helpers/time"
import { Link } from "react-router-dom"
const News = ({ rows, count }) => {
	const [news, setNews] = useState([])
	useEffect(() => {
		setNews(rows)
	}, [rows, count])

	// const dynamicContent = (content) => {
	// 	if (content) {
	// 		let newDyP = content.replace(/(<([^>]+)>)xA0/ig, '')
	// 		if (newDyP.length > 201) {
	// 			return "<p>" + substring(newDyP, 200) + "</p>"
	// 		} else {
	// 			return "<p>" + newDyP + "</p>"
	// 		}
	// 	}
	// }

	const dynamicContent = (str) => {
		if (str) {
			var plainString = str.replace(/<[^>]+>/g, " ")
			plainString.replace(/\s{2,}/g, " ").trim()
			return `<p>${plainString.substring(0, 150)}${plainString.length > 149 ? '...' : ''}</p>`
		}
	}



	return (
		<>

			<div className="news_row">
				{(rows.length > 0) &&
					news.map((item, i) => {

						const img = JSON.parse(item.profile)
						return (
							<>
								<div className="new_box_main" id={"news-" + item.id}>
									<div className="news_box">
										<div className="news_main_img">
											<img className="news_img" src={img.url} alt="" />
										</div>
										<div className="news_additional">
											<div className="left">
												<div className="img">
													<img src={url + '/uploads/default/calendar.png'} alt="" />
												</div>
												<div className="span">
													<span>{newsTime(item.createdAt)}</span>
												</div>
											</div>
											<div className="right">
												<div className="img">
													<img src={url + '/uploads/default/news-comment.png'} alt="" />
												</div>
												<div className="span">
													<span>{item.comment} Comments</span>
												</div>
											</div>
										</div>

										<div className="news_content">
											<h2>{item.title}</h2>
											<div className="description">
												{ReactHtmlParser(dynamicContent(item.description))}
											</div>
											<div className="read_more">
												<Link to={`/news/${item.url}`}>Read more <ArrowRightCircle /></Link>
											</div>
										</div>
									</div>
								</div>
							</>
						)
					})}
			</div>

		</>
	)
}
export default News
