import { useState, useEffect } from "react"
import { url } from "../../helpers/url"
const Share = ({ product }) => {
  useEffect(() => {
    window.addEventListener('click', (e) => {
      if (e.target.className !== 'share_img_url') {
        setShare(false)
      }
    })
  }, [])

  const [share, setShare] = useState(false)
  const shareHandler = () => {
    setShare(!share)
  }

  const getImgUrl = () => {
    if (product) {
      if (product.variation && JSON.parse(product.variation)[1]) {
        return JSON.parse(product.variation)[1].image.url
      } else if (product.image && JSON.parse(product.image).length > 0) {
        return JSON.parse(product.image)[0].url
      } else {
        return process.env.REACT_APP_PORUDCT_IMAGE
      }
    }
  }

  let productURL = product.type === 'business' ? url + '/strain/' + product.url : url + '/merchandise/' + product.url
  return (
    <div className="share">
      <div className="share_img">
        <img className="share_img_url" src={url + "/uploads/default/share.png"} alt="" onClick={shareHandler} />
      </div>
      {share &&
        <div className="social_icon">
          <a target="_blank" href={"https://www.facebook.com/sharer.php?u=" + productURL}>
            <div className="social_icon_box">
              <div className="img">
                <img src={url + "/uploads/default/facebook.png"} alt="" />
              </div>
              <div className="text">
                <span>Facebook</span>
              </div>
            </div>
          </a>

          <a target="_blank" href={"https://api.whatsapp.com/send?text=" + productURL}>
            <div className="social_icon_box">
              <div className="img">
                <img src={url + "/uploads/default/whatsapp.png"} alt="" />
              </div>
              <div className="text">
                <span>WhatsApp</span>
              </div>
            </div>
          </a>

          <a target="_blank" href={"https://www.linkedin.com/sharing/share-offsite/?url=" + productURL}>
            <div className="social_icon_box">
              <div className="img">
                <img src={url + "/uploads/default/linkedin.png"} alt="" />
              </div>
              <div className="text">
                <span>Linkedin</span>
              </div>
            </div>
          </a>
          <a target="_blank" href={"https://twitter.com/intent/tweet?text=" + product.title + "&url=" + productURL}>
            <div className="social_icon_box">
              <div className="img">
                <img src={url + "/uploads/default/twitter.png"} alt="" />
              </div>
              <div className="text">
                <span>Twitter</span>
              </div>
            </div>
          </a>

          <a target="_blank" href={"https://www.pinterest.com/pin/create/button/?media=" + getImgUrl() + "&url=" + productURL}>
            <div className="social_icon_box">
              <div className="img">
                <img src={url + "/uploads/default/pinterest.png"} alt="" />
              </div>
              <div className="text">
                <span>Pinterest</span>
              </div>
            </div>
          </a>
        </div >
      }
    </div >
  )
}

export default Share