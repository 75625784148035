import Fav from "../components/favourite/favourite"
import axios from "axios"
import { url } from "../helpers/url"
import { useState, useEffect } from "react"

const Favourite = () => {
	const [product, setProduct] = useState([])
	const [business, setBusiness] = useState([])
	const [merchandise, setMerchandise] = useState([])
	const [productCount, setProductCount] = useState([])
	const [businessCount, setBusinessCount] = useState([])
	const [merchandiseCount, setmerchandiseCount] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		axios.get(`${url}/favourite/api?productQuery=true&merchandiseQuery=true&businessQuery=true`).then(({ data }) => {
			if (data.success) {
				setProductCount(data.product.count)
				setBusinessCount(data.business.count)
				setmerchandiseCount(data.merchandise.count)
				setBusiness(data.business.rows)
				setProduct(data.product.rows)
				setMerchandise(data.merchandise.rows)
				setLoad(true)
			}
		})
	}, [])

	const [single, setSingle] = useState()

	const getProduct = (product) => {
		if (product.variation) {
			setSingle(product)
		}
	}

	return (
		<>
			<div className="container">
				<div className="favourite_title">
					<h1>Favorites</h1>
				</div>
			</div>

			{merchandise.length > 0 &&
				<Fav countItem={merchandiseCount} cartItem={getProduct} type="merchandise" title="Merchandise" data={merchandise} link={"/merchandise"} singleLink={"/merchandise/"} />
			}
			{product.length > 0 &&
				<Fav countItem={productCount} type="product" title="Products" data={product}
					link={"/strain"} singleLink={"/strain/"} />
			}
			{business.length > 0 &&
				<Fav countItem={businessCount} type="business" title="Business" data={business} link={"/dispensary"} singleLink={"/dispensary/"} />
			}
		</>
	)
}
export default Favourite
