import React, { useState } from 'react';
import { url } from '../helpers/url';
import axios from 'axios';
const Forget = (props) => {
    const [success, setSuccess] = useState({ success: false, email: true });
    const formSubmit = (e) => {
        e.preventDefault();
        axios.post(`${url}/forgot/api`, { email: e.target.email.value })
            .then(data => {
                const result = data.data;
                setSuccess({ ...success, ...result });
            })
    }

    return (<>
        <section className="login-page section-b-space">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h3 className="my-3 title_color">Reset Password</h3>
                        <div className="theme-card">
                            <form className="theme-form" onSubmit={formSubmit}>
                                {
                                    (!success.success) && <div className="form-group" >
                                        <label htmlFor="email">Email</label>
                                        <input name="email" id="email" type="email" className="form-control" placeholder="Enter your Email" required />
                                    </div>
                                }

                                {
                                    success.success && <div className="alert alert-success">
                                        Your reset password link has been sent to your email
                                    </div>
                                }
                                {
                                    (!success.success && !success.email) && < div className="alert alert-danger">
                                        This email is not exists on our records.
                                    </div>
                                }
                                {(!success.success) &&
                                    <button className="btn btn-solid" type="submit">Reset</button>
                                }
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>);
}
export default Forget;