import NewsCom from "../components/news/news"
import { useEffect, useState, useRef } from "react"
import { dynamicView } from "../helpers/dynamic_view"
import Breadcumbs from "../components/breadcumbs/breadcumbs"
import Pagination from "../components/pagination/pagination"
import { url } from "../helpers/url"
import SVG from '../components/dynamic-svg/dynamic-svg'
import { Link } from "react-router-dom"
import axios from "axios"
import { screen, toUrl } from "../helpers/functions"
const News = (props) => {
	useEffect(() => {
		dynamicView('page')
	}, [])

	const [rows, setRows] = useState([])
	const [count, setCount] = useState([])
	const [category, setCategory] = useState([])
	const [recentNews, setRecentNews] = useState([])
	const [recentComment, setRecentComment] = useState([])
	const [tags, setTags] = useState('')
	const [load, setLoad] = useState(false)
	useEffect(() => {
		axios.get(`${url}/news/additional/api?page=0`).then(({ data }) => {
			if (data.success) {
				setRows(data.news.rows)
				setCount(data.news.count)
				setRecentNews(data.recentNews)
				setCategory(data.category.rows)
				setRecentComment(data.recentComment)
				if (data.tags) {
					setTags(data.tags.tags)
				}
			}
			setLoad(true)
		})
	}, [])


	const [search, setSearch] = useState("")
	const searchHandler = (e) => {
		setSearch(e.target.value)
	}
	const filterBySearch = (e) => {
		e.preventDefault()
		if (search && search.trim()) {
			axios.post(url + "/search/news/api", { search: search.trim(), page: 0, limit: 20 }).then(({ data }) => {
				if (data.success) {
					setRows(data.rows)
					setCount(data.count)
				}
			})
		}
	}

	const sidebarRef = useRef(null)
	const [openSidebar, setOpenSidebar] = useState(false)
	useEffect(() => {
		const click = (e) => {
			if (sidebarRef && sidebarRef.current && !sidebarRef.current.contains(e.target)) {
				setOpenSidebar(false)
			}
		};
		window.addEventListener("click", click);
		return () => window.removeEventListener("click", click);
	}, [])

	const sidebarHandler = (redirect = true) => {
		if (screen() <= 800) {
			setOpenSidebar(!openSidebar)
		}
		if (redirect) {
			window.location.href = "/business#sorting"
		}
	}

	const clearFilter = () => {
		axios.get(`${url}/news/additional?page=0`).then(({ data }) => {
			if (data.success) {
				// setBus(data.rows)
				// setCount(data.count)
				// setLoad(true)
				// sidebarHandler()
			}
		})
	}

	const [next, setNext] = useState(true)
	function getPaginationId(id) {
		const length = 20
		const page = id > 0 ? (id - 1) * length : 0
		axios.get(`${url}/news/additional/api?page=${page}`).then(({ data }) => {
			if ((length !== data.news.rows.length) || data.news.count.length === id) {
				setNext(false)
			} else {
				setNext(true)
			}
			setRows(data.news.rows)
			setCount(data.news.count)
			setLoad(true)
		})
	}


	return (
		<>
			<section className="mercahndise_main_section">
				<Breadcumbs title={"News"} link={"/news"} linktext={"News"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
				<div className="container">
					<div className="merchandise_main">
						<div className="merchandise_left">



							<div className="container news_container">
								<div className="sidebar_ref" ref={sidebarRef}>
									{(screen() <= 800) &&
										<div className="filter">
											<span onClick={() => sidebarHandler(false)}>Filter</span> <span><i className="fa fa-angle-down"></i></span>
										</div>
									}
									{(screen() <= 800 && openSidebar) &&
										<div className="merchandise_right">
											<div className="search_heading">
												<SVG />
												<span>Search by Posts</span>
											</div>
											<div className="search_input">
												<form class="merchandise_search_form" onSubmit={filterBySearch}>
													<input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" />
													<button className="" type="submit" class="subscribe_btn">Search</button>
												</form>
											</div>
											<div className="search_heading">
												<span>Categories List</span>
											</div>
											<div className="category_list_main">
												{category.map(item => (
													<div className="category_list">
														<div className="list">
															<div className="img">
																<img src={url + '/uploads/default/category-list.png'} />
															</div>
															<div className="link">
																<Link to={toUrl(item.category) + "/news"}>{item.category}</Link>
															</div>
														</div>
													</div>
												))}
											</div>



											<div className="search_heading">
												<SVG />
												<span>Recent Post</span>
											</div>
											<div className="recent_post_main">
												{recentNews.map(item => (
													<div className="recent_post">
														<ul>
															<li>
																<div className="heading">
																	<Link to={"/news/" + item.url}><p>{item.title}</p></Link>
																</div>
															</li>
														</ul>

													</div>
												))}
											</div>


											<div className="search_heading">
												<SVG />
												<span>Tags</span>
											</div>
											{
												tags &&
												<div className="serach_weight">
													{tags.split(',').map(item => (
														<Link to={item.trim() + "/news?mode=tag"} className="badge rounded-pill badge-lg ">{item}</Link>
													))}
												</div>
											}
											{recentComment.length > 0 && <>

												<div className="search_heading">
													<SVG />
													<span>Recent Comments</span>
												</div>

												<div className="recent_comment_main">
													{recentComment.map(item => (
														<div className="recent_comment">
															<div className="left">
																<div className="img">
																	<img src={url + '/uploads/default/news-comment.png'} alt="" />
																</div>
															</div>
															<div className="right">
																<div className="content">
																	<p>{item.comment}</p>
																	<Link to={"/news/" + item['news.url']} className="view_news">View News</Link>
																</div>
															</div>
														</div>
													))}
												</div>
											</>}


										</div>
									}
								</div>

								{(rows.length === 0 && load) &&
									<div className="alert alert-danger my-3">
										<p className="title_color">There are no news</p>
									</div>
								}
								<NewsCom rows={rows} count={count} />
								{count.length > 0 && <Pagination isNext={next} product={rows.length} count={count} clickId={getPaginationId} />}
							</div>

						</div>
						{screen() > 800 &&
							<div className="merchandise_right">
								<div className="search_heading">
									<SVG />
									<span>Search by Posts</span>
								</div>
								<div className="search_input">
									<form class="merchandise_search_form" onSubmit={filterBySearch}>
										<input onChange={searchHandler} name="search" placeholder="Search" type="text" class="" />
										<button className="" type="submit" class="subscribe_btn">Search</button>
									</form>
								</div>
								<div className="search_heading">
									<span>Categories List</span>
								</div>
								<div className="category_list_main">
									{category.map(item => (
										<div className="category_list">
											<div className="list">
												<div className="img">
													<img src={url + '/uploads/default/category-list.png'} />
												</div>
												<div className="link">
													<Link to={toUrl(item.category) + "/news"}>{item.category}</Link>
												</div>
											</div>
										</div>
									))}
								</div>



								<div className="search_heading">
									<SVG />
									<span>Recent Post</span>
								</div>
								<div className="recent_post_main">
									{recentNews.map(item => (
										<div className="recent_post">
											<ul>
												<li>
													<Link to={"/news/" + item.url}><p>{item.title}</p></Link>
												</li>
											</ul>

										</div>
									))}
								</div>


								<div className="search_heading">
									<SVG />
									<span>Tags</span>
								</div>
								{
									tags &&
									<div className="serach_weight">
										{tags.split(',').map(item => (
											<Link to={item.trim() + "/news?mode=tag"} className="badge rounded-pill badge-lg ">{item}</Link>
										))}
									</div>
								}
								{recentComment.length > 0 && <>

									<div className="search_heading">
										<SVG />
										<span>Recent Comments</span>
									</div>

									<div className="recent_comment_main">
										{recentComment.map(item => (
											<div className="recent_comment">
												<div className="left">
													<div className="img">
														<img src={url + '/uploads/default/news-comment.png'} alt="" />
													</div>
												</div>
												<div className="right">
													<div className="content">
														<p>{item.comment}</p>
														<Link to={"/news/" + item['news.url']} className="view_news">View News</Link>
													</div>
												</div>
											</div>
										))}
									</div>
								</>}

							</div>
						}
					</div>
				</div>
			</section>
		</>

	)
}
export default News
