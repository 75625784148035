import React, { useEffect, useState } from "react"
import Product from '../components/single-product/single-product';
import ReviewContext from "../helpers/review_context";
import { url } from '../helpers/url';
import { useParams } from "react-router-dom"
import { Container } from "react-bootstrap"
import axios from "axios";
import StarItem from "../components/star/star"
import Products from "../components/products/products"
import { dynamicView } from "../helpers/dynamic_view";
import Breadcumbs from "../components/breadcumbs/breadcumbs"
import HtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";

const SingleProduct = (props) => {
    const { id } = useParams();
    const [product, setProduct] = useState()
    const [load, setLoad] = useState(false)
    const [relate, setRelate] = useState({ rows: [], count: [] })
    const [currentStar, setCurrentStar] = useState({})
    const [star, setStar] = useState({ rows: [], count: [] })
    const [siteURL, setSiteURL] = useState('')
    const [hash, setHash] = useState()
    useEffect(() => {
        axios.post(`${url}/products/single/api`, { url: id }).then(({ data }) => {
            if (data.success && data.singleProduct) {
                dynamicView(data.singleProduct.type === 'merchandise' ? 'merchandise' : 'product', data.singleProduct.id, data.singleProduct.businessId)
                setProduct(data.singleProduct)
                setRelate(data.relateProduct)
                setCurrentStar(data.userReview)
                setStar(data.star)
                setSiteURL(window.location.href)
                setLoad(true)
                if (window.location.hash) {
                    starClickHandler()
                    setHash(true)
                }
            }

        })

    }, [id])


    const starHandler = (item) => {
        setProduct({ ...product, ...item })
    }

    const [curTab, setCurTab] = useState(0)
    const tabHandler = (tab) => {
        setCurTab(tab)
    }

    const starClickHandler = () => {
        setCurTab(1)
        if (!window.location.hash) {
            window.location.href = siteURL + "#starbox"
            setHash(true)
        }
        if (window.location.hash) {
            window.location.href = window.location.href
        }
    }



    useEffect(() => {
        if (window.location.hash && document.querySelector(window.location.hash)) {
            const id = document.querySelector(window.location.hash)
            window.scrollTo(0, id.offsetTop)
        }
    }, [hash])

    const getProduct = (product) => { }

    return (
        <ReviewContext>
            <>
                {(load && product) && <>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{product.title}</title>
                        <link rel="canonical" href={product.url} />
                        <meta name="description" content={product.seoDes} />
                    </Helmet>
                    <Breadcumbs title={product.type === 'merchandise' ? "Product Details" : "Product Review"} link={product.url} linktext={product.type === 'merchandise' ? "Product Details" : "Product Review"} description={"Nibh tellus molestie nunc non blandit. Mi tempus imperdiet nulla malesuada pellentesque elit"} />
                    <Product data={product} starClick={starClickHandler} />
                    {/* Review Handler */}

                    <div className="container">
                        <div className="product_tab">
                            <div className="tab_item" onClick={() => tabHandler(0)}>
                                <h2 className={curTab === 0 ? 'active' : ''}>Description</h2>
                            </div>
                            {product.type === 'business' &&
                                <div className="tab_item" onClick={() => tabHandler(1)}>
                                    <h2 className={curTab === 1 ? 'active' : ''}>{`Reviews ${product.total > 0 ? '(' + product.total + ')' : ''}`}</h2>
                                </div>
                            }
                            <div className="tab_item" onClick={() => tabHandler(2)}>
                                <h2 className={curTab === 2 ? 'active' : ''}>Additional Information</h2>
                            </div>
                        </div>

                        <div className="single_pro_line"></div>
                        {curTab === 0 &&
                            <div className="single_product_description">
                                {HtmlParser(product.description)}
                            </div>
                        }
                        {curTab === 2 &&
                            <div className="single_additional_information">
                                {HtmlParser(product.additional)}
                            </div>
                        }
                    </div>

                    {(product.type === "business" && curTab === 1) && (
                        <Container>
                            <div id="starbox" className="star_wrapper">
                                <StarItem click={starHandler} reviews={star.rows} currentReviews={currentStar} single={product} type="product" count={star.count} />
                            </div>
                        </Container>
                    )}



                    {/* Relaet  Product */}
                    {relate.rows.length > 0 && (
                        <Container>
                            <Products css={"single_related_product"} data={relate.rows} page={relate.count} title="Related Products" cartItem={getProduct} productType={product.type === "merchandise" ? "merchandise" : "business"} />
                        </Container>
                    )}
                </>
                }
            </>
        </ReviewContext>
    );
}

export default SingleProduct;
