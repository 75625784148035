import News from '../components/news/news'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { url } from '../helpers/url'
import { useParams, useLocation } from 'react-router-dom'
import { toText } from '../helpers/functions'
const NewsCat = () => {
  const [rows, setRows] = useState([])
  const [count, setCount] = useState([])

  const location = useLocation()
  const { id } = useParams()
  const [load, setLoad] = useState(false)
  useEffect(() => {
    const mode = location.search === '?mode=tag' ? 'tag' : 'category'
    axios.get(`${url}/news/api?page=0&mode=${mode}&search=${toText(id)}`).then(({ data }) => {
      if (data.success) {
        setRows(data.rows)
        setCount(data.count)
        setLoad(true)
      }
    })
  }, [])
  return (
    <div className="category_news">
      <div className="container">
        {(rows.length > 0 && load) &&
          <>
            <h1 className=''>News by {toText(id)}</h1>
            <News rows={rows} count={count} />
          </>
        }
        {(load && rows.length === 0) &&
          <>
            <h1 className='notfound'>News by {toText(id)}</h1>
          </>
        }
      </div>
    </div>
  )
}
export default NewsCat