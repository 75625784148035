import { useEffect } from "react"

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem('letsmango.com')
    window.location.href = '/login'
  }, [])
  return (
    <>
    </>
  )
}
export default Logout