import React, { useEffect, useState } from "react"
import Products from "../components/products/products"
import { Container } from "react-bootstrap"
import { url } from "../helpers/url"
import axios from "axios"
const Discover = (props) => {
	const [feature, setFeature] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		const item = localStorage.getItem("products")
		if (item) {
			axios
				.post(`${url}/discover/api`, {
					discover: JSON.parse(item),
				})
				.then(({ data }) => {
					if (data.success) {
						setFeature(data.products)
						setLoad(true)
					}
				})
		} else {
			setLoad(true)
		}
	}, [])

	return (
		<Container className="shop_container">
			{feature.length > 0 && load && <Products data={feature} viewItem="true" menu="false" title="Recently Viewd" productType="product" />}
			{feature.length === 0 && load && <div className="alert alert-danger my-3">
				<p className="title_color">This is no product recently viewd</p>
			</div>}
		</Container>
	)
}
export default Discover
