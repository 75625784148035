import React, { useState, useEffect, useContext } from "react"
import axios from "axios"
import FeedPost from "../../components/social/post/admin_post"
import { url } from "../../helpers/url"
import { Context } from "../../helpers/context"
const InfluencerFeed = (props) => {

  const [post, setPost] = useState([])
  const [user, setUser] = useState()
  const [load, setLoad] = useState(false)
  const [conUser, setConUser] = useContext(Context)
  useEffect(() => {
    axios.get(`${url}/influencer/feed/api?type=feed&page=0`).then(({ data }) => {
      if (data.success) {
        setPost(data.rows)
        setUser(data.user)
        setLoad(true)
      }
    })
  }, [])


  return (
    <>
      <FeedPost allowPosting={conUser.type === 'influencer'} route={"influencer"} type="feed" data={post} isUser={user} isLoad={load} />
    </>
  )
}
export default InfluencerFeed
