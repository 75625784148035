import React, { useState } from "react"
import { Redirect } from "react-router-dom"
import { url } from "../helpers/url"
import axios from "axios"
const Forget = (props) => {
	const [success, setSuccess] = useState({ success: false, password: true, expire: false, reset: false })

	const formSubmit = (e) => {
		e.preventDefault()
		const query = props.location.search
		axios.post(`${url}/reset/api${query}`, { passA: e.target.passA.value, passB: e.target.passB.value }).then((data) => {
			const result = data.data
			setSuccess({ ...success, ...result })
		})
	}
	const [user, setUser] = useState({ passA: "", passB: "" })

	const onType = (e) => {
		const updateType = { [e.target.name]: e.target.value }
		setUser({ ...user, ...updateType })
	}

	const [passeye, setPasseye] = useState(false)
	const [passeye2, setPasseye2] = useState(false)

	return (
		<>
			{success.reset && <Redirect to="/login" />}
			<section className="login-page section-b-space">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h3 className="my-3 title_color">Update Password</h3>
							<div className="theme-card">
								<form autoComplete="off" className="theme-form" onSubmit={formSubmit}>
									{!success.expire && (
										<>
											{" "}
											<div className="form-group">
												<label htmlFor="password">Password</label>
												<div className="eye_pass_input ">
													<i className={passeye ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye(!passeye)}></i>
													<input name="passA" id="email" type={passeye ? "text" : "password"} className="form-control" placeholder="Enter your Password" onChange={onType} required />
												</div>
											</div>
											<div className="form-group">
												<label htmlFor="password">Confirm Password</label>
												<div className="eye_pass_input">
													<i className={passeye2 ? "fa fa-eye" : "fa fa-eye-slash"} onClick={() => setPasseye2(!passeye2)}></i>
													<input name="passB" id="email" type={passeye2 ? "text" : "password"} className="form-control" placeholder="Enter your Confirm Password" onChange={onType} required />
												</div>
											</div>
											{user.passA !== user.passB && <div className="alert alert-danger">Confirm Password didn't match yet.</div>}
										</>
									)}

									{success.expire && <div className="alert alert-success">Your rest password link has been expired you need to reset it again</div>}

									<button disabled={user.passA !== user.passB ? true : false} className="btn btn-solid" type="submit">
										Update Password
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export default Forget
