export const time = (before, content = "") => {
	if (before) {
		const past = new Date(before)
		const today = new Date()
		const time = today - past
		const minutes = 1000 * 59
		const hour = 1000 * 60 * 60
		const day = 1000 * 60 * 60 * 24

		if (time < minutes) {
			return parseInt(time / 1000) + " seconds " + content
		} else if (time > minutes && time < hour) {
			return parseInt(time / 60000) + " minutes " + content
		} else if (time > minutes && time < day) {
			return parseInt(time / hour) + " hours " + content
		} else {
			return parseInt(time / day) + " day " + content
		}
	}
}
export const feedTime = (before, content = "") => {
	if (before) {
		const past = new Date(before)
		const today = new Date()
		const time = today - past
		const minutes = 1000 * 59
		const hour = 1000 * 60 * 60
		const day = 1000 * 60 * 60 * 24

		if (time < minutes) {
			const final = parseInt(time / 1000)
			const isSec = final > 1 ? " seconds " : " second "
			return final + isSec + content
		} else if (time > minutes && time < hour) {
			const final = parseInt(time / 60000)
			const isMin = final > 1 ? " minutes " : " minute "
			return final + isMin + content
		} else if (time > minutes && time < day) {
			let final = parseInt(time / hour)
			let isHour = final > 1 ? " hours " : " hour "
			return final + isHour + content
		} else {
			return past.getMonth() + 1 + "-" + past.getDate() + "-" + past.getFullYear()
		}
	}
}

export const getChatListDate = (item) => {
	const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
	const date = new Date(item)
	return months[date.getMonth()] + " " + date.getDate()
}

export const getMsgDate = (item) => {
	const date = new Date(item).toLocaleString([], { hour12: true, weekday: "short", minute: "2-digit", hour: "numeric" })
	return date
}

export const getTime = (date) => {
	return new Date(date).getTime()
}
export const getDate = () => {
	return new Date()
}

export const getToday = (fullDate) => {
	const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
	const date = new Date(fullDate)
	return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
}

export const newsTime = (item) => {
	return new Date(item).toLocaleString([], { month: "short", day: "2-digit", year: "numeric" })
}
