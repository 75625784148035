import React, { useEffect, useState } from "react"
import Products from "../products/products"
import { Container } from "react-bootstrap"
import { url } from "../../helpers/url"
import { toText } from "../../helpers/functions"
import axios from "axios"
import { useParams } from "react-router-dom"
import Pagination from "../../components/pagination/pagination"
const Shop = ({ type }) => {
  const [product, setProduct] = useState([])
  const [count, setCount] = useState([])
  const [load, setLoad] = useState(false)
  const { id } = useParams()
  const [category, setCategory] = useState(id)

  useEffect(() => {
    axios.get(`${url}/products/api?page=0&category=${toText(id)}&type=category&mode=${type}`).then((data) => {
      const result = data.data
      if (result.success) {
        setProduct(result.rows)
        setCount(result.count)
        setLoad(true)
      }
    })
  }, [])

  const [next, setNext] = useState(true)
  function getPaginationId(id) {
    const length = 21
    const page = id > 0 ? (id - 1) * length : 0
    axios.get(`${url}/products/api?page=${page}&category=${toText(category)}&type=category&mode=${type}`).then(({ data }) => {
      if ((length !== data.rows.length) || count.length === id) {
        setNext(false)
      } else {
        setNext(true)
      }
      setProduct(data.rows)
      setCount(data.count)
      setLoad(true)
    })
  }


  return (
    <Container>
      {product.length > 0 && load && <Products productType={type} data={product} title={"Products By " + toText(category)} />}

      {product.length === 0 && load && <div className="alert alert-danger my-3">
        <p className="title_color">This item is not found</p>
      </div>
      }
      {count.length > 0 && <Pagination product={product.length} count={count} clickId={getPaginationId} isNext={next} />}
    </Container >
  )
}
export default Shop
