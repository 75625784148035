import { useEffect, useState } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import { useLocation, Link } from 'react-router-dom'
const Confirmation = () => {
  const [status, setStatus] = useState({})
  const [load, setLoad] = useState(false)
  const query = useLocation()
  useEffect(() => {
    axios.get(`${url}/api/email${query.search}`)
      .then(({ data }) => {
        setStatus(data)
        setLoad(true)
      })
  }, [])
  return (
    <section className="login-page section-b-space">
      <div className="container">
        <div
          className="row boxshadow"
        >
          <div className="col">
            {(status.type === 'customer' && status.success) &&
              <div className="alert alert-success">Your email has been confirmed successfully. <p>Click here to <Link to="/login">Login</Link></p>
              </div>
            }
            {(status.type === 'business' && status.success) &&
              <div className="alert alert-success">Your email has been confirmed successfully. <p>Once the admin will aprrove, you will be notified.</p>
              </div>
            }
            {(status.type === 'expired' && !status.success) &&
              <div className="alert alert-success">Your confirmation link is expired.
              </div>
            }
            {(status.type === 'invalid' && !status.success) &&
              <div className="alert alert-success">Your confirmation link is invalid.
              </div>
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default Confirmation