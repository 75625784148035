import React from 'react';
const NotFound = () => {
    const style = {
        fontSize: '160px',
        color: '#ccc',
        fontFamily: 'sans-serif',
        fontWeight: 'bold',
    }

    const style2 = {
        fontSize: '40px',
        fontWeight: 'bold',
        color: '#ccc',
        fontFamily: 'sans-serif',
    }
    const style3 = {
        marginBottom: '50px'
    }
    return (
        <div className="text-center" >
            <h1 className="mt-2" style={style}>404</h1>
            <h2 className="" style={style2}>Page Not Found</h2>
            <a style={style3} href="/" className="mt-2 btn btn-solid text-center">Back To Home</a>
        </div>
    )
}
export default NotFound;