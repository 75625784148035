import React, { useState, useEffect } from "react"
import axios from "axios"
import { url } from "../../helpers/url"
import Friend from "../../components/social/friend/friend"

const FriendRequest = (props) => {
	const [friend, setFriend] = useState([])
	const [count, setCount] = useState([])
	const [load, setLoad] = useState(false)
	useEffect(() => {
		axios.get(`${url}/social/request?type=pending&page=0`).then((data) => {
			if (data.data.success) {
				setFriend(data.data.rows)
				setCount(data.data.count)
				setLoad(true)
			}
		})
	}, [])

	return (
		<>
			<div className="container friend_container">
				<Friend more={"social/request?type=pending"} type="request" data={friend} count={count} load={load} sub="Most Recently" title="Friend Requests" message="You don't have any friends" link="/social/find-friend" button="Find Friend" />
			</div>
		</>
	)
}
export default FriendRequest
