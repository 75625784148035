import axios from "axios"
import { useState, useEffect, useRef } from "react"
import { url } from "../../../helpers/url"
import { license_type } from "../../../helpers/business-type"
import './claim_popup.css'
import Autocomplete from "react-google-autocomplete"
const key = process.env.REACT_APP_MAP_KEY
const PopUpClaim = ({ single, parentOpen }) => {

  const [success, setSuccess] = useState()
  const ref = useRef()


  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!ref.current.contains(e.target)) {
        parentOpen(false)
      }
    }
    document.addEventListener('click', handleOutsideClick, true)
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };

  }, [])

  const handleOutsideClick = () => {
    parentOpen(false)
  }


  const [term, setTerm] = useState(false)
  const onCheck = () => {
    setTerm(!term)
  }
  const [value, setValue] = useState({
    fname: "",
    lname: "",
    email: "",
    address: "",
    phone: "",
    business_type: "",
    business_name: "",
    city: "",
    state: "",
    zip: "",
  })

  const onType = (id, e) => {
    let updateState = {
      ...value,
      [id]: e.target.value,
    }
    setValue(updateState)
  }



  const [license, setLicense] = useState([1])
  const [count, setCount] = useState(1)
  const multiLicense = () => {
    setCount(count + 1)
    setLicense([...license, count + 1])
  }
  const [licenseObj, setLicenseObj] = useState({})
  const licenseChange = (e, id) => {
    let updateState = {
      ...licenseObj,
      [id]: {
        ...licenseObj[id],
        [e.target.name]: e.target.value,
      }
    }
    setLicenseObj(updateState)
  }

  const closeChildBox = (item) => {
    const filter = license.filter(i => i !== item)
    const licenseObjCopy = { ...licenseObj }
    delete licenseObjCopy[item]
    setLicense(filter)
    setLicenseObj(licenseObjCopy)
  }




  const addDiv = () => {
    var addressInputElement = document.querySelector(".user_address_row")
    var pacContainer = document.querySelector(".pac-container")
    if (addressInputElement && pacContainer) {
      addressInputElement.appendChild(pacContainer)
    }
  }


  const [city, setCity] = useState()
  const [zip, setZip] = useState()
  const [state, setState] = useState()


  const [currentPlace, setCurrentPlace] = useState("")
  const addressChanger = (e) => {
    const ad = e.target.value
    setCurrentPlace(ad)
  }

  const placeController = (place) => {
    let address
    let zip = "";
    let city;
    let state;
    let number;

    place.address_components.map(item => {
      // console.log("item")
      // console.log(item)
      if (item.types[0] === "route") address = item.long_name
      if (item.types[0] === "street_number") number = item.long_name
      if (item.types[0] === "postal_code") zip = item.short_name
      if (item.types[0] === "administrative_area_level_3" || item.types[0] === "locality") city = item.long_name
      if (item.types[0] === "administrative_area_level_1") state = item.short_name
    })

    if (number) {
      address = number + " " + address
    }

    setCurrentPlace(address)
    setZip(zip)
    setCity(city)
    setState(state)

  }



  const [emailError, setEmailError] = useState(false)

  const formValue = (e) => {
    e.preventDefault()
    if (single && single.id) {
      const business_url = window.location.href
      const updateValue = { ...value, city: city, zip: zip, state: state, address: currentPlace, businessId: single.id, license: JSON.stringify(licenseObj), business_url: business_url }
      if (term) {
        axios.post(`${url}/business/claim/api`, { ...updateValue, title: single.title, category: single.category }).then(({ data }) => {
          if (data.success) {
            setEmailError(false)
            setSuccess('yes')
          }
          else if (data.email) {
            setEmailError(data.message)
            setSuccess('')
          }
          else {
            setSuccess('wrong')
          }
        })
      }
    }

  }

  return (
    <section className={"login-page section-b-space "}>
      <div className="container registration_container">

        <div className={"row registration boxshadow business claim_section"} ref={ref}>

          <div className={"scecond col-lg-12"}>

            <div className="theme-card">
              <i className="fa fa-close main_close" onClick={handleOutsideClick}></i>
              <h3 className="my-3 title_color">Business Claim request</h3>


              <form className="" method="post" onSubmit={formValue}>
                {success === 'yes' &&
                  <div className="alert alert-success">
                    Thanks for your request.
                    It will be reviewed shortly
                  </div>
                }
                {success === 'wrong' &&
                  <div className="alert alert-danger">
                    Something went wrong. Contact with admin
                  </div>
                }
                {emailError &&
                  <div className="alert alert-danger">
                    {emailError}
                  </div>
                }
                {success !== 'yes' && <>
                  <div className="user_details">
                    <h3 className="form_title">Contact</h3>

                    <div className="input_group">
                      <label htmlFor="fname">First Name</label>
                      <input required name="fname" onChange={(e) => onType("fname", e)} type="text" className="form-control mb-3" />
                    </div>

                    <div className="input_group">
                      <label htmlFor="lname">Last Name</label>
                      <input required name="lname" onChange={(e) => onType("lname", e)} type="text" className="form-control mb-3" />
                    </div>

                    <div className="input_group">
                      <label htmlFor="phone">Phone</label>
                      <input required name="phone" onChange={(e) => onType("phone", e)} type="text" className="form-control mb-3" />
                    </div>

                    <div className="input_group">
                      <label htmlFor="email">Email</label>
                      <input required name="email" onChange={(e) => onType("email", e)} type="email" className="form-control mb-3" />
                      {value.emailEx ? <p className="alert alert-danger">This email already exist on our records</p> : ""}
                    </div>
                  </div>
                  <div className="user_business">
                    <h3 className="form_title">Business</h3>


                    <div className="input_group">
                      <label htmlFor="address">Address</label>
                      <Autocomplete
                        onFocus={addDiv}
                        className="form-control"
                        options={{
                          // types: ['geocode',],
                          // (regions)
                          // 'address'
                          types: ["address"],
                          componentRestrictions: { country: "us" },
                        }}
                        apiKey={key}
                        onPlaceSelected={placeController}
                        onChange={addressChanger}
                        value={currentPlace}
                        placeholder="Address"
                      />
                    </div>

                    <div className="input_group">
                      <label htmlFor="city">City</label>
                      <input required name="city" onChange={(e) => setCity(e.target.value)} type="text" className="form-control mb-3" value={city} />
                    </div>

                    <div className="input_group">
                      <label htmlFor="state">State/ Province</label>
                      <input required name="state" onChange={(e) => setState(e.target.value)} type="text" className="form-control mb-3" value={state} />
                    </div>

                    <div className="input_group">
                      <label htmlFor="zip">Zip Code</label>
                      <input required name="zip" onChange={(e) => setZip(e.target.value)} type="text" className="form-control mb-3" value={zip} />
                    </div>

                  </div>

                  <div className="user_license">
                    <h3 className="form_title">License</h3>

                    {
                      license.map(item => {
                        return (
                          <div className={item > 1 ? "license_fileds more" : "license_fileds"}>
                            {item > 1 &&
                              <div className="close_child_main">
                                <i onClick={() => closeChildBox(item)} className="fa fa-close close_child"></i>
                              </div>
                            }

                            <div className="input_group">
                              <label htmlFor="license">License number</label>
                              <input required name="license" onChange={(e) => licenseChange(e, item)} type="text" className="form-control mb-3" />
                            </div>
                            <div className="input_group">
                              <label htmlFor="license_type">License type</label>
                              <select required className="form-control mb-3" name="license_type" onChange={(e) => licenseChange(e, item)} id="">
                                <option value="">Select a license type</option>
                                {license_type.map(item => <option value={item}>{item}</option>)}
                              </select>
                            </div>
                            <div className="input_group">
                              <label htmlFor="expiration">Expiration</label>
                              <input required name="expiration" onChange={(e) => licenseChange(e, item)} type="date" className="form-control mb-3" />
                            </div>

                          </div>
                        )
                      })
                    }


                    <div className="license_plus">
                      <p className="" onClick={multiLicense}>Add More License <i className="fa fa-plus"></i></p>
                    </div>


                  </div>


                  <div className="form-group terms">
                    <div className="terms_content">
                      <h4>Terms and Conditions</h4>
                      <ul>
                        <li>You consent to Let's Mango’ collection of your name, phone number, email address, business name, business address, and other business information for the limited purposes of verifying and evaluating your application to add your business to the Let's Mango platform, for any purposes related to your commercial use of our products or services and for our representatives to contact you with respect thereto.</li>
                        <li>You acknowledge that you are not a minor as legally defined in your municipality, state, province, territory, or country.</li>
                        <li>You agree that Let's Mango may share your personal data with third parties for the limited purpose of providing you with the Authorized Communications.</li>
                        <li>You understand that you will have the opportunity to unsubscribe from the Authorized Communications and request the deletion of your personal data from Let's Mango’ systems at any time.</li>
                        <li>You acknowledge that you have read and agree to be bound by the Let's Mango Terms of Use and Privacy Policy.</li>
                        <li>You acknowledge that you have read and agree to be bound by the Let's Mango Commercial Terms.</li>
                      </ul>
                      <p>


                      </p>
                    </div>
                    <div className="check_all">
                      <div className="check">
                        <input id="check_term" type="checkbox" onChange={onCheck} checked={term} />
                      </div>
                      <div className="text">
                        <label htmlFor="check_term">
                          <a>I have read and agree to the above terms and conditions.*</a>
                        </label>
                      </div>
                    </div>
                  </div>
                  <button className={term ? "btn input_button" : "disabled btn input_button"} style={{ cursor: term ? 'pointer' : 'not-allowed' }} type="submit">
                    Submit
                  </button>
                </>}
              </form>

            </div>
          </div>
        </div>
      </div>
    </section >
  )
}
export default PopUpClaim