import React, { useEffect, useState, useContext } from "react"
import { Redirect } from "react-router-dom"
import { url, } from "../../helpers/url"
import { math } from "../../helpers/functions"
import { screen } from "../../helpers/functions"
import { Context } from "../../helpers/context"
import { NavLink } from "react-router-dom"
import axios from "axios"
import "./orders.css"
import Pagination from "../../components/pagination/pagination"
import { Container } from "react-bootstrap"

const Orders = (props) => {
	const [order, setOrder] = useState([])
	const [count, setCount] = useState([])
	const [global, setGlobal] = useContext(Context)
	const [load, setLoad] = useState(false)

	useEffect(() => {
		axios.get(`${url}/orders/api?page=0`).then((data) => {
			const result = data.data
			if (result.rows.length > 0) {
				setOrder(result.rows)
			}
			if (result.count.length > 0) {
				setCount(result.count)
			}
			setLoad(true)
		})
	}, [])


	const [next, setNext] = useState(true)
	function getPaginationId(id) {
		const length = 20
		const page = id > 0 ? (id - 1) * length : 0
		axios.get(`${url}/orders/api?page=${page}`).then(({ data }) => {
			if ((length !== data.rows.length) || count.length === id) {
				setNext(false)
			} else {
				setNext(true)
			}
			setOrder(data.rows)
			setCount(data.count)
		})
	}

	const showInvoice = (order) => {
		axios.post(`${url}/invoice/api`, order).then((data) => {
			const result = data.data
			if (result.success && typeof window !== "undefined") {
				window.open(result.url)
			}
		})
	}
	const cancelRequest = (id, arrayId) => {
		axios.post(`${url}/order/cancel/api`, { orderId: id }).then(({ data }) => {
			let newOrder = [...order]
			if (data.success) {
				newOrder[arrayId].status = 'Cancel Request Sent'
				setOrder(newOrder)
			} else {
				newOrder[arrayId].error = true
			}
		})
	}

	return (
		<Container className="order_container">
			{!global.type && global.load && <Redirect to="/merchandise" />}
			{order.length > 0 && (
				<div className="order">
					<div className="order_title">
						<h3 className="font24 half_black">Order History</h3>
					</div>
					{screen() < 835
						? order.map((item, i) => {
							return (
								<div className="order_main">
									<div className="order_header">
										<p className="orderid">
											<span className="order_text"> Order#:</span> <span>{item.orderId}</span>
										</p>
										{(item.status === 'Pending') &&
											<p onClick={() => cancelRequest(item.orderId, i)} className="cancel">Cancel Order</p>
										}
									</div>
									<div className="line mobile"></div>

									<div className="order_middle first">
										<div className="date">
											<p>Date</p>
											<p>{item["today"]}</p>
										</div>

										<div className="amount">
											<p>Amount</p>
											<p>${math(item["total"]).toFixed(2)}</p>
										</div>
										<div className="address">
											<p>Address</p>
											<p>{`${item.address} ${item.address2} ${item.city} ${item.zip}  ${item.state}`}</p>
										</div>
										<div className="payment">
											<p>Payment Method</p>
											<p>{item["type"]}</p>
										</div>

										<div className="status">
											<p>Status</p>
											<p className={item["status"]}>{item["status"]}</p>
										</div>

										<div className="status">
											<p>View Order</p>
											<a onClick={() => showInvoice(item)} className="order_history_btn">View Order</a>
										</div>
									</div>
								</div>
							)
						})
						: order.map((item, i) => {
							return (
								<div className="order_main">
									<div className="order_header">
										<p className="orderid">
											<span className="order_text">Order#:</span> <span>{item.orderId}</span>
										</p>
										{(item.status === 'Pending') &&
											<p className="cancel" onClick={() => cancelRequest(item.orderId, i)} >Cancel Order</p>
										}

									</div>
									<div className="order_middle first">
										<div className="date">
											<p>Date</p>
										</div>
										<div className="amount">
											<p>Amount</p>
										</div>
										<div className="address">
											<p>Address</p>
										</div>
										<div className="payment">
											<p>Payment Method</p>
										</div>

										<div className="status">
											<p>Status</p>
										</div>
										<div className="status">
											<p>View</p>
										</div>
									</div>
									<div className="line desktop"></div>
									<div className="order_middle">
										<div className="date">
											<p>{item["today"]}</p>
										</div>
										<div className="amount">
											<p>${math(item["total"]).toFixed(2)}</p>
										</div>
										<div className="address">
											<p>{`${item.address} ${item.address2} ${item.city} ${item.zip}  ${item.state}`}</p>
										</div>
										<div className="payment">
											<p>{item["type"]}</p>
										</div>

										<div className="status">
											<p className={item["status"]}>{item["status"]}</p>
										</div>

										<div className="status">
											<a onClick={() => showInvoice(item)} className="order_history_btn">View Order</a>
										</div>
									</div>
								</div>
							)
						})}
				</div>
			)}
			{order.length === 0 && load && (
				<>
					<div className="order_header m_top m_bottom">
						<h2 className="text-center font24 half_black m-auto d-block">You didn't order anything</h2>
					</div>
					<div className="empty_cart_wrapper">
						<div className="empty_cart">
							<img src={url + "/uploads/default/empty_cart.png"} alt="" />
						</div>
						<div className="buy">
							<NavLink to="/merchandise" className="shopping_btn">
								Continue Shopping
							</NavLink>
						</div>
					</div>
				</>
			)}
			{count.length > 0 && <Pagination product={order.length} count={count} clickId={getPaginationId} isNext={next} />}

		</Container>
	)
}
export default Orders
