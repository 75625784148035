import React from "react"
import RegistrationCom from "../components/registration/registration"
const Registration = (props) => {
	return (
		<>
			<RegistrationCom {...props} />
		</>
	)
}

export default Registration
