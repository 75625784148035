import Alert from "../components/alert/alert"
import { useEffect, useState } from "react"
import axios from "axios"
import { url } from "../helpers/url"
import { useLocation } from "react-router-dom"
const WriterConfrimation = (props) => {
  const location = useLocation()
  const [success, setSuccess] = useState('none')
  useEffect(() => {
    const token = location.search
    if (token) {
      const split = token.split('?token=')
      axios.post(url + '/news/writer/api', { mode: 'confirm', token: split[1] })
        .then(({ data }) => data.success ? setSuccess('yes') : setSuccess('no'))
    }

  }, [])
  return (
    <>
      <div className="container mt-4">
        {success === 'yes' &&
          <Alert type="success" title="Congratulations!" sub="Your request has been received!" description="Once the Adminstrator approve you get an email." />
        }
        {success === 'no' &&
          <Alert type="error" title="Whoops!" sub="Something Went Wrong" description="Please try again." />
        }
      </div>
    </>
  )
}
export default WriterConfrimation
