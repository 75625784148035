import Alert from "../components/alert/alert"
import { useEffect } from "react"
import { url } from "../helpers/url"
import axios from "axios"
import { useLocation } from "react-router-dom"

const Success = () => {
	const location = useLocation()
	useEffect(() => {
		axios.get(url + "/checkout/success/api" + location.search).then(({ data }) => {
			if (data.success) {
				localStorage.removeItem("cart")
			}
		})
	}, [])
	return (
		<>
			<div className="container mt-4">
				<Alert type="success" title="Congratulations" sub="Your Order has been Placed" description="Your Order confirmation has sent to your email" />
			</div>
		</>
	)
}
export default Success
