import './popup.css';
import { url } from '../../helpers/url';
const Popup = (props) => {
  const ageVerify = (type) => {
    if (type === 'yes') {
      localStorage.setItem('agemango', type);
      window.location.href = window.location.href;
    } else {
      window.location.href = 'https://google.com';
    }
  }
  return (<>
    {props.children}
    <div className="popup_bg" style={{ backgroundImage: `url("${url}/uploads/default/popup.webp")` }}>
      <div className="popup">
        <form onSubmit={ageVerify} className="form">

          <p>By clicking "yes", I verify that I am at least 21 years of age.</p>
          <button onClick={() => ageVerify('yes')}>Yes</button>
          <button onClick={() => ageVerify('no')}>No</button>

        </form>
      </div>
    </div>
  </>);
}
export default Popup;
