import './loginmessage.css'
import React, { useContext, useRef, useEffect } from "react"
import { url } from "../../helpers/url"
import axios from "axios"
import { Context } from "../../helpers/context"
const LoginAlert = ({ position }) => {
  const [user, setUser] = useContext(Context)
  const ref = useRef()
  useEffect(() => {
    const loginAlert = (e) => {
      if (ref.current && !ref.current.contains(e.target) && user.loginPopup) {
        setUser({ ...user, loginPopup: false })
      }
    }
    window.addEventListener('click', loginAlert, true)
    return () => window.removeEventListener('click', loginAlert, true)
  }, [])

  const formSubmit = (e) => {
    e.preventDefault()
    axios
      .post(`${url}/api/login`, {
        email: e.target.email.value,
        password: e.target.password.value,
      })
      .then((data) => {
        const result = data.data
        if (result.token && result.status !== "pending") {
          localStorage.setItem("letsmango.com", result.token)
          const currentPage = localStorage.getItem("page") ? localStorage.getItem("page") : "/"
          window.location.href = currentPage

        } else if (result.status === "pending") {
          setUser({
            ...user,
            login: result.login,
            status: result.status,
            type: result.type,
          })
        }
        else if (result.status === "email") {
          setUser({
            ...user,
            login: result.login,
            status: result.status,
            type: result.type,
          })
        }
        else {
          setUser({ ...user, status: "failed" })
        }
      })
  }

  const clickHandler = () => {
    setUser({ ...user, loginPopup: false })
  }
  return (
    <div className="login_alert">
      <div className="theme-card">
        <form
          style={{ marginTop: position + 20 }}
          className="theme-form login_form"
          onSubmit={formSubmit} ref={ref}>
          <div className="close model_close">
            <i onClick={clickHandler} className="fa fa-close"></i>
          </div>
          <h3 className="my-3 title_color">Login</h3>

          {user.status == "email" && <div className="alert alert-danger">You need to confirm the email to login (Check your inbox a confirmation link had been sent)</div>}
          {user.status == "failed" && <div className="alert alert-danger">Email or password didn't match</div>}
          {user.status === "pending" && <div className="alert alert-danger">Your account status is pending. The admin will approve shortly.</div>}

          <div className="input_group">
            <label htmlFor="email">Email</label>
            <input name="email" id="email" type="email" className="form-control" placeholder="Enter your Email" required />
          </div>

          <div className="input_group">
            <label htmlFor="password">Password</label>
            <input id="password" type="password" name="password" className="form-control" placeholder="Enter your password" required />
          </div>

          <p className="forget_pass">
            <a href="/forgot">Forgot Password</a>
          </p>

          <button className="btn input_button" type="submit">
            Login
          </button>
          <p className="login_alternative">
            Don’t have an account? <a href="/register">Sign Up</a>
          </p>
        </form>
      </div>


    </div>
  )
}
export default LoginAlert