import ChatCom from "../../components/social/chat/chat"
const Chat = () => {
  return (
    <>
      <div className="container chat_box_container">
        <ChatCom type={"dynamic"} />
      </div>
    </>
  )
}
export default Chat
