import { url } from "./url";
import { socket } from "./socket"
export const dynamicView = (type, id, businessId) => {
  // socket().emit("addUser", "1.2.3.4.5", businessId)
  function getDomain(url, subdomain) {
    if (url && !url.includes(process.env.REACT_APP_FRONTEND_URL)) {
      url = url.replace(/(https?:\/\/)?(www.)?/i, "")
      let split = url.split('.')
      return split.length > 1 ? split[1] : split[0]
    }
  }

  const startTime = new Date();
  const device = navigator.userAgent;
  const pageURL = window.location.href;
  let referrer = ''
  if (document.referrer) {
    referrer = getDomain(document.referrer)
  }

  function logData() {
    if (document.visibilityState === 'hidden') {
      const totalTime = (new Date() - startTime) / 1000;
      navigator.sendBeacon(`${url}/analytics/api?device=${device}&url=${pageURL}&time=${totalTime}&type=${type}&id=${id ? id : 0}&referrer=${referrer}&businessId=${businessId ? businessId : 0}`);
      document.removeEventListener('visibilitychange', logData)
    }
  }
  document.addEventListener('visibilitychange', logData);
  return () => {
    const totalTime = (new Date() - startTime) / 1000;
    navigator.sendBeacon(`${url}/analytics/api?device=${device}&url=${pageURL}&time=${totalTime}&type=${type}&id=${id ? id : 0}&referrer=${referrer}&businessId=${businessId}`);
    document.removeEventListener('visibilitychange', logData)
    document.addEventListener('visibilitychange', logData);
  }
}
export const getBounce = (ids, type) => {
  const page = window.location.href
  const getPage = localStorage.getItem('page')
  if (page === getPage) {
    const bounce = localStorage.getItem('bounce')
    localStorage.setItem('bounce', bounce ? bounce + 1 : 1)
  }
}
