import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Store from "./helpers/context"
import './public/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './public/css/normalize.css';
import './public/css/main.css';
import App from './App';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<BrowserRouter><Store><App /></Store></BrowserRouter>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
