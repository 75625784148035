import { url } from "../../helpers/url"
import { Link } from "react-router-dom"
import './breadcumbs.css'
import { screen } from "../../helpers/functions"
const Breadcumbs = ({ title, link, linktext, description, subLink, subLinkText }) => {
  const dynamicImage = () => {
    if (screen() > 767) {
      return {
        backgroundImage: `url("${url}/uploads/default/1.jpg")`
      }
    }
    else if (screen() < 768 && screen() >= 646) {
      return {
        backgroundImage: `url("${url}/uploads/default/13.jpg")`
      }
    } else if (screen() <= 645) {
      return {
        backgroundImage: `url("${url}/uploads/default/14.jpg")`
      }
    }
  }



  return (
    <div className="business_breadcumbs">
      <div className="breadcumb_items" style={dynamicImage()}>
        <div className="overlay">

          <h3>{title}</h3>
          <p>{description}</p>

        </div>
      </div>
      <div className="container">
        <div className="col">
          <div className="sub_breadcums">
            <Link className="home" to={"/"}>Home</Link>
            <span><img className="breadcum_angle" src={url + '/uploads/default/right_angel.png'} alt="" /> </span>
            <Link className={subLink && "sublink"} to={link}> {linktext}</Link>
            {subLink && <> <span><img className="breadcum_angle" src={url + '/uploads/default/right_angel.png'} alt="" /> </span><Link to={subLink}> {subLinkText}</Link></>}

          </div>
        </div>

      </div>

    </div>
  )
}
export default Breadcumbs