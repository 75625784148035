exports.toText = (url) => {
	if (url) {
		const item = url.trim()
		return item.split("-").join(" ")
	}
	return ""
}
exports.toUrl = (url) => {
	if (url) {
		return url.trim().toLowerCase().split(" ").join("-")
	}
	return ""
}
exports.cartItem = typeof window !== "undefined" && JSON.parse(localStorage.getItem("cart")) ? JSON.parse(localStorage.getItem("cart")) : {}


exports.substring = (text, total) => {
	if (text && text.length > total) {
		return text.substring(0, total) + "..."
	}
	if (text && text.length <= total) {
		return text
	}
}

exports.username = (fname, lname) => {
	return fname + " " + lname
}

exports.trimText = (text) => {
	if (text) {
		return text
	}
	return ""
}

const screen = () => {
	let windowScreen
	if (typeof window !== "undefined") {
		windowScreen = window.screen.width
	}
	return windowScreen
}


exports.screen = screen

exports.height = () => {
	let windowScreen
	if (typeof window !== "undefined") {
		windowScreen = window.screen.height
	}
	return windowScreen
}
exports.dynamicTitle = (title, curVariation) => {
	let dynamicTitle = ''
	if (curVariation.Size) {
		dynamicTitle += 'Size: ' + curVariation.Size + ', '
	}
	if (curVariation.Color) {
		dynamicTitle += 'Color: ' + curVariation.Color + ', '
	}
	if (curVariation.Weight) {
		dynamicTitle += 'Weight: ' + curVariation.Weight + ', '
	}
	return title + ' (' + dynamicTitle.trim().replace(/.$/, "") + ')'
}
exports.math = (val) => {
	if (val) {
		return Math.round(val * 100) / 100
	}
	return 0
}
exports.isSingular = (val, content) => {
	if (val === 0) {
		return val + ' ' + content
	}
	else if (val === 1) {
		return val + ' ' + content
	}
	else if (val > 1) {
		return val + ' ' + content + 's'
	}
}
exports.jsonHandler = (data) => {
	if (data && JSON.stringify(data).length > 0) return JSON.stringify(data)
	return []
}

exports.urlParser = (text, follow = "nofollow") => {
	if (text) {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function (url) {
			return `<a target="_blank" rel="${follow}" href=${url}>${url}</a>`;
		})
	}
}

exports.bannerSize = (data) => {
	const width = screen()
	if (data.length === 3) {
		if (width > 720) return data[2]
		if (width < 720 && width > 480) return data[1]
		else return data[0]
	}
	if (data.length === 2) {
		if (width < 720 && width > 480) return data[1]
		else return data[0]
	}
	else {
		return data[0]
	}
}