import React, { useState } from "react";
export const ReviewContext = React.createContext();
const ReviewProvider = ({ children }) => {
    const [rate, setRate] = useState({
        total: 0,
        review: 0
    });
    return (
        <ReviewContext.Provider value={[rate, setRate]} >
            {children}
        </ReviewContext.Provider >
    )
}
export default ReviewProvider;