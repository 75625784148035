import React, { useState, useEffect, useRef } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { url } from "../../helpers/url";
import axios from "axios";
import ReactStars from "react-rating-stars-component"
import './map.css'
function CustomMap({ item, }) {
  const [selectedPark, setSelectedPark] = useState(null);
  const [bus, setBus] = useState([])
  const [total, setTotal] = useState([])
  const [load, setLoad] = useState(false)
  const [cordinates, setCordinates] = useState({ lat: "", lng: "" })
  useEffect(() => {
    axios.get(`${url}/business/api?page=0&type=map`).then(({ data }) => {
      if (data.success) {
        setCordinates(data.cordinates)
        setBus(data.rows)
        setTotal(data.count)
        setLoad(true)
      }
    })
  }, [])



  // useEffect(() => {
  //   const listener = e => {
  //     if (e.key === "Escape") {
  //       setSelectedPark(null);
  //     }
  //   };
  //   window.addEventListener("keydown", listener);
  //   return () => {
  //     window.removeEventListener("keydown", listener);
  //   };
  // }, []);


  const defaultCenter = () => {
    if (load) {
      if (bus.length > 0) {
        return { lat: parseFloat(bus[0].latitude), lng: parseFloat(bus[0].longitude) }
      } else {
        return cordinates
      }
    }
  }

  const starController = {
    edit: false,
    half: true,
    count: 5,
    size: 22,
    isHalf: true,
    activeColor: "#F68924",
    color: "#C4C4C4",
  }
  const markerRef = useRef()
  const markerDataRef = useRef()


  useEffect(() => {
    function clickOutside(e) {
      if (markerDataRef && markerDataRef.current && !markerDataRef.current.contains(e.target)) {
        setSelectedPark(null)
      }
    }
    document.addEventListener('click', clickOutside, true)
    return () => {
      window.removeEventListener('click', clickOutside, true)

    }
  }, [])

  return (<>
    {load &&
      <GoogleMap
        defaultZoom={15}
        defaultCenter={defaultCenter()}
      // defaultOptions={{ styles: mapStyles }}
      >
        {bus.map(park => (
          <Marker ref={markerRef}
            key={park.id}
            position={{
              lat: parseFloat(bus[0].latitude),
              lng: parseFloat(bus[0].longitude)
            }}
            onClick={() => {
              setSelectedPark(park);
            }}
            icon={{
              url: park.logo ? JSON.parse(park.logo).url : process.env.REACT_APP_BUSINESS_LOGO_IMAGE,
              scaledSize: new window.google.maps.Size(40, 40)
            }}
          />
        ))}

        {selectedPark && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedPark(null);
            }}
            position={{
              lat: parseFloat(bus[0].latitude),
              lng: parseFloat(bus[0].longitude)
            }}
          >
            <div className="business_info" ref={markerDataRef}>
              <div className="left">
                <img src={selectedPark.logo ? JSON.parse(selectedPark.logo).url : process.env.REACT_APP_BUSINESS_LOGO_IMAGE} alt="" />
              </div>
              <div className="right">
                <div className="business_title">
                  <h2>{selectedPark.title}</h2>
                </div>
                <div className="root_star">
                  <ReactStars {...starController} value={selectedPark.review / selectedPark.total} />
                  <span className="star_span">({selectedPark.total})</span>
                </div>
                <div className="business_address">
                  <p>{selectedPark.address}</p>
                </div>
              </div>

            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    }
  </>);
}

const MapWrapped = withScriptjs(withGoogleMap(CustomMap));

export default function App({ item }) {
  return (
    <div style={{ width: "100%", height: "100vh", paddingBottom: '100px' }}>
      <MapWrapped
        item={item}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_MAP_KEY
          }`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );



}
